import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import TextInput from '../../../../components/TextInput/TextInput';
import { ClientDetails, toolContactConfig } from '../../ClientOnboarding.config';

import * as S from '../EditClientData/EditClientData.style';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    newClientDetails: ClientDetails;
    handleChange: Function;
};

export default function EditToolContact({ isOpen, handleClose, newClientDetails, handleChange }: Props): JSX.Element {
    return (
        <Modal isOpen={isOpen} title="Tool Contact" handleClose={handleClose}>
            <S.ContactDetailsContainerModal>
                <S.SectionTitle>Please enter contact details</S.SectionTitle>
                {toolContactConfig.map((contactConfig) => (
                    <>
                        <S.ContactTitle>
                            {contactConfig.label} {contactConfig.mandatory && <S.Asterisk>*</S.Asterisk>}
                        </S.ContactTitle>
                        {contactConfig.inputs.map((config) => (
                            <TextInput
                                key={`${config.key}${contactConfig.label}`}
                                handleChange={handleChange(config.key)}
                                placeholder={config.placeholder}
                                value={newClientDetails[config.key].toString()}
                                required={contactConfig.mandatory}
                            />
                        ))}
                    </>
                ))}
            </S.ContactDetailsContainerModal>
        </Modal>
    );
}
