import Axios, { AxiosPromise, AxiosResponse } from 'axios';
import { Method } from 'axios';
import { OperatingMode } from '../pages/NewAnalysis/NewAnalysis.constants';

export interface RouteConfig {
    path: string;
    method: Method;
    headers?: object;
    params?: object;
    data?: object;
    responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream' | undefined;
}

const baseUrl = process.env.REACT_APP_BASE_URL || '';

export const makeRequest = (routeConfig: RouteConfig, info: any, logout: Function): AxiosPromise => {
    const basePath = '/api';
    const { path, method, headers = {}, params, data, responseType } = routeConfig;
    const url = baseUrl + basePath + path;
    const request = {
        method,
        url,
        data,
        params,
        headers: { ...headers, Authorization: `Bearer ${info.accessToken}` },
        responseType,
    };

    try {
        const result = Axios(request);
        return result;
    } catch (error) {
        if (error?.response?.status === 401) {
            logout();
        }
        throw error;
    }
};

//TODO: transition the use of all the below functions to useFetch hook

export const getModulesList = async (info: any, logout: Function): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: '/modules', method: 'GET' };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const getModuleAnalyses = async (moduleId: string, info: any, logout: Function): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: `/analyses?module=${moduleId}`, method: 'GET' };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const createNewAnalysis = async (moduleId: string, info: any, logout: Function): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: '/analyses', method: 'POST', data: { moduleLabel: moduleId } };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const updateAnalysis = async (
    analysisId: string,
    updatedAnalysis: any,
    info: any,
    logout: Function,
): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: `/analyses/${analysisId}`, method: 'PATCH', data: updatedAnalysis };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const getAnalysis = async (id: string, info: any, logout: Function): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: '/analyses/' + id, method: 'GET' };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const uploadFile = async (data: FormData, info: any, logout: Function): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: '/uploads', method: 'POST', data: data };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const getFileTemplate = async (dataType: string, info: any, logout: Function): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: '/uploads/templates/' + dataType, method: 'GET', responseType: 'blob' };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const getUploadHistory = async (
    pageId: number,
    limit: number,
    info: any,
    logout: Function,
): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: `/uploads/history?page=${pageId}&limit=${limit}`, method: 'GET' };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const getParts = async (
    module: string,
    subModule: string,
    info: any,
    logout: Function,
): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: `/parts?module=${module}&sub_module=${subModule}`, method: 'GET' };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const runAnalysis = async (
    analysisId: number,
    partsList: any,
    operatingMode: OperatingMode,
    module: string,
    subModule: string,
    investmentDetails: {},
    category: string,
    info: any,
    logout: Function,
): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = {
        path: `/analyses/${analysisId}/run`,
        method: 'POST',
        data: { partsList, category, operatingMode, ...investmentDetails, module, subModule },
    };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const runQuickAnalysis = async (
    industry: string,
    subIndustry: string,
    currentPartsData: any,
    proposedPartsData: any,
    investmentDetails: { toolingCost: number; designCost: number; otherInvestment: number },
    info: any,
    logout: Function,
): Promise<AxiosResponse> => {
    const partsList = currentPartsData.map((currPart: any, index: number) => {
        return {
            current: {
                quantity: Number(currPart.quantity),
                avgCost: Number(currPart.partCost),
                totalVolume: Number(currPart.Volume),
                partNumber: currPart.partNum,
                avgInventoryDays: Number(currPart.inventory),
                binSize: Number(currPart.binCount),
                unitsPerBin: Number(currPart.partsBin),
            },
            proposed: {
                quantity: Number(proposedPartsData[index].quantity),
                avgCost: Number(proposedPartsData[index].partCost),
                totalVolume: Number(proposedPartsData[index].Volume),
                partNumber: proposedPartsData[index].partNum,
                avgInventoryDays: Number(proposedPartsData[index].inventory),
                binSize: Number(proposedPartsData[index].binCount),
                unitsPerBin: Number(proposedPartsData[index].partsBin),
            },
        };
    });
    const routeConfig: RouteConfig = {
        path: `/analyses/quickAnalysis`,
        method: 'POST',
        data: { industry: industry, subIndustry: subIndustry, partsList: partsList, ...investmentDetails },
    };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

export const getIndustries = async (
    analysisType: 'QUICK' | 'REGULAR',
    info: any,
    logout: Function,
): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = { path: `/industries?analysis_type=${analysisType}`, method: 'GET' };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};

interface DataViewProps {
    path: string;
    pageId: number;
    limit: number;
}

export const getDataViewPage = async (
    { path, pageId, limit }: DataViewProps,
    info: any,
    logout: Function,
): Promise<AxiosResponse> => {
    const routeConfig: RouteConfig = {
        path: `/${path}?page=${pageId}&limit=${limit}`,
        method: 'GET',
    };
    const res = await makeRequest(routeConfig, info, logout);
    return res;
};
