import React from 'react';
import * as S from './HierarchyInput.style';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import TextInput from '../../../../components/TextInput/TextInput';
import {
    verticalHierarchyInfo,
    horizontalHierarchyInfo,
    VerticalHierarchyDetails,
    HorizontalHierarchyDetails,
} from '../../UserManagement.config';
import { FormControlLabel } from '@material-ui/core';

interface Props {
    isVerticalHierarchySelected: string | undefined;
    setIsVerticalHierarchySelected: (val: string) => void;
    verticalHierarchyDetails: VerticalHierarchyDetails;
    setVerticalHierarchyDetails: (val: VerticalHierarchyDetails) => void;
    isHorizontalHierarchySelected: string | undefined;
    setIsHorizontalHierarchySelected: (val: string) => void;
    horizontalHierarchyDetails: HorizontalHierarchyDetails;
    setHorizontalHierarchyDetails: (val: HorizontalHierarchyDetails) => void;
    allPlatformsSelected: boolean | undefined;
    setAllPlatformsSelected: (val: boolean) => void;
    allModulesSelected: boolean | undefined;
    setAllModulesSelected: (val: boolean) => void;
}

export default function HierarchyInput({
    isVerticalHierarchySelected,
    setIsVerticalHierarchySelected,
    verticalHierarchyDetails,
    setVerticalHierarchyDetails,
    isHorizontalHierarchySelected,
    setIsHorizontalHierarchySelected,
    horizontalHierarchyDetails,
    setHorizontalHierarchyDetails,
    allPlatformsSelected,
    setAllPlatformsSelected,
    allModulesSelected,
    setAllModulesSelected,
}: Props): JSX.Element {
    const hierarchySelectOptions = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
    ];

    const handlePlatformsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setAllPlatformsSelected(event.target.checked);
    };
    const handleModulesCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setAllModulesSelected(event.target.checked);
    };
    return (
        <S.HierarchyDetailsContainer>
            <RadioButton
                label="Vertical User Journey (Platform Lead)"
                items={hierarchySelectOptions}
                value={isVerticalHierarchySelected}
                handleChange={(event): void => setIsVerticalHierarchySelected(event.target.value as string)}
            />
            {isVerticalHierarchySelected === 'Yes' && (
                <S.HierarchyDetails>
                    <div>
                        <FormControlLabel
                            control={
                                <S.StyledCheckbox
                                    checked={allPlatformsSelected}
                                    onChange={handlePlatformsCheckboxChange}
                                    name="allPlatforms"
                                />
                            }
                            label="All Platforms"
                        />
                    </div>
                    {verticalHierarchyInfo.map((verticalHierarchy) => (
                        <TextInput
                            key={verticalHierarchy.key}
                            label={verticalHierarchy.label}
                            value={verticalHierarchyDetails?.[verticalHierarchy.key] ?? ''}
                            handleChange={(event): void =>
                                setVerticalHierarchyDetails({
                                    ...verticalHierarchyDetails,
                                    [verticalHierarchy.key]: event.target.value,
                                })
                            }
                        />
                    ))}
                </S.HierarchyDetails>
            )}
            <RadioButton
                label="Horizontal User Journey (Module Lead)"
                items={hierarchySelectOptions}
                value={isHorizontalHierarchySelected}
                handleChange={(event): void => setIsHorizontalHierarchySelected(event.target.value as string)}
            />
            {isHorizontalHierarchySelected === 'Yes' && (
                <S.HierarchyDetails>
                    <div>
                        <FormControlLabel
                            control={
                                <S.StyledCheckbox
                                    checked={allModulesSelected}
                                    onChange={handleModulesCheckboxChange}
                                    name="allModules"
                                />
                            }
                            label="All Modules"
                        />
                    </div>
                    {horizontalHierarchyInfo.map((horizontalHierarchy) => (
                        <TextInput
                            key={horizontalHierarchy.key}
                            label={horizontalHierarchy.label}
                            value={horizontalHierarchyDetails?.[horizontalHierarchy.key] ?? ''}
                            handleChange={(event): void =>
                                setHorizontalHierarchyDetails({
                                    ...horizontalHierarchyDetails,
                                    [horizontalHierarchy.key]: event.target.value,
                                })
                            }
                        />
                    ))}
                </S.HierarchyDetails>
            )}
        </S.HierarchyDetailsContainer>
    );
}
