import React from 'react';
import { TextInput, Container } from './TextInput.style';

interface Props {
    rows?: number;
    dark?: boolean;
    value?: string | number;
    label?: string;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    type?: string;
    required?: boolean;
    handleChange?: (event: React.ChangeEvent<{ name?: string; value: string }>) => void;
}

export default function TextField({
    rows,
    value,
    handleChange,
    dark = false,
    label,
    placeholder = '',
    error,
    disabled = false,
    type = 'text',
    required = false,
}: Props): JSX.Element {
    return (
        <Container>
            <TextInput
                dark={dark}
                disabled={disabled}
                error={!!error}
                helperText={error}
                multiline={!!rows}
                rows={rows ? rows : 1}
                label={label}
                type={type}
                value={value ?? undefined}
                placeholder={placeholder}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                required={required}
                inputProps={{ min: '0' }}
            />
        </Container>
    );
}
