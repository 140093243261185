import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundDark};
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.textDark};
    `,
);

export const ContainerDark = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1rem;
        width: 100%;
        flex: 1;
        color: ${theme.colors.textDark};
        > div {
            margin-top: 1.5rem;
        }
    `,
);

export const ContainerLight = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1rem;
        width: 100%;
        flex: 1;
        color: ${theme.colors.textLight};
        background-color: ${theme.colors.backgroundLight};
        border-radius: 0 0 10px 10px;
        > div {
            margin-top: 1.5rem;
        }
    `,
);

export const MetricRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    > div {
        width: 50%;
    }
`;

export const CenteredElement = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ColumnHeader = styled.div<{ dark?: boolean }>(
    ({ dark = false, theme }) => css`
        display: flex;
        justify-content: space-between;
        color: ${dark ? theme.colors.subtextLight : theme.colors.subtextDark};
        margin-top: 0 !important;
        > div:last-child {
            ${theme.typography.robotoRegular8};
        }
    `,
);
