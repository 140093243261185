import React from 'react';
import * as S from './Landing.style';
import StyledButton, { ButtonType } from '../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import { Roles } from '../../constants/auth';

export default function Landing({ roles }: { roles: Array<Roles> }): JSX.Element {
    const history = useHistory();
    return (
        <S.Container>
            <S.Box>
                <S.Title>Cost of Complexity</S.Title>

                {(roles.includes(Roles.SUPER_USER) || roles.includes(Roles.MODULE_LEAD)) && (
                    <StyledButton
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={(): void => history.push('/MLOverview')}
                    >
                        Module Lead
                    </StyledButton>
                )}
                {(roles.includes(Roles.SUPER_USER) || roles.includes(Roles.PLATFORM_LEAD)) && (
                    <StyledButton
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={(): void => history.push('/PLOverview')}
                    >
                        Platform Lead
                    </StyledButton>
                )}
                {(roles.includes(Roles.SUPER_USER) || roles.includes(Roles.DATA_ADMIN)) && (
                    <StyledButton
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={(): void => history.push('/dataManagement')}
                    >
                        Data Management
                    </StyledButton>
                )}
                {roles.includes(Roles.SUPER_USER) && (
                    <StyledButton buttonType={ButtonType.PRIMARY} onClickHandler={(): void => history.push('/home')}>
                        Client Management
                    </StyledButton>
                )}
            </S.Box>
        </S.Container>
    );
}
