import styled, { css } from 'styled-components';
import { TableCell, TableRow, Typography } from '@material-ui/core';

export const StyledTableRow = styled(TableRow)(
    ({ theme }) => css`
        .MuiTableRow-root {
            background-color: ${theme.colors.backgroundDark};
        }
    `,
);

export const StyledTableCount = styled.div(
    ({ theme }) => css`
        margin: 1.25rem;
        ${theme.typography.robotoRegular14};
    `,
);

export const StyledTableCell = styled(TableCell)(
    ({ theme }) => css`
        padding: 1rem 1.5rem;
        &.MuiTableCell-head {
            background-color: ${theme.colors.backgroundDark};
            color: ${theme.colors.textDark};
            ${theme.typography.robotoMedium14};
        }
        .MuiTableCell-body {
            ${theme.typography.robotoRegular14};
        }
    `,
);

export const TableText = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
    `,
);
