import React from 'react';
import { Row } from '../PartsSelection.style';
import { AddNewPartButton, ButtonWrapper } from './AddNewPartRow.style';

interface Props {
    onCurrentClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onProposedClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function AddNewPartRow({ onCurrentClick, onProposedClick }: Props): JSX.Element {
    return (
        <Row>
            <ButtonWrapper>
                <AddNewPartButton onClick={onCurrentClick}>+ add new part</AddNewPartButton>
            </ButtonWrapper>
            <ButtonWrapper>
                <AddNewPartButton onClick={onProposedClick}>+ add new part</AddNewPartButton>
            </ButtonWrapper>
        </Row>
    );
}

export default AddNewPartRow;
