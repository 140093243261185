import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button, { ButtonType } from '../../../../components/Button/Button';
import * as S from './ConfirmationDialog.style';

interface Props {
    isOpen: boolean;
    analysisId: string;
    handleConfirm: () => void;
    handleCancel: () => void;
    isPL?: boolean;
}

export default function ConfirmationDialog({
    isOpen,
    analysisId,
    handleConfirm,
    handleCancel,
    isPL = false,
}: Props): JSX.Element {
    return (
        <Modal isOpen={isOpen} title="Confirm Delete" handleClose={handleCancel}>
            {isPL ? 'Project' : 'Analysis'} {analysisId} would be permanently deleted.
            <S.Container>
                <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleConfirm}>
                    Confirm
                </Button>
                <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleCancel}>
                    Cancel
                </Button>
            </S.Container>
        </Modal>
    );
}
