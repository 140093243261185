import styled, { css } from 'styled-components';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledSelect = styled(Select)<{ variant: 'outlined' | 'standard' }>(
    ({ theme, variant }) => css`
        background: ${theme.colors.backgroundLight};
        width: 100%;
        .MuiSelect-icon {
            color: ${theme.colors.accentPrimary};
        }
        color: ${variant === 'standard' ? theme.colors.textLight : theme.colors.accentPrimary};
    `,
);

export const Label = styled(InputLabel)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
    `,
);

export const StyledMenuItem = styled(MenuItem)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
        &&:hover {
            background-color: ${theme.colors.accentPrimary};
            color: ${theme.colors.textDark};
        }
    `,
);

export const SelectContainer = styled(FormControl)<{ variant: 'outlined' | 'standard' }>(
    ({ variant }) => css`
        width: 100%;
        .MuiInputLabel-shrink {
            display: ${variant === 'standard' ? 'initial' : 'none'};
        }
        .MuiOutlinedInput-notchedOutline {
            border: none;
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
        }
    `,
);

export const Container = styled.div`
    width: 100%;
`;

export const Asterisk = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.accentSecondary};
    `,
);

export const Error = styled.div(
    ({ theme }) => css`
        color: red;
        margin-top: 0.25rem;
        ${theme.typography.robotoRegular12};
    `,
);
