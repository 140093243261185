import { ListItem } from '../../components/DoublyNestedCheckList/DoublyNestedCheckList';
import { GetAnalysisByIdResponse } from '../../constants/apiConfig';
import { AggregatedAnalysisData } from './ModuleExplorer';
import { groupBy } from 'lodash';
import { Finance } from 'financejs';

const finance = new Finance();

interface AnalysesData {
    hierarchy_5: string;
    hierarchy_6: string;
    analysis_id: number;
    title: string;
}

export const formatAnalysisDataForNestedList = (data: AnalysesData[]): ListItem[] => {
    const analysesGroupedByModule = groupBy(data, (item) => item.hierarchy_5);
    const analysesWithModuleLabels = Object.keys(analysesGroupedByModule).map((item) => {
        return { label: item, subItems: analysesGroupedByModule[item] };
    });
    return analysesWithModuleLabels.map((module) => {
        const analysesGroupedBySubModule = groupBy(module.subItems, (item) => item.hierarchy_6);
        const analysesWithSubModuleLabels = Object.keys(analysesGroupedBySubModule).map((item) => {
            const analysesIds = analysesGroupedBySubModule[item].map((analysis) => ({
                label: analysis.analysis_id.toString(),
                hover: analysis.title,
            }));
            return { label: item, subItems: analysesIds };
        });
        return { ...module, subItems: analysesWithSubModuleLabels };
    });
};

export const getAggregatedAnalysisData = (data: Array<GetAnalysisByIdResponse>): AggregatedAnalysisData | undefined => {
    if (data.length === 0) {
        return undefined;
    }
    const aggregate = data.reduce(
        (accumulator, currentValue) => {
            const newData = { ...accumulator };
            (Object.keys(accumulator) as Array<keyof typeof accumulator>).forEach((key) => {
                newData.tooling_investment = accumulator.tooling_investment + currentValue.tooling_investment_result;
                newData.design_investment = accumulator.design_investment + currentValue.design_investment_result;
                newData.line_investment = accumulator.line_investment + currentValue.line_investment_result;
                newData.other_investment = accumulator.other_investment + currentValue.other_investment_result;
                newData.dmc_result = accumulator.dmc_result + currentValue.dmc_result;
                newData.scale_benefit_result = accumulator.scale_benefit_result + currentValue.scale_benefit_result;
                newData.unique_current_parts =
                    accumulator.unique_current_parts + (Number(currentValue.unique_current_parts) || 0);
                newData.unique_proposed_parts =
                    accumulator.unique_proposed_parts + (Number(currentValue.unique_proposed_parts) || 0);
                newData.sunk_cost = accumulator.sunk_cost + currentValue.residual_tooling_result;
                newData.cash_flow_release_result =
                    accumulator.cash_flow_release_result + currentValue.cash_flow_release_result;
                newData.inventory_cost =
                    accumulator.inventory_cost +
                    (currentValue.working_capital_result +
                        currentValue.part_obsolescence_result +
                        currentValue.space_utilization_result);
                newData.warranty_cost = accumulator.warranty_cost + currentValue.warranty_result;
                newData.rejection_cost = accumulator.rejection_cost + currentValue.lr_material_result;
                newData.logistics_cost =
                    accumulator.logistics_cost +
                    (currentValue.inbound_logistics_result + currentValue.intra_plant_logistics_result);
                newData.effiency_enhancement_result =
                    accumulator.effiency_enhancement_result +
                    (currentValue.handling_manpower_result +
                        currentValue.lr_rework_manpower_result +
                        currentValue.sc_efficiency_design_result +
                        currentValue.sc_efficiency_documentation_result +
                        currentValue.sc_efficiency_inspection_result +
                        currentValue.sc_efficiency_line_result +
                        currentValue.sc_efficiency_purchase_result +
                        currentValue.sc_efficiency_quality_result);
                newData.effiency_enhancement_hours_result =
                    accumulator.effiency_enhancement_hours_result +
                        (currentValue.handling_manhours_result +
                            currentValue.lr_rework_manhours_result +
                            currentValue.sc_efficiency_design_manhours_result +
                            currentValue.sc_efficiency_documentation_manhours_result +
                            currentValue.sc_efficiency_inspection_manhours_result +
                            currentValue.sc_efficiency_line_manhours_result +
                            currentValue.sc_efficiency_purchase_manhours_result +
                            currentValue.sc_efficiency_quality_manhours_result) || 0;
                newData.benefit =
                    accumulator.benefit +
                    currentValue.dmc_result +
                    currentValue.working_capital_result +
                    currentValue.part_obsolescence_result +
                    currentValue.space_utilization_result +
                    currentValue.warranty_result +
                    currentValue.lr_material_result +
                    currentValue.inbound_logistics_result +
                    currentValue.intra_plant_logistics_result +
                    currentValue.lr_rework_manpower_result +
                    currentValue.handling_manpower_result +
                    newData.effiency_enhancement_result;
                newData.investment =
                    accumulator.investment +
                    currentValue.tooling_investment_result +
                    currentValue.line_investment_result +
                    currentValue.design_investment_result +
                    currentValue.other_investment_result +
                    currentValue.residual_tooling_result;
                const netSavingsArr = (currentValue.net_savings_arr as number[][]).reduce(
                    (acc, curr) => {
                        return curr.map((ele, index) => acc[index] + ele);
                    },
                    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                );
                newData.net_savings_arr = netSavingsArr.map((ele, index) => {
                    return newData.net_savings_arr[index] + ele;
                });
                newData.npv = accumulator.npv + currentValue.npv;
            });
            return newData;
        },
        {
            tooling_investment: 0,
            design_investment: 0,
            line_investment: 0,
            other_investment: 0,
            variant_reduction_result: 0,
            dmc_result: 0,
            scale_benefit_result: 0,
            unique_current_parts: 0,
            unique_proposed_parts: 0,
            sunk_cost: 0,
            cash_flow_release_result: 0,
            effiency_enhancement_result: 0,
            effiency_enhancement_hours_result: 0,
            inventory_cost: 0,
            warranty_cost: 0,
            rejection_cost: 0,
            logistics_cost: 0,
            benefit: 0,
            investment: 0,
            net_savings_arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            npv: 0,
            irr: 0,
            payback_period: 0,
        },
    );

    try {
        aggregate.irr = finance.IRR(aggregate.net_savings_arr[0], ...aggregate.net_savings_arr.slice(1));
    } catch (e) {
        aggregate.irr = 0;
    }
    aggregate.payback_period = aggregate.investment !== 0 ? aggregate.benefit / aggregate.investment : 0;
    aggregate.variant_reduction_result = 1 - aggregate.unique_proposed_parts / aggregate.unique_current_parts;
    return aggregate;
};

export const formatForExport = (analysisDetailsRes: GetAnalysisByIdResponse): {} => {
    const {
        other_investment_result: other_investment,
        dmc_result,
        scale_benefit_result,
        net_savings_arr,
    } = analysisDetailsRes;

    const toolingInvestment = analysisDetailsRes.tooling_investment_result;
    const designInvestment = analysisDetailsRes.design_investment_result;
    const residualInvestment = analysisDetailsRes.residual_tooling_result;
    const lineInvestment = analysisDetailsRes.line_investment_result;

    const inventoryCost =
        analysisDetailsRes.working_capital_result +
        analysisDetailsRes.part_obsolescence_result +
        analysisDetailsRes.space_utilization_result;
    const logisticsCost = analysisDetailsRes.inbound_logistics_result + analysisDetailsRes.intra_plant_logistics_result;
    const effiencyEnhancementResult =
        analysisDetailsRes.handling_manpower_result +
        analysisDetailsRes.lr_rework_manpower_result +
        analysisDetailsRes.sc_efficiency_design_result +
        analysisDetailsRes.sc_efficiency_documentation_result +
        analysisDetailsRes.sc_efficiency_inspection_result +
        analysisDetailsRes.sc_efficiency_line_result +
        analysisDetailsRes.sc_efficiency_purchase_result +
        analysisDetailsRes.sc_efficiency_quality_result;
    const effiencyEnhancementResulthours =
        analysisDetailsRes.handling_manhours_result +
            analysisDetailsRes.lr_rework_manhours_result +
            analysisDetailsRes.sc_efficiency_design_manhours_result +
            analysisDetailsRes.sc_efficiency_documentation_manhours_result +
            analysisDetailsRes.sc_efficiency_inspection_manhours_result +
            analysisDetailsRes.sc_efficiency_line_manhours_result +
            analysisDetailsRes.sc_efficiency_purchase_manhours_result +
            analysisDetailsRes.sc_efficiency_quality_manhours_result || 0;

    const benefit =
        analysisDetailsRes.dmc_result +
        analysisDetailsRes.working_capital_result +
        analysisDetailsRes.part_obsolescence_result +
        analysisDetailsRes.space_utilization_result +
        analysisDetailsRes.warranty_result +
        analysisDetailsRes.lr_material_result +
        analysisDetailsRes.inbound_logistics_result +
        analysisDetailsRes.intra_plant_logistics_result +
        analysisDetailsRes.lr_rework_manpower_result +
        analysisDetailsRes.handling_manpower_result +
        effiencyEnhancementResult;
    const investment =
        analysisDetailsRes.tooling_investment_result +
        analysisDetailsRes.line_investment_result +
        analysisDetailsRes.design_investment_result +
        analysisDetailsRes.other_investment_result +
        analysisDetailsRes.residual_tooling_result;
    const payback_period = investment !== 0 ? benefit / investment : 0;

    let irr;
    try {
        irr = finance.IRR((net_savings_arr as number[])[0], ...(net_savings_arr as number[]).slice(1));
    } catch {
        irr = 0;
    }

    const partsData = analysisDetailsRes.partsList.map((part) => {
        return {
            currentPart: part.currentPart.partNumber,
            proposedPart: part.proposedPart.partNumber,
        };
    });

    const recurringImpact =
        Number(
            dmc_result +
                scale_benefit_result +
                inventoryCost +
                logisticsCost +
                analysisDetailsRes.warranty_result +
                analysisDetailsRes.lr_material_result,
        )?.toFixed(2) || 0;

    return {
        analysisId: analysisDetailsRes.id,
        analysisType: analysisDetailsRes.operating_mode,
        module: analysisDetailsRes.hierarchy_5,
        subModule: analysisDetailsRes.hierarchy_6,
        analysisTitle: analysisDetailsRes.title,
        additionalInformation: analysisDetailsRes.additional_info,
        detailedAnalysisDescription: analysisDetailsRes.detailed_description,
        impactOnOtherModules: analysisDetailsRes.impact_on_other_modules,
        creator: analysisDetailsRes.originator,
        partsData: partsData,
        input: {
            toolingInvestment: Number(toolingInvestment)?.toFixed(2) || 0,
            designInvestment: Number(designInvestment)?.toFixed(2) || 0,
            residualToolingInvestment: Number(residualInvestment)?.toFixed(2) || 0,
            lineInvestment: Number(lineInvestment)?.toFixed(2) || 0,
            otherInvestment: Number(other_investment)?.toFixed(2) || 0,
            proposedDesignInvestment: null,
            proposedToolingInvestment: null,
            proposedMaterialCost: null,
            proposedAnnualVolume: null,
        },
        output: {
            dmcResult: Number(dmc_result)?.toFixed(2) || 0,
            scaleBenefitResult: Number(scale_benefit_result)?.toFixed(2) || 0,
            inventoryCost: inventoryCost?.toFixed(2) || 0,
            warrantyCost: analysisDetailsRes.warranty_result?.toFixed(2) || 0,
            rejectionCost: analysisDetailsRes.lr_material_result?.toFixed(2) || 0,
            spareCost: null,
            logisticsCost: logisticsCost?.toFixed(2) || 0,
            totalCost: recurringImpact,
            recurringImpact: recurringImpact,
            totalInvestment: (
                Number(toolingInvestment) +
                Number(designInvestment) +
                Number(other_investment) +
                Number(lineInvestment)
            ).toFixed(2),
            variantReduction: (Number(analysisDetailsRes.variant_reduction_result) || 0 * 100)?.toFixed(2) || 0,
            sunkCost: analysisDetailsRes.residual_tooling_result?.toFixed(2) || 0,
            efficiencyEnhancementCost: effiencyEnhancementResult?.toFixed(2) || 0,
            efficiencyEnhancementTime: effiencyEnhancementResulthours?.toFixed(2) || 0,
            paybackPeriod: Number(payback_period)?.toFixed(2) || 0,
            cashRelease: analysisDetailsRes.cash_flow_release_result?.toFixed(2) || 0,
            npv: Number(analysisDetailsRes?.npv)?.toFixed(2) || 0,
            irr: Number(irr)?.toFixed(2) || 0,
            designInvestment: Number(designInvestment)?.toFixed(2) || 0,
            toolingInvestment: Number(toolingInvestment)?.toFixed(2) || 0,
            lifeInvestment: Number(analysisDetailsRes.line_investment_result)?.toFixed(2) || 0,
            otherInvestment: Number(other_investment)?.toFixed(2) || 0,
        },
    };
};
