import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        flex: 1;
        margin-right: 1rem;
        padding: 1.5rem;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundLight};
        > div {
            margin-bottom: 1rem;
        }
    `,
);

export const Divider = styled.span(
    ({ theme }) => css`
        margin: 1rem 0 2rem;
        border: 1px solid ${theme.colors.greyScaleLight};
    `,
);
