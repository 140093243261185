import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 1.5rem 1rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 0.875rem;
    height: 3.5rem;
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
    > div {
        margin-left: 1rem;
    }
`;

export const BodyContainer = styled.div`
    display: flex;
    height: 100%;
`;

export const ProjectDetails = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        border-radius: 10px;
        padding: 1.5rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundDark};
        > div {
            margin-bottom: 1.5rem;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    `,
);

export const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Details = styled.div(
    ({ theme }) => css`
        margin-left: auto;
        color: ${theme.colors.accentSecondary};
        ${theme.typography.robotoRegular12};
    `,
);
