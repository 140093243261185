export type PartDetails = {
    platform: string;
    product: string;
    model: string;
    variant: string;
    partNumber: string;
    annualVolume: string;
    partCost: string;
    quantity: string;
    reworkTime: string;
    averageInventoryDays: string;
    partObsolescence: string;
    unitsPerBin: string;
    handlingTime: string;
    firstPassRate: string;
    scrapRate: string;
    warrantyInstances: string;
    averageCostPerInstance: string;
    inboundLogisticsCost: string;
    intraPlantLogisticsCost: string;
    description: string;
};

export type HierarchiesSelection = {
    platform?: number;
    product?: number;
    model?: number;
    variant?: number;
};

export const initialPartDetails = {
    platform: '',
    product: '',
    model: '',
    variant: '',
    partNumber: '',
    annualVolume: '',
    partCost: '',
    quantity: '',
    reworkTime: '',
    averageInventoryDays: '',
    partObsolescence: '',
    unitsPerBin: '',
    handlingTime: '',
    firstPassRate: '',
    scrapRate: '',
    warrantyInstances: '',
    averageCostPerInstance: '',
    inboundLogisticsCost: '',
    intraPlantLogisticsCost: '',
    description: '',
};

type SelectConfig = Array<{
    key: keyof PartDetails;
    label: string;
    pathToOptions: Function;
}>;

export const partDataSelectConfig: SelectConfig = [
    {
        key: 'platform',
        pathToOptions: (): string => 'platforms',
        label: 'PLATFORM',
    },
    {
        key: 'product',
        pathToOptions: ({ platform }: HierarchiesSelection): string => `platforms[${platform}].products`,
        label: 'PRODUCT',
    },
    {
        key: 'model',
        pathToOptions: ({ platform, product }: HierarchiesSelection): string =>
            `platforms[${platform}].products[${product}].models`,
        label: 'MODEL',
    },
    {
        key: 'variant',
        pathToOptions: ({ platform, product, model }: HierarchiesSelection): string =>
            `platforms[${platform}].products[${product}].models[${model}].variants`,
        label: 'VARIANT',
    },
];

type Config = Array<{
    key: keyof PartDetails;
    label: string;
    placeholder: string;
    type: string;
}>;

export const partDataConfig: Config = [
    {
        key: 'partNumber',
        label: 'PART NUMBER',
        placeholder: 'Enter part number',
        type: 'text',
    },
    {
        key: 'annualVolume',
        label: 'ANNUAL VOLUME',
        placeholder: 'Enter annual volume',
        type: 'number',
    },
    {
        key: 'partCost',
        label: 'PART COST (INR/UNIT)',
        placeholder: 'Enter part cost',
        type: 'number',
    },
    {
        key: 'quantity',
        label: 'QUANTITY',
        placeholder: 'Enter quantity',
        type: 'number',
    },
    {
        key: 'reworkTime',
        label: 'REWORK TIME/UNIT (HOURS)',
        placeholder: 'Enter time',
        type: 'number',
    },
];

export const advancedOperatingModeConfig: Config = [
    {
        key: 'averageInventoryDays',
        label: 'AVERAGE INVENTORY DAYS',
        placeholder: 'Enter average inventory days',
        type: 'number',
    },
    {
        key: 'partObsolescence',
        label: 'PART OBSOLESCENCE (INR)',
        placeholder: 'Enter part obsolescence',
        type: 'number',
    },
    {
        key: 'unitsPerBin',
        label: 'NUMBER OF UNITS/BIN',
        placeholder: 'Enter number of units/bin',
        type: 'number',
    },
    {
        key: 'handlingTime',
        label: 'HANDLING TIME/UNIT (WAREHOUSE)',
        placeholder: 'Enter handling time',
        type: 'number',
    },
    {
        key: 'firstPassRate',
        label: 'FIRST PASS RATE (%)',
        placeholder: 'Enter first pass rate',
        type: 'text',
    },
    {
        key: 'scrapRate',
        label: 'SCRAP RATE (%)',
        placeholder: 'Enter scrap rate',
        type: 'text',
    },
    {
        key: 'warrantyInstances',
        label: 'WARRANTY INSTANCES',
        placeholder: 'Enter Warranty Instances',
        type: 'number',
    },
    {
        key: 'averageCostPerInstance',
        label: 'AVERAGE COST/INSTANCE (INR/UNIT)',
        placeholder: 'Enter average cost per instance',
        type: 'number',
    },
    {
        key: 'inboundLogisticsCost',
        label: 'INBOUND LOGISTICS COST (INR/UNIT)',
        placeholder: 'Enter inbound logistics cost',
        type: 'number',
    },
    {
        key: 'intraPlantLogisticsCost',
        label: 'INTRA PLANT LOGISTICS COST (INR/UNIT)',
        placeholder: 'Enter intra plant logistics cost',
        type: 'number',
    },
];

export const sparePartConfig = [
    {
        key: 'sparePartNumber',
        label: 'SPARE PART NUMBER',
        placeholder: 'Enter spare part number',
        type: 'text',
    },
    {
        key: 'sparePartVolume',
        label: 'SPARE PART VOLUME',
        placeholder: 'Enter spare part volume',
        type: 'number',
    },
    {
        key: 'sparePartCost',
        label: 'SPARE PART COST',
        placeholder: 'Enter spare part cost',
        type: 'number',
    },
    {
        key: 'sparePartQuantity',
        label: 'SPARE PART QUANTITY',
        placeholder: 'Enter spare part quantity',
        type: 'number',
    },
];
