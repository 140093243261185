interface Props {
    [key: string]: number;
}

const sumOfKeysOfAnalysisResult = (analysisResult: { [key: string]: number }, keys: Array<string>): number => {
    return Object.keys(analysisResult).reduce((acc, key) => {
        return keys.includes(key) ? acc + Number(analysisResult[key]) : acc;
    }, 0);
};

export const calcEfficiencyEnchancement = (analysisResult: Props): number => {
    const keys = [
        'handlingManpowerResult',
        'reworkCostResult',
        'scEfficiencyDesignResult',
        'scEfficiencyDocumentationResult',
        'scEfficiencyInspectionResult',
        'scEfficiencyLineResult',
        'scEfficiencyPurchaseResult',
        'scEfficiencyQualityResult',
    ];
    return sumOfKeysOfAnalysisResult(analysisResult, keys);
};

export const calcEfficiencyEnchancementHours = (analysisResult: Props): number => {
    const keys = [
        'handlingManpowerHours',
        'reworkManhours',
        'scEfficiencyDesignHours',
        'scEfficiencyDocumentationHours',
        'scEfficiencyInspectionHours',
        'scEfficiencyLineHours',
        'scEfficiencyPurchaseHours',
        'scEfficiencyQualityHours',
    ];
    return sumOfKeysOfAnalysisResult(analysisResult, keys);
};

export const calcInventory = (analysisResult: Props): number => {
    const keys = ['workingCapitalResult', 'obsolescenceResult', 'spaceUtilizationResult'];
    return sumOfKeysOfAnalysisResult(analysisResult, keys);
};

export const calcLogistics = (analysisResult: Props): number => {
    const keys = ['inboundLogisticsResult', 'intraPlantLogisticsResult'];
    return sumOfKeysOfAnalysisResult(analysisResult, keys);
};
