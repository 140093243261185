import styled, { css } from 'styled-components';
import { AppBar, Tabs } from '@material-ui/core';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 1.5rem 1rem;
        overflow: auto;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 0.875rem;
    height: 3.5rem;
`;

export const BodyContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex: 1;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        margin-top: 0;
        background-color: ${theme.colors.backgroundLight};
        overflow: hidden;
    `,
);

export const StyledAppBar = styled(AppBar)(
    () => css`
        box-shadow: none;
    `,
);

export const StyledTabs = styled(Tabs)(
    ({ theme }) => css`
        .PrivateTabIndicator-colorPrimary-2 {
            background-color: ${theme.colors.textSalmon};
        }
    `,
);
