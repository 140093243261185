import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 1.5rem 1rem;
        overflow: auto;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.875rem;
    height: 3.5rem;
    > div:first-child {
        display: flex;
    }
`;

export const BodyContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex: 1;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        margin-top: 0;
        background-color: ${theme.colors.backgroundLight};
    `,
);

export const ActionButtonContainer = styled.div`
    display: flex;
    > div {
        width: 200px;
        margin-left: 0.25rem;
    }
`;
