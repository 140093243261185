import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        flex: 2;
        margin: 0 1rem;
        padding: 1.5rem;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundLight};
    `,
);

export const AnalysisChartContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex: 1;
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.textLight};
    `,
);

export const ChartTitle = styled.div`
    text-align: left;
    align-self: flex-start;
`;
