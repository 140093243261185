import styled, { css } from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const RoundButton = styled.button`
    background: #fff;
    border: none;
    outline: none;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: #000c7b;
    margin-right: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 2px #88888888;

    &:hover {
        box-shadow: 0 1px 4px #88888888;
    }
`;

export const Container = styled(Paper)(
    ({ theme }) => css`
        && {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-self: flex-end;
            background-color: ${theme.colors.backgroundPrimary};
            overflow: hidden;
        }
    `,
);

export const PageHeader = styled.div(
    () => css`
        && {
            display: flex;
            justify-content: space-between;
            margin: 2%;
        }
    `,
);

export const PageHeaderBody = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            justify-content: flex-start;
            width: 50%;
            align-items: center;
            color: ${theme.colors.backgroundDark};
        }
    `,
);

export const PageHeaderBodyRight = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            justify-content: flex-end;
            width: 20%;
            align-items: center;
            color: ${theme.colors.backgroundDark};
        }
    `,
);

export const CardContainer = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px 15px;
    justify-items: stretch;
    align-items: stretch;
    padding: 15px;
`;

interface CellParams {
    gridColumnStart?: number;
    gridColumnEnd?: number;
    gridRowStart?: number;
    gridRowEnd?: number;
    secondaryBackground?: boolean;
}

export const Card = styled(Paper)(
    ({ theme }) => css`
        && {
            > div {
                margin-top: 2rem;
            }
            min-height: 0;
            max-height: 800px;
            overflow: auto;
            border-radius: 15px;
            padding-left: 7%;
            padding-right: 7%;
            grid-column-start: ${(props: CellParams): number | string => props.gridColumnStart || 'auto'};
            grid-column-end: ${(props: CellParams): number | string => props.gridColumnEnd || 'auto'};
            grid-row-start: ${(props: CellParams): number | string => props.gridRowStart || 'auto'};
            grid-row-end: ${(props: CellParams): number | string => props.gridRowEnd || 'auto'};
            background-color: ${(props: CellParams): string =>
                props.secondaryBackground ? theme.colors.backgroundDark : theme.colors.backgroundLight};
        }
    `,
);

export const ModuleInfoLabel = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            margin-top: 10%;
            font-size: 80%;
            font-weight: lighter;
        }
    `,
);

export const ModuleInfoText = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            margin-top: 2%;
            font-size: 90%;
            font-weight: normal;
        }
    `,
);

export const ModuleInfoTitle = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.textLight};
            margin-top: 10%;
            ${theme.typography.robotoRegular12}
        }
    `,
);

export const ResultsContainer = styled(Paper)(
    ({ theme }) => css`
        padding-left: 7%;
        padding-right: 7%;
        width: 100%;
        padding-top: 7%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: ${theme.colors.backgroundDark};
    `,
);

export const ModuleStatValue = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            font-size: 125%;
            font-weight: normal;
        }
    `,
);

export const ModuleStatLabel = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            font-size: 70%;
            font-weight: lighter;
        }
    `,
);

export const ModuleStatContainer = styled.div(
    () => css`
        > div {
            margin-bottom: 1.5rem;
        }
        overflow: fixed;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 7%;
    `,
);

export const ModuleStatValueDark = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.textLight};
            font-size: 125%;
            font-weight: normal;
        }
    `,
);

export const ModuleStatLabelDark = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextLight};
            font-size: 70%;
            font-weight: lighter;
        }
    `,
);

export const InputStatsContainer = styled.div(
    ({ theme }) => css`
        > div {
            margin-top: 10%;
        }
        min-height: 0;
        padding-left: 7%;
        padding-right: 7%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-start;
        background-color: ${theme.colors.backgroundLight};
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    `,
);

export const SelectedModuleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const SelectedModuleBox = styled.div`
    width: 50%;
`;

export const Divider = styled.hr`
    margin-top: 20%;
    margin-bottom: 20%;
`;

export const SensitivityLabel = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            margin-top: 2%;
            font-size: 95%;
            font-weight: ligher;
        }
    `,
);

export const ResultsCard = styled(Paper)(
    ({ theme }) => css`
        min-height: 0;
        max-height: 1000000px;
        border-radius: 15px;
        grid-column-start: ${(props: CellParams): number | string => props.gridColumnStart || 'auto'};
        grid-column-end: ${(props: CellParams): number | string => props.gridColumnEnd || 'auto'};
        grid-row-start: ${(props: CellParams): number | string => props.gridRowStart || 'auto'};
        grid-row-end: ${(props: CellParams): number | string => props.gridRowEnd || 'auto'};
        background-color: ${theme.colors.backgroundLight};
    `,
);

export const ResultsCardLabel = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            ${theme.typography.robotoRegular12}
        }
    `,
);

export const ChartCard = styled(Paper)(
    ({ theme }) => css`
        && {
            > div {
                margin-top: 2rem;
            }
            min-height: 0;
            max-height: 850px;
            border-radius: 15px;
            padding-left: 3%;
            padding-right: 3%;
            grid-column-start: ${(props: CellParams): number | string => props.gridColumnStart || 'auto'};
            grid-column-end: ${(props: CellParams): number | string => props.gridColumnEnd || 'auto'};
            grid-row-start: ${(props: CellParams): number | string => props.gridRowStart || 'auto'};
            grid-row-end: ${(props: CellParams): number | string => props.gridRowEnd || 'auto'};
            background-color: ${theme.colors.backgroundLight};
        }
    `,
);

export const ChartTitle = styled(Typography)(
    ({ theme }) => css`
        && {
            margin-top: 5%;
            color: ${theme.colors.subtextLight};
            ${theme.typography.robotoRegular12}
        }
    `,
);

export const ColumnHeader = styled.div<{ dark?: boolean }>(
    ({ dark = false, theme }) => css`
        display: flex;
        justify-content: space-between;
        color: ${dark ? theme.colors.subtextLight : theme.colors.subtextDark};
        ${theme.typography.robotoRegular12};
        > div:last-child {
            ${theme.typography.robotoRegular8};
        }
    `,
);
