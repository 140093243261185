import React from 'react';
import { StyledButton, Container } from './Button.style';

export enum ButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TEXT = 'text',
}
interface Props {
    buttonType: ButtonType;
    children: React.ReactNode;
    onClickHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
}

export default function Button({ buttonType, children, onClickHandler, disabled = false }: Props): JSX.Element {
    return (
        <Container>
            <StyledButton
                buttonType={buttonType}
                variant={buttonType === ButtonType.TEXT ? 'text' : 'contained'}
                onClick={onClickHandler}
                disabled={disabled}
            >
                {children}
            </StyledButton>
        </Container>
    );
}
