import React, { ChangeEvent } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    StyledExpansionPanelSummary,
    CardTitle,
    ExpansionPanelContainer,
    StyledExpansionPanelDetails,
} from './ExpansionPanel.style';

type Props = {
    title: string;
    children: React.ReactNode;
};

export default function StyledExpansionPanel({ title, children }: Props): JSX.Element {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (event: ChangeEvent<{}>, isExpanded: boolean): void => {
        setExpanded(isExpanded ? isExpanded : false);
    };

    return (
        <ExpansionPanelContainer elevation={0} expanded={expanded} onChange={handleChange}>
            <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
                <CardTitle>{title}</CardTitle>
            </StyledExpansionPanelSummary>
            <StyledExpansionPanelDetails>{children}</StyledExpansionPanelDetails>
        </ExpansionPanelContainer>
    );
}
