import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import EditModal from './components/EditModal/EditModal';
import Pagination from '@material-ui/lab/Pagination';
import { userTableBodyCells, userTableHeadCells, maxEntriesPerPage } from './UserManagement.config';
import {
    uploadExcelConfig,
    UploadExcelResponse,
    getAllUsersConfig,
    GetAllUsersResponse,
    UsersResponse,
    updateUserConfig,
    UpdateUserResponse,
    deleteUserConfig,
    DeleteUserResponse,
    usersExportConfig,
    usersTemplateConfig,
} from '../../constants/apiConfig';
import { useFetch } from '../../hooks/useFetch';
import * as S from './UserManagement.style';
import Button, { ButtonType } from '../../components/Button/Button';
import { formatTableData } from './UserManagement.utils';
import { downloadExcel } from '../../utils/fileUtils';
import { useAuthContext } from '../../context/Auth';
import Snackbar from '../../components/Snackbar/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmationModal from './components/ConfirmationDialog/ConfirmationDialog';

interface TableData {
    totalEntries: number;
    pageTotal: number;
    data: any[];
}

export default function DataView(): JSX.Element {
    const history = useHistory();
    const { info } = useAuthContext();

    const [uploadExcelResult, uploadExcel] = useFetch<UploadExcelResponse>(uploadExcelConfig);
    const [allUsers, getAllUsers] = useFetch<GetAllUsersResponse>(getAllUsersConfig);
    const [updateUserStatus, updateUser] = useFetch<UpdateUserResponse>(updateUserConfig);
    const [deleteUserStatus, deleteUser] = useFetch<DeleteUserResponse>(deleteUserConfig);
    const [exportExcelStatus, exportExcelPost] = useFetch<Blob>(usersExportConfig);
    const [downloadTemplateStatus, downloadTemplate] = useFetch<Blob>(usersTemplateConfig);

    const [tableData, setTableData] = useState<TableData>({
        data: [],
        totalEntries: 0,
        pageTotal: 10,
    });
    const [editModalState, setEditModalState] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [selectedRow, setSelectedRow] = useState<number>(-1);
    const [showSnackBarError, setShowSnackBarError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showSnackBarSuccess, setShowSnackBarSuccess] = useState<boolean>(false);
    const [confirmationModalState, setConfirmationModalState] = useState<boolean>(false);
    const [emailToDelete, setEmailToDelete] = useState<string>('');

    const fetchTableData = useCallback(async () => {
        if (!updateUserStatus.loading && !uploadExcelResult.loading && !deleteUserStatus.loading) {
            const params = { page: page - 1, limit: 10 };
            getAllUsers({ params });
        }
    }, [page, getAllUsers, updateUserStatus.loading, uploadExcelResult.loading, deleteUserStatus.loading]);

    useEffect(() => {
        fetchTableData();
    }, [fetchTableData]);

    useEffect(() => {
        if (allUsers.data) {
            const { total, page_total, results } = allUsers?.data;
            setTableData({ data: results, totalEntries: total, pageTotal: page_total });
        }
        // eslint-disable-next-line
    }, [allUsers.data, updateUserStatus.data, uploadExcelResult.data]);

    useEffect(() => {
        if (exportExcelStatus.loading === false && exportExcelStatus.data) {
            downloadExcel(exportExcelStatus.data, `Users-Details.xlsx`);
        }
    }, [exportExcelStatus.data, exportExcelStatus.loading]);

    useEffect(() => {
        if (downloadTemplateStatus.loading === false && downloadTemplateStatus.data) {
            downloadExcel(downloadTemplateStatus.data, `Users-Template.xlsx`);
        }
    }, [downloadTemplateStatus.data, downloadTemplateStatus.loading]);

    useEffect(() => {
        if (uploadExcelResult.loading === false && uploadExcelResult.error) {
            const message = uploadExcelResult.error?.response
                ? uploadExcelResult.error?.response?.data?.message
                : uploadExcelResult.error?.message;
            setErrorMessage(`Excel Upload Failed: ${message}`);
            setShowSnackBarError(true);
        } else if (uploadExcelResult.loading === false && uploadExcelResult.data) {
            setShowSnackBarSuccess(true);
        }
    }, [uploadExcelResult.loading, uploadExcelResult.data, uploadExcelResult.error]);

    const handleFileUpload = (event: any): void => {
        const file = event?.target?.files?.[0];
        try {
            const data = new FormData();
            data.append('excel', file);
            data.append('fileType', 'USER_DATA');
            data.append('fileId', 'USER_DATA');
            uploadExcel({ data });
            fetchTableData();
        } catch (err) {
            console.error(err);
        }
    };

    const handleExcelExport = (): void => {
        exportExcelPost();
    };

    const handleEdit = (index: number): void => {
        setSelectedRow(index);
        if (allUsers?.data?.results) setEditModalState(true);
    };

    const handleDelete = (index: number): void => {
        const email = allUsers?.data?.results.find((ele) => ele.id === index)?.email ?? '';
        setEmailToDelete(email);
        setConfirmationModalState(true);
    };

    const handleConfirmDelete = async (): Promise<void> => {
        const pathParams = { emailId: emailToDelete };
        if (await deleteUser({ pathParams })) {
            await fetchTableData();
        } else {
            setErrorMessage(`Delete user failed`);
            setShowSnackBarError(true);
        }
        setConfirmationModalState(false);
    };

    const handleEditUserConfirm = async (updatedUserInfo: UsersResponse): Promise<void> => {
        const email = allUsers?.data?.results.find((ele) => ele.id === updatedUserInfo.id)?.email;
        const pathParams = { emailId: email };
        if (await updateUser({ data: updatedUserInfo, pathParams })) {
            fetchTableData();
            setEditModalState(false);
        } else {
            setErrorMessage(`Update user failed`);
            setShowSnackBarError(true);
        }
    };

    const formattedTableData = tableData.data ? formatTableData(tableData.data) : [];

    const tableDataWithEditAndDelete =
        formattedTableData.map((row) => {
            const isCurrentUser = row.emailId.toLowerCase() === info?.idInfo?.email?.toLowerCase();
            return {
                ...row,
                edit: isCurrentUser ? null : (
                    <div style={{ cursor: 'pointer' }} onClick={(): void => handleEdit(row.id)}>
                        <EditIcon />
                    </div>
                ),
                delete: isCurrentUser ? null : (
                    <div style={{ cursor: 'pointer' }} onClick={(): void => handleDelete(row.id)}>
                        <DeleteIcon />
                    </div>
                ),
            };
        }) ?? [];

    return (
        <S.Container>
            <Snackbar
                message={errorMessage}
                isOpen={showSnackBarError}
                onClose={(): void => setShowSnackBarError(false)}
                variant="error"
                autoHideDuration={10000}
            />
            <Snackbar
                message="Excel Upload Successful"
                isOpen={showSnackBarSuccess}
                onClose={(): void => setShowSnackBarSuccess(false)}
                variant="success"
            />
            <ConfirmationModal
                isOpen={confirmationModalState}
                email={emailToDelete}
                handleConfirm={handleConfirmDelete}
                handleCancel={(): void => setConfirmationModalState(false)}
                loading={deleteUserStatus.loading || allUsers.loading}
            />
            <S.TitleContainer>
                <div>
                    <Title
                        title="User Management"
                        showBackButton
                        handleBackButtonClick={(): void => history.goBack()}
                    />
                </div>
                <S.ActionButtonContainer>
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={(): void => {
                            downloadTemplate();
                        }}
                        disabled={downloadTemplateStatus.loading}
                    >
                        Download Template
                    </Button>
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={handleExcelExport}
                        disabled={exportExcelStatus.loading}
                    >
                        Download User List
                    </Button>
                    <div>
                        <input
                            id="fileUpload"
                            type="file"
                            accept=".xls,.xlsx"
                            hidden
                            onChange={(e): void => handleFileUpload(e)}
                            onClick={(e): void => {
                                (e.target as HTMLInputElement).value = '';
                            }}
                        />
                        <Button
                            buttonType={ButtonType.SECONDARY}
                            onClickHandler={(): void => document.getElementById('fileUpload')?.click()}
                            disabled={uploadExcelResult.loading}
                        >
                            {uploadExcelResult.loading ? <CircularProgress size={24} /> : 'Add Users'}
                        </Button>
                    </div>
                </S.ActionButtonContainer>
            </S.TitleContainer>
            <S.BodyContainer>
                <Table
                    bodyCells={userTableBodyCells}
                    data={tableDataWithEditAndDelete}
                    headCells={userTableHeadCells}
                    totalTableEntries={tableData.totalEntries}
                    totalTableEntriesLabel="TOTAL USERS"
                    hideSerialNumberColumn
                />
            </S.BodyContainer>
            <Pagination
                count={Math.ceil((allUsers?.data?.total ?? 0) / maxEntriesPerPage)}
                page={page}
                onChange={(event, value): void => setPage(value)}
                showFirstButton
                showLastButton
            />
            {editModalState && (
                <EditModal
                    isOpen={editModalState}
                    handleClose={(): void => setEditModalState(false)}
                    userData={allUsers?.data?.results?.find((row) => row.id === selectedRow)}
                    handleConfirm={handleEditUserConfirm}
                    loading={updateUserStatus.loading}
                />
            )}
        </S.Container>
    );
}
