import styled, { css } from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import { Radio, FormControlLabel } from '@material-ui/core';

export const Container = styled.div(
    ({ theme }) => css`
        width: 100%;
    `,
);

export const Label = styled(InputLabel)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        text-transform: uppercase;
    `,
);

export const StyledRadio = styled(Radio)(
    ({ theme }) => css`
        color: ${theme.colors.accentPrimary};
        &.Mui-checked {
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const StyledFormControlLabel = styled(FormControlLabel)(
    ({ theme }) => css`
        > span {
            ${theme.typography.robotoRegular14};
        }
    `,
);
