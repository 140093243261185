import { useState, useEffect, useCallback } from 'react';
import { makeRequest } from '../utils/apiUtil';
import { AxiosError, Method, ResponseType } from 'axios';
import { useAuthContext } from '../context/Auth';

export type RequestVariables = {
    headers?: object;
    params?: object;
    data?: object;
    pathParams?: object;
};

export type RouteConfig = {
    path: string;
    method: Method;
    responseType?: ResponseType;
} & RequestVariables;

export function useFetch<T>(
    routeConfig: RouteConfig,
    loadOnMount = false,
): [
    { data?: T; loading: boolean; error?: AxiosError; dataAll?: Array<T> },
    (requestVariables?: RequestVariables) => Promise<boolean>,
    (requestList: Array<RequestVariables>) => Promise<boolean>,
    () => void,
] {
    const [data, setData] = useState<T>();
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState<boolean>(false);
    const [dataAll, setDataAll] = useState<Array<T>>();
    const { info, logout } = useAuthContext();

    const fetch = async (requestVariables?: RequestVariables): Promise<boolean> => {
        let hasSucceeded = false;
        setLoading(true);
        setError(undefined);
        try {
            let path = routeConfig.path;
            const pathParams = requestVariables?.pathParams ?? routeConfig.pathParams;
            if (pathParams) {
                Object.entries(pathParams).forEach(([param, value]) => {
                    path = path.replace(`:${param}`, value);
                });
            }
            const response = await makeRequest({ ...routeConfig, ...requestVariables, path }, info, logout);
            setData(response.data);
            hasSucceeded = true;
        } catch (error) {
            setError(error);
            hasSucceeded = false;
        }
        setLoading(false);
        return hasSucceeded;
    };

    const fetchAll = async (requestList: Array<RequestVariables>): Promise<boolean> => {
        let hasSucceeded = false;
        setLoading(true);
        setError(undefined);
        try {
            const result = await Promise.all(
                requestList.map((requestVariable) => {
                    let path = routeConfig.path;
                    const pathParams = requestVariable?.pathParams ?? routeConfig.pathParams;
                    if (pathParams) {
                        Object.entries(pathParams).forEach(([param, value]) => {
                            path = path.replace(`:${param}`, value);
                        });
                    }
                    return makeRequest({ ...routeConfig, ...requestVariable, path }, info, logout);
                }),
            );
            setDataAll(result.map((response) => response.data));
            hasSucceeded = true;
        } catch (error) {
            setError(error);
            hasSucceeded = false;
        }
        setLoading(false);
        return hasSucceeded;
    };

    const reset = (): void => {
        setLoading(false);
        setError(undefined);
        setData(undefined);
        setDataAll(undefined);
    };

    const memoizedFetch = useCallback(fetch, []);
    const memoizedFetchAll = useCallback(fetchAll, []);
    const memoizedReset = useCallback(reset, []);

    useEffect(() => {
        if (loadOnMount) memoizedFetch();
    }, [loadOnMount, memoizedFetch]);

    return [{ data, loading, error, dataAll }, memoizedFetch, memoizedFetchAll, memoizedReset];
}
