import React, { useEffect, useState, useContext } from 'react';
import { GlobalStoreContext } from '../../store/store.provider';
import { useHistory } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { Module } from '../../types/modules.types';
import Title from '../../components/Title/Title';
import CircularButton, { CircularButtonType } from '../../components/CircularButton/CircularButton';
import AnalysisSelector from './components/AnalysisSelector/AnalysisSelector';
import AnalysisChart from './components/AnalysisChart/AnalysisChart';
import AnalysisMetrics from './components/AnalysisMetrics/AnalysisMetrics';
import {
    getAnalysisListForModuleConfig,
    GetAnalysisListForModuleResponse,
    deleteAnalysisConfig,
    DeleteAnalysisResponse,
    getAnalysisByIdConfig,
    GetAnalysisByIdResponse,
    newAnalysisExportConfig,
} from '../../constants/apiConfig';
import { formatAnalysisDataForNestedList, getAggregatedAnalysisData, formatForExport } from './AnalysisExplorer.utils';
import { downloadExcel } from '../../utils/fileUtils';
import ConfirmationModal from './components/ConfirmationDialog/ConfirmationDialog';

import * as S from './AnalysisExplorer.style';

export type AggregatedAnalysisData = {
    tooling_investment: number;
    design_investment: number;
    line_investment: number;
    other_investment: number;
    variant_reduction_result: number;
    dmc_result: number;
    scale_benefit_result: number;
    sunk_cost: number;
    cash_flow_release_result: number;
    effiency_enhancement_result: number;
    effiency_enhancement_hours_result: number;
    inventory_cost: number;
    warranty_cost: number;
    rejection_cost: number;
    logistics_cost: number;
    payback_period: number;
    npv: number;
    irr: number;
};

export default function AnalysisExplorer(): JSX.Element {
    const {
        state: { modules },
    } = useContext(GlobalStoreContext);
    const history = useHistory();

    const [analysisList, getAnalysisList] = useFetch<GetAnalysisListForModuleResponse>(getAnalysisListForModuleConfig);
    const [deleteAnalysisStatus, deleteAnalysis] = useFetch<DeleteAnalysisResponse>(deleteAnalysisConfig);
    const [analysisDetails, , getAnalysisDetails] = useFetch<GetAnalysisByIdResponse>(getAnalysisByIdConfig);
    const [exportExcelStatus, exportExcelPost, , exportExcelReset] = useFetch<Blob>(newAnalysisExportConfig);

    const [selectedModule, setSelectedModule] = useState<Module>();
    const [selectedAnalyses, setSelectedAnalyses] = useState<Array<string>>([]);
    const [confirmationModalState, setConfirmationModalState] = useState<boolean>(false);

    useEffect(() => {
        if (modules.length === 1) {
            setSelectedModule(modules[0]);
        } else {
            setSelectedModule(undefined);
        }
    }, [modules]);

    useEffect(() => {
        if (selectedModule) {
            const params = { module: selectedModule.label };
            getAnalysisList({ params });
        }
    }, [selectedModule, getAnalysisList]);

    useEffect(() => {
        if (deleteAnalysisStatus.loading === false && deleteAnalysisStatus.data && selectedModule) {
            setSelectedAnalyses([]);
            const params = { module: selectedModule.label };
            getAnalysisList({ params });
        }
    }, [deleteAnalysisStatus.data, deleteAnalysisStatus.loading, selectedModule, getAnalysisList]);

    useEffect(() => {
        if (selectedAnalyses) {
            const routeVariables = selectedAnalyses.map((analysis) => ({
                pathParams: { id: analysis },
            }));
            getAnalysisDetails(routeVariables);
        }
    }, [selectedAnalyses, getAnalysisDetails]);

    useEffect(() => {
        if (exportExcelStatus.loading === false && exportExcelStatus.data) {
            downloadExcel(exportExcelStatus.data, `Analysis-${selectedAnalyses[0]}-Summary.xlsx`);
            exportExcelReset();
        }
    }, [exportExcelStatus.loading, exportExcelStatus.data, selectedAnalyses, exportExcelReset]);

    const handleDeleteAnalysis = (): void => {
        const pathParams = { id: selectedAnalyses[0] };
        deleteAnalysis({ pathParams });
        setConfirmationModalState(false);
    };

    const handleEditAnalysis = (): void => {
        if (analysisDetails.dataAll) {
            history.push(`/newAnalysis/${selectedAnalyses[0]}`, {
                ...analysisDetails.dataAll?.[0],
                editAnalysis: true,
            });
        }
    };

    const handleConfirmationModalClose = (): void => {
        setConfirmationModalState(false);
    };

    const handleConfirmationModalOpen = (): void => {
        setConfirmationModalState(true);
    };

    const filteredAnalysisList = analysisList.data?.filter(
        (analysis) => analysis.status === 'IN_PROGRESS' || analysis.status === 'SUBMITTED',
    );
    const formattedAnalysisList = filteredAnalysisList
        ? formatAnalysisDataForNestedList(filteredAnalysisList)
        : undefined;

    const aggregatedAnalysisData = getAggregatedAnalysisData(analysisDetails.dataAll ?? []);

    const handleExport = (): void => {
        if (analysisDetails.dataAll) {
            const analysisDetailsRes: GetAnalysisByIdResponse = analysisDetails.dataAll[0];
            const data = formatForExport(analysisDetailsRes);
            exportExcelPost({ data });
        }
    };

    return (
        <S.Container>
            <S.TitleContainer>
                <ConfirmationModal
                    analysisId={selectedAnalyses[0]}
                    isOpen={confirmationModalState}
                    handleConfirm={handleDeleteAnalysis}
                    handleCancel={handleConfirmationModalClose}
                />
                <Title title="Explore Analysis" showBackButton handleBackButtonClick={(): void => history.goBack()} />
                <S.ActionButtonContainer>
                    {selectedAnalyses.length === 1 && (
                        <CircularButton
                            variant={CircularButtonType.DELETE}
                            label="DELETE"
                            onClickHandler={handleConfirmationModalOpen}
                        />
                    )}
                    {selectedAnalyses.length === 1 && analysisDetails?.dataAll && (
                        <CircularButton
                            variant={CircularButtonType.EDIT}
                            label="EDIT"
                            onClickHandler={handleEditAnalysis}
                        />
                    )}
                    {selectedAnalyses.length === 1 && analysisDetails?.dataAll && (
                        <CircularButton
                            variant={CircularButtonType.EXPORT}
                            onClickHandler={handleExport}
                            label="EXPORT"
                        />
                    )}
                </S.ActionButtonContainer>
            </S.TitleContainer>
            <S.BodyContainer>
                <AnalysisSelector
                    data={formattedAnalysisList}
                    loading={analysisList.loading}
                    error={!!analysisList.error}
                    modules={modules}
                    selectedModule={selectedModule}
                    setSelectedModule={setSelectedModule}
                    selectedAnalysis={selectedAnalyses}
                    setSelectedAnalysis={setSelectedAnalyses}
                />
                <AnalysisChart
                    data={aggregatedAnalysisData}
                    loading={analysisDetails.loading}
                    error={!!analysisDetails.error}
                />
                <AnalysisMetrics
                    data={aggregatedAnalysisData}
                    loading={analysisDetails.loading}
                    error={!!analysisDetails.error}
                />
            </S.BodyContainer>
        </S.Container>
    );
}
