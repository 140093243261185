import { AnalysesAggregate, Analysis, AnalysisTableData } from './Overview.types';
import { SubModule } from '../../types/modules.types';

export const aggregateAnalyses = (analyses: Analysis[]): AnalysesAggregate => {
    return analyses.reduce(
        (acc, analysis) => {
            const { status } = analysis;
            if (status === 'SUBMITTED') acc.submitted += 1;
            else if (status === 'IN_PROGRESS') acc.inProgress += 1;
            return acc;
        },
        {
            submitted: 0,
            inProgress: 0,
        },
    );
};

// TODO: implement actual aggregate based on analyses;
const getEffectiveVariantReduction = (analyses: Analysis[], subModule: string): string => {
    const { uniqueCurrentParts, uniqueProposedParts } = analyses.reduce(
        (acc, curr) => {
            if (curr.hierarchy_6 === subModule && curr.status === 'SUBMITTED') {
                acc.uniqueCurrentParts += curr.unique_current_parts;
                acc.uniqueProposedParts += curr.unique_proposed_parts;
            }
            return acc;
        },
        {
            uniqueCurrentParts: 0,
            uniqueProposedParts: 0,
        },
    );

    const rawVariantReduction = 1 - uniqueProposedParts / uniqueCurrentParts;
    const variantReduction = isNaN(rawVariantReduction) ? 0 : rawVariantReduction;
    return (variantReduction * 100).toFixed(2);
};

// TODO: implement actual aggregate based on analyses;
export const getAnalysesTableData = (analyses: Analysis[], subModules: SubModule[]): AnalysisTableData[] => {
    return subModules.map((subModule) => {
        const subModuleLabel = subModule.label;
        return {
            hierarchy6: subModuleLabel,
            uniqueVariants: subModule.uniqueVariants,
            variantReduction: getEffectiveVariantReduction(analyses, subModuleLabel),
        };
    });
};
