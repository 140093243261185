import React from 'react';
import { Metric, MetricLabel, MetricValue } from './Metric.style';

interface Props {
    value: string | number;
    label: string;
    dark?: boolean;
    subText?: string;
    showUnitMarker?: boolean;
    distinctColor?: boolean;
}

export default function StyledMetric({
    value,
    label,
    dark = false,
    subText,
    showUnitMarker = false,
    distinctColor = false,
}: Props): JSX.Element {
    return (
        <Metric>
            <MetricValue variant="inherit" dark={dark} distinctColor={distinctColor}>
                {typeof value === 'number' ? value.toFixed(2) : value}
                {subText && (
                    <MetricLabel variant="inherit" dark={dark}>
                        {subText}
                    </MetricLabel>
                )}
            </MetricValue>
            <MetricLabel
                variant="inherit"
                dark={dark}
                uppercase
                showUnitMarker={showUnitMarker}
                distinctColor={distinctColor}
            >
                {label}
            </MetricLabel>
        </Metric>
    );
}
