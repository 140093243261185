import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        flex: 4;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundLight};
        ${theme.typography.robotoRegular12}
    `,
);

export const ClientDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 1rem;
`;

export const ClientDetailsContainerModal = styled.div`
    margin: -5px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

export const HeaderContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        padding: 1.5rem;
        border-radius: 10px 10px 0 0;
        justify-content: space-between;
        align-items: center;
        background-color: ${theme.colors.backgroundDark};
    `,
);

export const NameAndAddressContainer = styled.div(
    ({ theme }) => css`
        flex-basis: 33.33%;
        ${theme.typography.robotoLight24};
        color: ${theme.colors.textDark};
        > div:last-child {
            ${theme.typography.robotoRegular14};
            margin-top: 0.5rem;
        }
    `,
);

export const CenteredElement = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DisplayContainer = styled.div(
    ({ theme }) => css`
        flex-basis: 33.33%;
        padding: 1rem 0.5rem;
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.subtextLight};
        > div {
            ${theme.typography.robotoMedium16};
            color: ${theme.colors.textLight};
            margin-top: 0.5rem;
        }
    `,
);

export const SectionTitle = styled.h5(
    ({ theme }) => css`
        flex-basis: 100%;
        margin-left: 0.5rem;
        ${theme.typography.robotoMedium14};
    `,
);

export const FlexContainer = styled.div`
    flex-basis: 50%;
    padding: 0.5rem 0.5rem;
`;

export const ContactContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        border: 1px solid ${theme.colors.accentPrimary};
        border-radius: 10px;
        padding: 1rem;
        color: ${theme.colors.textLight};
        > div:first-child {
            flex-basis: 100%;
            ${theme.typography.robotoRegular16};
            margin-top: 0;
        }
        > div {
            flex-basis: 50%;
            ${theme.typography.robotoRegular12};
            margin-top: 1rem;
        }
    `,
);

export const LogoContainer = styled.div(
    ({ theme }) => css`
        width: 80px;
        height: 80px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: ${theme.colors.greyScaleLight};
        color: ${theme.colors.accentPrimary};
    `,
);

export const Img = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 4px;
    object-fit: contain;
`;
