import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85vw;
        ${theme.typography.robotoRegular14};
        color: ${theme.colors.textLight};
    `,
);

export const Title = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        ${theme.typography.robotoLight32};
    `,
);

export const PartDetails = styled.div`
    display: flex;
    flex-wrap: wrap;
    > div {
        padding: 1rem 0.75rem;
        flex-basis: 25%;
    }
    > h4 {
        margin-top: 2rem;
        flex-basis: 100%;
    }
`;

export const SparePartsDetails = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        border-radius: 10px;
        margin-top: 2rem;
        background-color: ${theme.colors.greyScaleLight};
        > div {
            padding: 1rem 0.75rem;
            flex-basis: 25%;
        }
        > h5 {
            padding: 1rem 0.75rem 0 0.75rem;
            flex-basis: 100%;
        }
    `,
);

export const SectionTitle = styled.h4(
    ({ theme }) => css`
        margin-left: 0.7rem;
        ${theme.typography.robotoMedium14}
    `,
);

export const SparePartSectionTitle = styled.h5(
    ({ theme }) => css`
        color: ${theme.colors.greyScaleDark};
        ${theme.typography.robotoMedium10}
    `,
);

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    width: 100%;
    > div {
        margin-left: auto;
        width: 200px;
    }
`;

export const Validation = styled.div(
    ({ theme }) => css`
        color: #f44336;
        margin-top: 0.5rem;
        ${theme.typography.robotoRegular12}
    `,
);
