import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import { ClientDetails, toolContactConfig } from '../../ClientOnboarding.config';

import * as S from '../DisplayClientData/DisplayClientData.style';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    clientDetails: ClientDetails | undefined;
};

export default function DisplayToolContact({ isOpen, handleClose, clientDetails }: Props): JSX.Element {
    return (
        <Modal isOpen={isOpen} title="Tool Contact" handleClose={handleClose}>
            <S.ClientDetailsContainerModal>
                <S.SectionTitle>Contacts for your support</S.SectionTitle>
                {toolContactConfig.map((contactConfig) => (
                    <S.FlexContainer key={contactConfig.label}>
                        <S.ContactContainer key={contactConfig.label}>
                            {contactConfig.inputs.map((config) => (
                                <div key={`${config.key}${contactConfig.label}`}>
                                    {clientDetails?.[config.key] || '--'}
                                </div>
                            ))}
                        </S.ContactContainer>
                    </S.FlexContainer>
                ))}
            </S.ClientDetailsContainerModal>
        </Modal>
    );
}
