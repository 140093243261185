import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        border-radius: 10px;
        padding: 1.5rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundDark};
        overflow: auto;

        > div {
            margin-bottom: 1.5rem;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    `,
);

export const AnalysisListContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex: 1;
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.textDark};
    `,
);
