import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: fixed;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const Box = styled.div(
    ({ theme }) => css`
        width: 30%;
        padding: 1rem;
        border: 1px solid ${theme.colors.accentPrimary};
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
            margin-top: 5%;
            width: 50%;
        }
    `,
);

export const Title = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoBold18};
        text-transform: capitalize;
    `,
);
