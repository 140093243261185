import React, { useState, useMemo, useEffect } from 'react';
import Title from '../../components/Title/Title';
import ImageCard from '../../components/ImageCard/ImageCard';
import Select from '../../components/Select/Select';
import Button, { ButtonType } from '../../components/Button/Button';
import AnalysesTable from '../../components/Table/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetch } from '../../hooks/useFetch';
import {
    getHierarchyPLDataConfig,
    GetHierarchyDataResponse,
    createNewProjectConfig,
    getProjectsForPlatformConfig,
    GetProjectsForPlatformResponse,
    GetAnalysisListByVerticalHierarchyResponse,
    getAnalysisListByVerticalHierarchyConfig,
} from '../../constants/apiConfig';
import { getAnalysesTableData } from './PLOverview.utils';
import PlaceHolderImage from '../../resources/AddImagePlaceholder.jpg';
import { useHistory } from 'react-router-dom';

import * as S from './PLOverview.style';

export default function PLOverview(): JSX.Element {
    const history = useHistory();

    const [selectedPlatform, setSelectedPlatform] = useState<string>('');
    const [selectedProduct, setSelectedProduct] = useState<string>('');

    const [{ data: hierarchy }] = useFetch<GetHierarchyDataResponse>(getHierarchyPLDataConfig, true);
    const [projectList, getProjectList] = useFetch<GetProjectsForPlatformResponse>(getProjectsForPlatformConfig);
    const [newProject, createNewProject] = useFetch<number>(createNewProjectConfig);
    const [analysisList, getAnalysisList] = useFetch<GetAnalysisListByVerticalHierarchyResponse>(
        getAnalysisListByVerticalHierarchyConfig,
    );
    const platformOptions = useMemo(() => {
        return hierarchy?.platforms?.map((platform) => ({ label: platform?.label, value: platform?.label })) ?? [];
    }, [hierarchy]);

    const productOptions = useMemo(() => {
        const platform = hierarchy?.platforms?.find((platform) => platform?.label === selectedPlatform) ?? undefined;
        return platform?.products?.map((product) => ({ label: product?.label, value: product?.label })) ?? [];
    }, [hierarchy, selectedPlatform]);

    useEffect(() => {
        if (selectedPlatform) {
            const params = { platform: selectedPlatform };
            getProjectList({ params });
        }
    }, [selectedPlatform, getProjectList]);

    useEffect(() => {
        setSelectedPlatform(platformOptions[0]?.label);
        const platform =
            hierarchy?.platforms?.find((platform) => platform?.label === platformOptions[0]?.label) ?? undefined;
        setSelectedProduct(platform?.products?.[0]?.label ?? '');
    }, [platformOptions, hierarchy]);

    useEffect(() => {
        if (newProject.loading === false && newProject.data) {
            history.push(`/newProject/${newProject.data}`, { id: newProject.data, isEdit: false });
        }
    }, [newProject.data, newProject.loading, history]);

    useEffect(() => {
        if (selectedPlatform && selectedProduct) {
            const params = {
                platform: selectedPlatform,
                product: selectedProduct,
            };
            getAnalysisList({ params });
        }
    }, [selectedPlatform, selectedProduct, getAnalysisList]);

    const handleCreateProject = (): void => {
        if (selectedPlatform && selectedProduct) {
            createNewProject({ data: { platform: selectedPlatform, product: selectedProduct } });
        }
    };

    const projectsForProduct = useMemo(
        () => projectList?.data?.find((project) => project.product === selectedProduct),
        [selectedProduct, projectList],
    );
    const submittedCount =
        projectsForProduct?.projects?.filter((project) => project.status === 'SUBMITTED').length ?? 0;
    const inProgressCount =
        projectsForProduct?.projects?.filter((project) => project.status === 'IN_PROGRESS').length ?? 0;

    const modelList = [...new Set(analysisList.data?.map((analysis) => analysis.hierarchy_5))];

    const analysesTableData = useMemo(() => getAnalysesTableData(analysisList.data ?? [], modelList), [
        analysisList.data,
        modelList,
    ]);

    const handlePlatformChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const value = event?.target?.value ?? '';
        setSelectedPlatform(value as string);
        const platform = hierarchy?.platforms?.find((platform) => platform?.label === value) ?? undefined;
        setSelectedProduct(platform?.products?.[0]?.label ?? '');
    };

    return (
        <S.Container>
            <Title title="Overview" />

            <S.BodyContainer>
                <S.LeftPane>
                    <S.SelectHeader>PLATFORM</S.SelectHeader>
                    <Select
                        handleChange={handlePlatformChange}
                        value={selectedPlatform}
                        label="Platform"
                        items={platformOptions}
                    />
                    <S.SelectHeader>PRODUCT</S.SelectHeader>
                    <Select
                        handleChange={(event): void => setSelectedProduct(event?.target?.value as string)}
                        value={selectedProduct}
                        label="Product"
                        items={productOptions}
                    />
                    <S.ImageCardContainer>
                        <ImageCard imageSource={PlaceHolderImage} />
                    </S.ImageCardContainer>
                    <S.ProductName>{selectedProduct ?? ''}</S.ProductName>
                    <S.ProductDescription>
                        Product Description
                        {''}
                    </S.ProductDescription>

                    <S.ProductStatContainer>
                        <div>
                            <S.ProductStatValue>{inProgressCount + submittedCount}</S.ProductStatValue>
                            <S.ProductStatLabel>Total Projects</S.ProductStatLabel>
                        </div>
                        <div>
                            <S.ProductStatValue>{submittedCount}</S.ProductStatValue>
                            <S.ProductStatLabel>Submitted</S.ProductStatLabel>
                        </div>
                        <div>
                            <S.ProductStatValue>{inProgressCount}</S.ProductStatValue>
                            <S.ProductStatLabel>In progress</S.ProductStatLabel>
                        </div>
                    </S.ProductStatContainer>
                </S.LeftPane>

                <S.TableCard>
                    <S.TableHeader>MODULE SUMMARY</S.TableHeader>
                    <AnalysesTable
                        headCells={['Module', 'Analyses', 'Variant reduction (%)']}
                        data={analysesTableData}
                        bodyCells={['hierarchy5', 'analyses', 'variantReduction']}
                        columnAlignment={['left', 'center', 'center']}
                        wrapText
                    />
                </S.TableCard>

                <S.ButtonCard>
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={handleCreateProject}
                        disabled={!selectedPlatform || !selectedProduct || newProject.loading}
                    >
                        {newProject.loading ? <CircularProgress size={24} /> : ' NEW PROJECT'}
                    </Button>
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        onClickHandler={(): void => history.push('/quickAnalysis/1')}
                    >
                        QUICK ANALYSIS
                    </Button>
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        onClickHandler={(): void => history.push('/ProjectExplorer')}
                    >
                        EXPLORE PROJECTS
                    </Button>
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        onClickHandler={(): void => history.push('/moduleExplorer')}
                    >
                        EXPLORE MODULES
                    </Button>
                </S.ButtonCard>
            </S.BodyContainer>
        </S.Container>
    );
}
