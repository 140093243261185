import React from 'react';
import { Slider, SliderLabel, SliderContainer, SliderLabelContainer } from './Slider.style';

interface Props {
    max?: number;
    min?: number;
    step?: number;
    label: string;
    onChange: Function;
    value: number;
    marks?: { value: number; label: string }[];
}

export default function StyledSlider({
    max = 1,
    min = 0,
    step = 0.1,
    label,
    onChange,
    value,
    marks,
}: Props): JSX.Element {
    return (
        <SliderContainer>
            <SliderLabelContainer>
                <SliderLabel>{label}</SliderLabel>
                <SliderLabel>{value}</SliderLabel>
            </SliderLabelContainer>
            <Slider
                max={max}
                min={min}
                step={step}
                value={value}
                onChange={(_, newValue): void => onChange(newValue)}
                marks={marks}
            />
        </SliderContainer>
    );
}
