import React from 'react';
import { Row } from '../PartsSelection.style';
import { Heading, SubHeading } from './PartsSelectionHeader.style';

interface Props {
    currentPartsCount: number;
    proposedPartsCount: number;
}

function PartsSelectionHeader({ currentPartsCount, proposedPartsCount }: Props): JSX.Element {
    return (
        <div>
            <Row>
                <Heading>current parts</Heading>
                <Heading>proposed parts</Heading>
            </Row>
            <Row>
                <SubHeading>{currentPartsCount} - unique variants</SubHeading>
                <SubHeading>{proposedPartsCount} - unique variants</SubHeading>
            </Row>
        </div>
    );
}

export default PartsSelectionHeader;
