import React, { useState } from 'react';
import {
    Container,
    StyledDialog,
    StyledDialogContent,
    StyledButton,
    DialogActions,
    HeaderContainer,
    ModalTitle,
    ModalSubTitle,
    PartsContainer,
    PartsBox,
    PartsBoxTitle,
    NewPartText,
    PartOptionsContainer,
    FileCopyOutlinedIcon,
    DescriptionOutlinedIcon,
    DeleteOutlineOutlinedIcon,
    StyledTableCell,
    StyledTableRow,
    TableSubHeader,
    ToolIcon,
    PasteAcrossOutlinedIcon,
    AddNewPartContainer,
    Asterisk,
    StyledIconButton,
} from './QuickAnalysisModal.style';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { PartData } from '../QuickAnalysis.types';
import { defaultPartsData } from '../QuickAnalysis.constants';
import Popover from '@material-ui/core/Popover';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    textField: {
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
    },
});

interface TableProps {
    partsData: PartData[];
    handleInputChange: any;
    handleCopyClick: () => void;
    handlePasteClick: () => void;
    handlePasteAcross?: () => void;
    setSelectedRow: any;
    handleDeleteClick: () => void;
    tableType: 'current' | 'proposed';
}

function PartsTable({
    partsData,
    handleInputChange,
    setSelectedRow,
    tableType,
    handleCopyClick,
    handlePasteClick,
    handleDeleteClick,
    handlePasteAcross,
}: TableProps): JSX.Element {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleToolIconClick = (event: React.MouseEvent<HTMLDivElement>, index: number): void => {
        setSelectedRow({ tableType: tableType, row: index });
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const tableHeadValues = ['Part No.', 'Quantity', 'Part Cost', 'Volume', 'Inventory', 'Bin Size', 'Parts/Bin', ''];
    const tableSubHeader = ['', '', 'INR/Unit', 'ANNUAL', 'AVERAGE DAYS', '', ''];
    const tableMandatory = [true, true, true, true, false, false, false];

    return (
        <TableContainer component={Paper}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PartOptionsContainer>
                    <Tooltip title="Copy">
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={(): void => {
                                handleCopyClick();
                                handleClose();
                            }}
                        >
                            <FileCopyOutlinedIcon />
                        </div>
                    </Tooltip>
                    <Tooltip title="Paste">
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={(): void => {
                                handlePasteClick();
                                handleClose();
                            }}
                        >
                            <DescriptionOutlinedIcon />
                        </div>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={(): void => {
                                handleDeleteClick();
                                handleClose();
                            }}
                        >
                            <DeleteOutlineOutlinedIcon />
                        </div>
                    </Tooltip>
                    {tableType === 'current' ? (
                        <Tooltip title="Copy Across">
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={(): void => {
                                    if (handlePasteAcross) handlePasteAcross();
                                    handleClose();
                                }}
                            >
                                <PasteAcrossOutlinedIcon />
                            </div>
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </PartOptionsContainer>
            </Popover>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {tableHeadValues.map((title, index) => {
                            return (
                                <StyledTableCell key={index} align="left">
                                    <div>
                                        {title}
                                        {tableMandatory[index] && <Asterisk>*</Asterisk>}
                                    </div>
                                    <TableSubHeader>{tableSubHeader[index]}</TableSubHeader>
                                </StyledTableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {partsData.map((row: PartData, index: number) => (
                        <StyledTableRow key={tableType + index}>
                            {Object.keys(row).map((fieldName) => {
                                return (
                                    <StyledTableCell key={fieldName} align="left">
                                        <TextField
                                            onChange={(e): void => handleInputChange(e, fieldName, index)}
                                            value={partsData[index][fieldName]}
                                            label=""
                                            type={fieldName !== 'partNum' ? 'number' : undefined}
                                            inputProps={{ min: '0' }}
                                            className={classes.textField}
                                        />
                                    </StyledTableCell>
                                );
                            })}
                            <StyledTableCell key={index + 'ham'} align="left">
                                <div style={{ cursor: 'pointer' }} onClick={(e): void => handleToolIconClick(e, index)}>
                                    <ToolIcon />
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default function PartsModal({
    showSnackbar,
    handleClose,
    open,
    onConfirm,
    currentParts,
    proposedParts,
    selectedIndustry,
    selectedSubIndustry,
}: any): JSX.Element {
    const [currentPartsData, setCurrentPartsData] = useState<PartData[]>([...defaultPartsData]);

    const [proposedPartsData, setProposedPartsData] = useState<PartData[]>([...defaultPartsData]);

    React.useEffect(() => {
        setCurrentPartsData(currentParts);
        setProposedPartsData(proposedParts);
        // eslint-disable-next-line
    }, []);

    const [selectedRow, setSelectedRow] = useState<{ tableType: 'current' | 'proposed'; row: number } | null>(null);
    const [copiedRow, setCopiedRow] = useState<{ tableType: 'current' | 'proposed'; row: number } | null>(null);

    const handleCurrentPartChange = (
        event: React.ChangeEvent<{ value: unknown }>,
        key: string,
        index: number,
    ): void => {
        const newObj = { ...currentPartsData[index], [key]: event.target.value };
        setCurrentPartsData((oldPartData) => {
            oldPartData[index] = newObj;
            return [...oldPartData];
        });
    };

    const handleProposedPartChange = (
        event: React.ChangeEvent<{ value: unknown }>,
        key: string,
        index: number,
    ): void => {
        const newObj = { ...proposedPartsData[index], [key]: event.target.value };
        setProposedPartsData((oldPartData) => {
            oldPartData[index] = newObj;
            return [...oldPartData];
        });
    };

    const handleAddNewCurrentPart = (): void => {
        setCurrentPartsData((oldPartData) => {
            oldPartData = [...oldPartData, { ...defaultPartsData[0] }];
            return [...oldPartData];
        });
        setProposedPartsData((oldPartData) => {
            oldPartData = [...oldPartData, { ...defaultPartsData[0] }];
            return [...oldPartData];
        });
    };

    const handleCopyClick = (): void => {
        if (selectedRow) {
            setCopiedRow(selectedRow);
        }
    };

    const handleDeleteClick = (): void => {
        if (selectedRow) {
            if (currentPartsData.length > selectedRow.row) {
                setCurrentPartsData((oldPartData) => {
                    const newPartsArray = [...oldPartData];
                    newPartsArray.splice(selectedRow.row, 1);
                    return [...newPartsArray];
                });
            }
            if (proposedPartsData.length > selectedRow.row) {
                setProposedPartsData((oldPartData) => {
                    const newPartsArray = [...oldPartData];
                    newPartsArray.splice(selectedRow.row, 1);
                    return [...newPartsArray];
                });
            }
        }
    };

    const handlePasteClick = (): void => {
        if (copiedRow && selectedRow) {
            const copiedRowContent =
                copiedRow.tableType === 'current' ? currentPartsData[copiedRow.row] : proposedPartsData[copiedRow.row];
            if (selectedRow.tableType === 'current') {
                setCurrentPartsData((oldPartData) => {
                    const newPartsArray = [...oldPartData];
                    newPartsArray[selectedRow.row] = copiedRowContent;
                    return [...newPartsArray];
                });
            } else {
                setProposedPartsData((oldPartData) => {
                    const newPartsArray = [...oldPartData];
                    newPartsArray[selectedRow.row] = copiedRowContent;
                    return [...newPartsArray];
                });
            }
        }
    };

    const validate = (): void => {
        let isValid = true;
        let errorText = '';
        currentPartsData.forEach((currentPart) => {
            if (
                currentPart.partNum === '' ||
                currentPart.quantity === '' ||
                currentPart.Volume === '' ||
                currentPart.partCost === ''
            ) {
                errorText = 'Part No., Quantity, Part Cost and Volume cannot be empty';
                isValid = false;
            }
            Object.keys(currentPart).forEach((key) => {
                if (key !== 'partNum' && parseInt(currentPart[key]) < 0) {
                    isValid = false;
                    errorText = 'Value cannot be negative';
                }
            });
        });

        proposedPartsData.forEach((proposedPart) => {
            if (
                proposedPart.partNum === '' ||
                proposedPart.quantity === '' ||
                proposedPart.Volume === '' ||
                proposedPart.partCost === ''
            ) {
                errorText = 'Part No., Quantity, Part Cost and Volume cannot be empty';
                isValid = false;
            }
            Object.keys(proposedPart).forEach((key) => {
                if (key !== 'partNum' && parseInt(proposedPart[key]) < 0) {
                    isValid = false;
                    errorText = 'Value cannot be negative';
                }
            });
        });
        if (isValid) {
            onConfirm({ currentPartsData, proposedPartsData, selectedIndustry, selectedSubIndustry });
        } else {
            showSnackbar({ isOpen: true, message: errorText });
        }
    };

    const handlePasteAcross = (): void => {
        if (selectedRow) {
            const copiedRowContent = currentPartsData[selectedRow.row];
            setProposedPartsData((oldPartData) => {
                oldPartData.splice(selectedRow.row, 1, copiedRowContent);
                return [...oldPartData];
            });
        }
    };

    return (
        <Container>
            <StyledDialog onClose={handleClose} open={open} maxWidth="xl">
                <StyledDialogContent>
                    <HeaderContainer>
                        <div>
                            <ModalTitle> ADD PART </ModalTitle>
                            <ModalSubTitle>Please enter details of the new part</ModalSubTitle>
                        </div>
                        <StyledIconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </StyledIconButton>
                    </HeaderContainer>
                    <PartsContainer>
                        <PartsBox>
                            <PartsBoxTitle>CURRENT</PartsBoxTitle>
                            <PartsTable
                                handleInputChange={handleCurrentPartChange}
                                partsData={currentPartsData}
                                tableType={'current'}
                                setSelectedRow={setSelectedRow}
                                handleCopyClick={handleCopyClick}
                                handlePasteClick={handlePasteClick}
                                handleDeleteClick={handleDeleteClick}
                                handlePasteAcross={handlePasteAcross}
                            />
                            <AddNewPartContainer>
                                <NewPartText onClick={handleAddNewCurrentPart}>+ADD NEW PART</NewPartText>
                            </AddNewPartContainer>
                        </PartsBox>
                        <PartsBox>
                            <PartsBoxTitle>PROPOSED</PartsBoxTitle>
                            <PartsTable
                                handleInputChange={handleProposedPartChange}
                                partsData={proposedPartsData}
                                tableType={'proposed'}
                                setSelectedRow={setSelectedRow}
                                handleCopyClick={handleCopyClick}
                                handlePasteClick={handlePasteClick}
                                handleDeleteClick={handleDeleteClick}
                            />
                        </PartsBox>
                    </PartsContainer>
                </StyledDialogContent>
                <DialogActions>
                    <StyledButton autoFocus onClick={validate} variant="contained">
                        CONFIRM
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        </Container>
    );
}
