import React from 'react';
import { RoundButton, Container, Label } from './CircularButton.style';
import SaveIcon from '@material-ui/icons/Save';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ResetIcon from '@material-ui/icons/Refresh';
import HomeIcon from '@material-ui/icons/Home';

export enum CircularButtonType {
    SAVE = 'SAVE',
    EXPORT = 'EXPORT',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    RESET = 'RESET',
    HOME = 'HOME',
}

interface Props {
    variant: CircularButtonType;
    onClickHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    label: string;
}

export default function CircularButton({ variant, onClickHandler, label = 'sample' }: Props): JSX.Element {
    const renderIcon = (variant: CircularButtonType): JSX.Element => {
        switch (variant) {
            case CircularButtonType.SAVE:
                return <SaveIcon />;
            case CircularButtonType.EXPORT:
                return <ShareIcon />;
            case CircularButtonType.EDIT:
                return <EditIcon />;
            case CircularButtonType.DELETE:
                return <DeleteIcon />;
            case CircularButtonType.RESET:
                return <ResetIcon />;
            case CircularButtonType.HOME:
                return <HomeIcon />;
        }
    };
    return (
        <Container>
            <RoundButton onClick={onClickHandler}>{renderIcon(variant)}</RoundButton>
            <Label>{label}</Label>
        </Container>
    );
}
