import styled, { css } from 'styled-components';
import { columnSpacing } from '../PartsSelection.style';

export const EnterNewPartButton = styled.button(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
        ${columnSpacing};
        color: ${theme.colors.greyScaleMedium};
        position: relative;
        border: none;
        outline: none;
        background: none;
        border-bottom: 1px solid ${theme.colors.greyScaleMedium};
        text-align: left;
        padding: 0.25rem 0;
        flex: 1;
        margin-top: 1.5rem;
        cursor: pointer;

        &:hover {
            border-bottom: 1px solid ${theme.colors.greyScaleDark};
        }
    `,
);
