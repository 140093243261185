import React, { useState, useCallback, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Table from '../../../../components/Table/Table';
import { formatTableData } from './DataTable.utils';
import { getDataViewPage } from '../../../../utils/apiUtil';
import * as S from './DataTable.style';
import { useAuthContext } from '../../../../context/Auth';

interface TableData {
    totalEntries: number;
    pageTotal: number;
    data: any[];
}

interface Props {
    tabIndex: number;
    tabConfig: any[];
}

export default function DataTable({ tabIndex, tabConfig }: Props): JSX.Element {
    const { info, logout } = useAuthContext();

    const currentTabConfig = tabConfig[tabIndex];

    const [page, setPage] = useState<number>(1);

    const maxEntriesPerPage = 10;

    const [tableData, setTableData] = useState<TableData>({
        data: [],
        totalEntries: 0,
        pageTotal: 10,
    });

    useEffect(() => {
        setPage(1);
    }, [tabIndex]);

    const fetchTableData = useCallback(async () => {
        const { data } = await getDataViewPage(
            { pageId: page - 1, limit: 10, path: currentTabConfig.path },
            info,
            logout,
        );
        setTableData({
            data: formatTableData(data, currentTabConfig),
            totalEntries: data?.[currentTabConfig.key]?.total ?? 0,
            pageTotal: data?.[currentTabConfig.key]?.page_total ?? 10,
        });
    }, [page, currentTabConfig, info, logout]);

    useEffect(() => {
        fetchTableData();
    }, [fetchTableData]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setPage(value);
    };

    return (
        <S.TableContainer>
            <Table
                bodyCells={tabConfig[tabIndex].bodyCells}
                headCells={tabConfig[tabIndex].headCells}
                data={tableData.data}
                hideSerialNumberColumn
            />
            <Pagination
                count={Math.ceil(tableData.totalEntries / maxEntriesPerPage)}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
            />
        </S.TableContainer>
    );
}
