export type Locationstate = {
    analysisId: number;
    selectedModule: string;
    selectedSubmodule: string;
    toolingInvestment: number;
    designInvestment: number;
    residualInvestment: number;
    otherInvestment: number;
    lineInvestment: number;
    residualToolingInvestment: number;
    analysisResults: {
        dmcResult: number;
        scaleBenefitResult: number;
        variantReduction: number;
        annualCurrentSpend: number;
        annualProposedSpend: number;
        cashFlowReleaseResult: number;
        warrantyResult: number;
        rejectionCostResult: number;
        [key: string]: number;
    };
    analysisTitle: string;
    additionalInfo: string;
    originator: string;
    impactOnOthers: string;
    detailedAnalysisDesc: string;
    partsData: { currentPart: string; proposedPart: string }[];
    analysisType: string;
    partsListForValidate: Array<{
        partNumber: string;
        verticalHierarchies: Array<{
            platform: string;
            product: string;
            model: string;
            variant: string;
            volume: number;
        }>;
        isUserAdded: boolean;
    }>;
};

export type SensitivityInputs = {
    materialCost: number;
    annualVolumes: number;
    designInvestment: number;
    toolingInvestment: number;
};

type sensitivityInputSlider = {
    key: string;
    label: string;
    min: number;
    max: number;
    step: number;
    marks: Array<{ value: number; label: string }>;
};

export const sensitivityInputConfig: Array<sensitivityInputSlider> = [
    {
        key: 'materialCost',
        label: 'Material cost (Proposed)',
        min: -10,
        max: 10,
        step: 0.5,
        marks: [
            { value: -10, label: '-10%' },
            { value: 10, label: '10%' },
        ],
    },
    {
        key: 'annualVolumes',
        label: 'Annual volumes (Proposed)',
        min: -20,
        max: 20,
        step: 1,
        marks: [
            { value: -20, label: '-20%' },
            { value: 20, label: '20%' },
        ],
    },
    {
        key: 'designInvestment',
        label: 'Design investment',
        min: -30,
        max: 30,
        step: 1,
        marks: [
            { value: -30, label: '-30%' },
            { value: 30, label: '30%' },
        ],
    },
    {
        key: 'toolingInvestment',
        label: 'Tooling investment',
        min: -30,
        max: 30,
        step: 1,
        marks: [
            { value: -30, label: '-30%' },
            { value: 30, label: '30%' },
        ],
    },
];
