import styled, { css } from 'styled-components';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundDark};
    `,
);

export const Box = styled.div<{ size: number }>(
    ({ theme, size }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        color: ${size > 0 ? theme.colors.textDark : theme.colors.greyScaleDark};
        ${theme.typography.robotoRegular12}
        margin-bottom: 0.25rem;
    `,
);

export const FolderIcon = styled(FolderOpenIcon)`
    height: 64px;
    width: 64px;
`;
