import React, { useEffect, useState, useMemo, useContext } from 'react';
import { getModuleAnalyses, createNewAnalysis } from '../../utils/apiUtil';
import * as S from './Overview.style';
import { Analysis } from './Overview.types';
import ImageCard from '../../components/ImageCard/ImageCard';
import Select from '../../components/Select/Select';
import Button, { ButtonType } from '../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import AnalysesTable from '../../components/Table/Table';
import { aggregateAnalyses, getAnalysesTableData } from './Overview.utils';
import { analysisTableTitles, analysisBodyKeys } from './Overview.constants';
import { GlobalStoreContext } from '../../store/store.provider';
import Title from '../../components/Title/Title';
import PlaceHolderImage from '../../resources/AddImagePlaceholder.jpg';
import { useAuthContext } from '../../context/Auth';

function Overview(): JSX.Element {
    const { info, logout } = useAuthContext();

    const [analyses, setAnalyses] = useState<Analysis[]>([]);
    const [currentModule, setCurrentModule] = useState<number>(0);
    const { state: globalState } = useContext(GlobalStoreContext);

    const { modules } = globalState;
    const subModules = modules?.[currentModule]?.subModules ?? [];
    const history = useHistory();

    const handleModuleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setCurrentModule(event.target.value as number);
    };

    useEffect(() => {
        const fetchAnalyses = async (): Promise<void> => {
            const selectedModule = modules?.[currentModule]?.label ?? null;
            // make api call only if label exists
            if (selectedModule) {
                const { data } = await getModuleAnalyses(selectedModule, info, logout);
                const filteredAnalysisList = data?.filter(
                    (analysis: Record<string, string>) =>
                        analysis.status === 'IN_PROGRESS' || analysis.status === 'SUBMITTED',
                );
                setAnalyses(filteredAnalysisList);
            }
        };
        fetchAnalyses();
    }, [modules, currentModule, info, logout]);

    const nextPath = (path: string): void => {
        history.push(path, modules);
    };

    const goToNewAnalysis = async (): Promise<void> => {
        const selectedModule = modules?.[currentModule]?.label ?? null;
        const { data: analysisId } = await createNewAnalysis(selectedModule, info, logout);
        history.push('/newAnalysis/' + analysisId, { modules, analysisId });
    };

    const moduleDropdownOptions = useMemo(() => modules.map((data, index) => ({ value: index, label: data.label })), [
        modules,
    ]);

    const analysisAggregate = useMemo(() => aggregateAnalyses(analyses), [analyses]);
    const analysesTableData = useMemo(() => getAnalysesTableData(analyses, subModules), [analyses, subModules]);

    const goToQuickAnalysis = async (): Promise<void> => {
        history.push('/quickAnalysis/1', { isML: true });
    };

    return (
        <S.Container>
            <Title title="Overview" />

            <S.BodyContainer>
                <S.LeftPane>
                    <S.SelectHeader>MODULE</S.SelectHeader>
                    <Select
                        handleChange={handleModuleChange}
                        value={currentModule}
                        label="Module"
                        items={moduleDropdownOptions}
                    />
                    <S.ImageCardContainer>
                        <ImageCard imageSource={PlaceHolderImage} />
                    </S.ImageCardContainer>
                    <S.ModuleName>{modules?.[currentModule]?.label ?? null}</S.ModuleName>
                    <S.ModuleDescription>
                        Module Description
                        {'' /*modules?.[currentModule]?.description ?? null*/}
                    </S.ModuleDescription>

                    <S.ModuleStatContainer>
                        <S.ModuleStat>
                            <S.ModuleStatValue>
                                {analysisAggregate.submitted + analysisAggregate.inProgress}
                            </S.ModuleStatValue>
                            <S.ModuleStatLabel>Total Analyses</S.ModuleStatLabel>
                        </S.ModuleStat>
                        <S.ModuleStat>
                            <S.ModuleStatValue>{analysisAggregate.submitted}</S.ModuleStatValue>
                            <S.ModuleStatLabel>Submitted</S.ModuleStatLabel>
                        </S.ModuleStat>
                        <S.ModuleStat>
                            <S.ModuleStatValue>{analysisAggregate.inProgress}</S.ModuleStatValue>
                            <S.ModuleStatLabel>In progress</S.ModuleStatLabel>
                        </S.ModuleStat>
                    </S.ModuleStatContainer>
                </S.LeftPane>

                <S.TableCard>
                    <S.TableHeader>SUB-MODULE SUMMARY</S.TableHeader>
                    <AnalysesTable
                        headCells={analysisTableTitles}
                        data={analysesTableData}
                        bodyCells={analysisBodyKeys}
                        columnAlignment={['left', 'center', 'center']}
                        wrapText
                    />
                </S.TableCard>

                <S.ButtonCard>
                    <Button buttonType={ButtonType.PRIMARY} onClickHandler={(): Promise<void> => goToNewAnalysis()}>
                        New Analysis
                    </Button>
                    <Button buttonType={ButtonType.SECONDARY} onClickHandler={(): Promise<void> => goToQuickAnalysis()}>
                        Quick Analysis
                    </Button>
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        onClickHandler={(): void => nextPath('/analysisExplorer')}
                    >
                        Explore Analysis
                    </Button>
                </S.ButtonCard>
            </S.BodyContainer>
        </S.Container>
    );
}

export default Overview;
