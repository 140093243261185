import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import * as S from './RadioButton.style';

export interface SelectItem {
    value: number | string;
    label: string;
}

interface Props {
    items: SelectItem[];
    handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    value?: number | string;
    label: string;
}

export default function RadioButton({ items, handleChange, value, label }: Props): JSX.Element {
    return (
        <S.Container>
            <S.Label id="select-label">{label}</S.Label>
            <RadioGroup row name={label} value={value} onChange={handleChange}>
                {items.map((item) => (
                    <S.StyledFormControlLabel
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        control={<S.StyledRadio />}
                    />
                ))}
            </RadioGroup>
        </S.Container>
    );
}
