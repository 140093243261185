import { UsersResponse } from '../../constants/apiConfig';
import { upperFirst, toLower } from 'lodash';
import { Roles } from '../../constants/auth';

export const formatTableData = (usersData: Array<UsersResponse>) => {
    const userTypeMap = {
        CSP: 'Client Service Professional',
        C: 'Client',
        SU: 'Superuser',
    };
    return usersData?.map((data) => ({
        id: data.id,
        date: data.created_at.substring(0, 10),
        name: data.first_name + ' ' + data.last_name,
        emailId: data.email,
        contact: data.contact,
        designation: data.designation,
        department: data.department,
        userType: userTypeMap[data.type],
        ml: data.roles.find((role) => role === Roles.MODULE_LEAD) ? 'Yes' : '--',
        pl: data.roles.find((role) => role === Roles.PLATFORM_LEAD) ? 'Yes' : '--',
        dataAdmin: data.roles.find((role) => role === Roles.DATA_ADMIN) ? 'Yes' : '--',
        superUser: data.roles.find((role) => role === Roles.SUPER_USER) ? 'Yes' : '--',
        status: upperFirst(toLower(data.status)),
    }));
};
