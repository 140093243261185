import { AnalysisInputsObject, StateValidations, PartData } from './QuickAnalysis.types';

export const defaultAnalysisInputs: AnalysisInputsObject = {
    analysisTitle: '',
    originator: '',
    additionalInfo: '',
    toolingInvestment: 0,
    designInvestment: 0,
    otherInvestment: 0,
    detailedAnalysisDesc: '',
    impactOnOthers: '',
};

export const defaultStateErrors: StateValidations = {
    analysisTitle: '',
    originator: '',
    detailedAnalysisDesc: '',
    impactOnOthers: '',
    toolingInvestment: '',
    designInvestment: '',
    otherInvestment: '',
};

export const defaultPartsData = [
    {
        partNum: '',
        quantity: '',
        partCost: '',
        Volume: '',
        inventory: '',
        binCount: '',
        partsBin: '',
    } as PartData,
];
