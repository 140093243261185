export type VerticalHierarchyDetails = {
    platform1: string;
    platform2: string;
    platform3: string;
    platform4: string;
    platform5: string;
    platform6: string;
    [key: string]: string;
};

export const defaultVerticalHierarchyDetails = {
    platform1: '',
    platform2: '',
    platform3: '',
    platform4: '',
    platform5: '',
    platform6: '',
};

export const verticalHierarchyInfo = [
    {
        key: 'platform1',
        label: 'PLATFORM 01',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'platform2',
        label: 'PLATFORM 02',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'platform3',
        label: 'PLATFORM 04',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'platform4',
        label: 'PLATFORM 04',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'platform5',
        label: 'PLATFORM 05',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'platform6',
        label: 'PLATFORM 06',
        placeholder: '',
        type: 'text',
    },
];

export type HorizontalHierarchyDetails = {
    module1: string;
    module2: string;
    module3: string;
    module4: string;
    module5: string;
    module6: string;
    [key: string]: string;
};

export const defaultHorizontalHierarchyDetails = {
    module1: '',
    module2: '',
    module3: '',
    module4: '',
    module5: '',
    module6: '',
};

export const horizontalHierarchyInfo = [
    {
        key: 'module1',
        label: 'MODULE 01',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'module2',
        label: 'MODULE 02',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'module3',
        label: 'MODULE 04',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'module4',
        label: 'MODULE 04',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'module5',
        label: 'MODULE 05',
        placeholder: '',
        type: 'text',
    },
    {
        key: 'module6',
        label: 'MODULE 06',
        placeholder: '',
        type: 'text',
    },
];

export const userTableBodyCells = [
    'date',
    'name',
    'emailId',
    'contact',
    'designation',
    'department',
    'userType',
    'superUser',
    'ml',
    'pl',
    'dataAdmin',
    'status',
    'edit',
    'delete',
];

export const userTableHeadCells = [
    'Date Added',
    'Name',
    'Email ID',
    'Contact',
    'Designation',
    'Department',
    'User Type',
    'Super User',
    'ML',
    'PL',
    'Data Admin',
    'Status',
    '',
    '',
];

export const maxEntriesPerPage = 10;
