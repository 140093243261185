import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button, { ButtonType } from '../../../../components/Button/Button';
import * as S from './ConfirmationDialog.style';

interface Props {
    isOpen: boolean;
    analysisId: string;
    user: string;
    handleConfirm: () => void;
    handleCancel: () => void;
}

export default function ConfirmationDialog({
    isOpen,
    analysisId,
    user,
    handleConfirm,
    handleCancel,
}: Props): JSX.Element {
    return (
        <Modal isOpen={isOpen} title="Confirm Delete" handleClose={handleCancel}>
            {`The parts currently being analyzed have already been analyzed & submitted in analysis ${analysisId} created by ${user}. Are you sure you want to submit this analysis?`}
            <S.Container>
                <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleConfirm}>
                    Continue
                </Button>
                <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleCancel}>
                    Cancel
                </Button>
            </S.Container>
        </Modal>
    );
}
