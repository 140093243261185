import React, { ReactNode } from 'react';
import { AggregatedAnalysisData } from '../../AnalysisExplorer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Metric from '../../../../components/Metric/Metric';

import * as S from './AnalysisMetrics.style';

interface Props {
    data?: AggregatedAnalysisData;
    loading: boolean;
    error: boolean;
}

export default function AnalysisMetrics({ data, loading, error }: Props): JSX.Element {
    const renderMetrics = (): ReactNode => {
        if (loading) {
            return (
                <S.CenteredElement>
                    <CircularProgress />
                </S.CenteredElement>
            );
        }
        if (error) {
            return <S.CenteredElement>ERROR: UNABLE TO LOAD METRICS</S.CenteredElement>;
        }
        if (!data) {
            return <S.CenteredElement>SELECT AN ANALYSIS</S.CenteredElement>;
        }

        return (
            <>
                <S.ContainerDark>
                    <S.ColumnHeader>
                        <div>OVERALL</div> <div>* INR LAKH</div>
                    </S.ColumnHeader>
                    <Metric
                        value={Number(
                            data?.dmc_result +
                                data?.scale_benefit_result +
                                data?.inventory_cost +
                                data?.logistics_cost +
                                data?.rejection_cost +
                                data?.warranty_cost,
                        )}
                        label="TOTAL RECURRING IMPACT"
                        dark
                        showUnitMarker
                    />
                    <Metric
                        value={
                            data?.tooling_investment +
                            data?.design_investment +
                            data?.line_investment +
                            data?.other_investment
                        }
                        label="TOTAL INVESTMENT"
                        dark
                        showUnitMarker
                    />
                    <Metric value={Number(data?.variant_reduction_result) * 100} label="VARIANT REDUCTION (%)" dark />
                    <Metric value={Number(data?.sunk_cost)} label="SUNK COST" dark showUnitMarker />
                    <Metric
                        value={Number(data?.effiency_enhancement_result)}
                        label="EFFICIENCY ENHANCEMENT"
                        dark
                        showUnitMarker
                    />
                    <Metric
                        value={Number(data?.effiency_enhancement_hours_result)}
                        label="EFFICIENCY ENHANCEMENT (HOURS)"
                        dark
                    />
                    <S.MetricRow>
                        <Metric value={Number(data?.payback_period)} label="PAYBACK PERIOD (YEARS)" dark />
                        <Metric
                            value={Number(data?.cash_flow_release_result)}
                            label="CASH RELEASE"
                            dark
                            showUnitMarker
                        />
                    </S.MetricRow>
                    <S.MetricRow>
                        <Metric value={Number(data?.npv)} label="NPV" dark showUnitMarker />
                        <Metric value={Number(data?.irr)} label="IRR" dark />
                    </S.MetricRow>
                </S.ContainerDark>
                <S.ContainerLight>
                    <S.ColumnHeader dark>
                        <div>ONE TIME INVESTMENT</div> <div>* INR LAKH</div>
                    </S.ColumnHeader>
                    <Metric value={data?.design_investment} label="DESIGN INVESTMENTS" showUnitMarker />
                    <Metric value={data?.tooling_investment} label="TOOLING INVESTMENTS" showUnitMarker />
                    <Metric value={data?.line_investment} label="LINE INVESTMENTS" showUnitMarker />
                    <Metric value={data?.other_investment} label="OTHER INVESTMENTS" showUnitMarker />
                </S.ContainerLight>
            </>
        );
    };
    return <S.Container>{renderMetrics()}</S.Container>;
}
