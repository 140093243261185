import { GetAnalysisListByVerticalHierarchyResponse } from '../../constants/apiConfig';
import { AnalysisTableData } from './PLOverview.types';

const getEffectiveVariantReduction = (analyses: GetAnalysisListByVerticalHierarchyResponse, module: string): string => {
    const { uniqueCurrentParts, uniqueProposedParts } = analyses.reduce(
        (acc, curr) => {
            if (curr.hierarchy_5 === module) {
                acc.uniqueCurrentParts += Number(curr.unique_current_parts);
                acc.uniqueProposedParts += Number(curr.unique_proposed_parts);
            }
            return acc;
        },
        {
            uniqueCurrentParts: 0,
            uniqueProposedParts: 0,
        },
    );

    const rawVariantReduction = 1 - uniqueProposedParts / uniqueCurrentParts;
    const variantReduction = isNaN(rawVariantReduction) ? 0 : rawVariantReduction;
    return (variantReduction * 100).toFixed(2);
};

export const getAnalysesTableData = (
    analyses: GetAnalysisListByVerticalHierarchyResponse,
    modules: string[],
): AnalysisTableData[] => {
    return modules.map((module) => {
        return {
            hierarchy5: module,
            analyses: analyses.filter((analysis) => analysis.hierarchy_5 === module).length,
            variantReduction: getEffectiveVariantReduction(analyses, module),
        };
    });
};
