import React, { useState, useMemo, useEffect } from 'react';
import { GetAnalysisListByVerticalHierarchyResponse as getAnalysisListResponse } from '../../../../constants/apiConfig';
import TextInput from '../../../../components/TextInput/TextInput';

import Expand from '@material-ui/icons/Add';
import Collapse from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';

import * as S from './SelectModules.style';

type ModuleData = Array<{
    label: string;
    subModules: Array<{
        label: string;
        analyses: Array<{
            id: string;
            title: string;
        }>;
    }>;
}>;

interface Props {
    analysisList: getAnalysisListResponse | undefined;
    setSelectedModules: React.Dispatch<React.SetStateAction<Array<string>>>;
    selectedModules: Array<string>;
}

export default function SelectModules({ analysisList, setSelectedModules, selectedModules }: Props): JSX.Element {
    const [searchText, setSearchText] = useState('');
    const [expandedModules, setExpandedModules] = useState<Array<string>>([]);
    const [expandedSubModules, setExpandedSubModules] = useState<Array<string>>([]);

    const handleModuleSelection = (module: string): void => {
        if (selectedModules.includes(module)) {
            setSelectedModules(selectedModules.filter((value) => value !== module));
        } else {
            setSelectedModules([...selectedModules, module]);
        }
    };

    const toggleModuleExpansion = (module: string): void => {
        if (expandedModules.indexOf(module) === -1) {
            setExpandedModules([...expandedModules, module]);
        } else {
            setExpandedModules(expandedModules.filter((item) => item !== module));
        }
    };

    const toggleSubModuleExpansion = (subModule: string): void => {
        if (expandedSubModules.indexOf(subModule) === -1) {
            setExpandedSubModules([...expandedSubModules, subModule]);
        } else {
            setExpandedSubModules(expandedSubModules.filter((item) => item !== subModule));
        }
    };

    const filteredData = useMemo(
        () => analysisList?.filter((analysis) => `ANALYSIS ${analysis.analysis_id}`.includes(searchText)),
        [analysisList, searchText],
    );

    useEffect(() => {
        setExpandedModules([]);
        setExpandedSubModules([]);
    }, [analysisList]);

    const moduleData = useMemo(() => {
        return (
            filteredData?.reduce((accumulator, currentValue) => {
                const moduleIndex = accumulator.findIndex((module) => module.label === currentValue.hierarchy_5);
                if (moduleIndex === -1) {
                    const subModules = [
                        {
                            label: currentValue.hierarchy_6,
                            analyses: [{ id: `ANALYSIS ${currentValue.analysis_id}`, title: currentValue.title }],
                        },
                    ];
                    return [...accumulator, { label: currentValue.hierarchy_5, subModules }];
                } else {
                    const subModuleIndex = accumulator[moduleIndex].subModules.findIndex(
                        (subModule) => subModule.label === currentValue.hierarchy_6,
                    );
                    if (subModuleIndex === -1) {
                        const subModules = [
                            ...accumulator[moduleIndex].subModules,
                            {
                                label: currentValue.hierarchy_6,
                                analyses: [{ id: `ANALYSIS ${currentValue.analysis_id}`, title: currentValue.title }],
                            },
                        ];
                        const newData = [...accumulator];
                        newData[moduleIndex] = { ...newData[moduleIndex], subModules };
                        return newData;
                    } else {
                        const subModules = [...accumulator[moduleIndex].subModules];
                        subModules[subModuleIndex] = {
                            ...subModules[subModuleIndex],
                            analyses: [
                                ...subModules[subModuleIndex].analyses,
                                { id: `ANALYSIS ${currentValue.analysis_id}`, title: currentValue.title },
                            ],
                        };
                        const newData = [...accumulator];
                        newData[moduleIndex] = { ...newData[moduleIndex], subModules };
                        return newData;
                    }
                }
            }, [] as ModuleData) ?? []
        );
    }, [filteredData]);

    return (
        <S.Container>
            <S.RequiredField> SELECT MODULES </S.RequiredField>
            <TextInput
                value={searchText}
                placeholder="Search Analysis"
                handleChange={(event): void => setSearchText(event.target.value)}
            />
            {moduleData.map((module) => (
                <>
                    <S.CheckListItem key={module.label}>
                        <S.IconButton onClick={(): void => toggleModuleExpansion(module.label)}>
                            {expandedModules.includes(module.label) ? <Collapse /> : <Expand />}
                        </S.IconButton>
                        <span onClick={(): void => handleModuleSelection(module.label)}>
                            <S.StyledCheckbox
                                checked={selectedModules.includes(module.label)}
                                checkedIcon={<S.CheckedIcon />}
                            />
                            {module.label}
                        </span>
                    </S.CheckListItem>
                    {expandedModules.includes(module.label) && (
                        <S.SubListContainer>
                            {module.subModules.map((subModule) => (
                                <>
                                    <S.CheckListItem key={subModule.label}>
                                        <S.IconButton onClick={(): void => toggleSubModuleExpansion(subModule.label)}>
                                            {expandedSubModules.includes(subModule.label) ? <Collapse /> : <Expand />}
                                        </S.IconButton>
                                        {subModule.label}
                                    </S.CheckListItem>
                                    {expandedSubModules.includes(subModule.label) && (
                                        <S.SubListContainer>
                                            {subModule.analyses.map((analysis) => (
                                                <Tooltip key={analysis.id} title={analysis.title} placement="left">
                                                    <S.CheckListItem>{analysis.id}</S.CheckListItem>
                                                </Tooltip>
                                            ))}
                                        </S.SubListContainer>
                                    )}
                                </>
                            ))}
                        </S.SubListContainer>
                    )}
                </>
            ))}
        </S.Container>
    );
}
