import React from 'react';
import { GroupedMetricContainer, Metrics, Divider } from './GroupedMetric.style';
import Metric from '../Metric/Metric';

interface Props {
    title?: string;
    values: { value: string | number; label: string; distinctColor?: boolean }[];
}

export default function GroupedMetric({ title, values }: Props): JSX.Element {
    return (
        <GroupedMetricContainer>
            {title}
            <Divider />
            <Metrics>
                {values.map((metric) => {
                    return (
                        <Metric
                            key={metric.label}
                            value={metric.value}
                            label={metric.label}
                            distinctColor={!!metric.distinctColor}
                        />
                    );
                })}
            </Metrics>
        </GroupedMetricContainer>
    );
}
