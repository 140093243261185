import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import TextInput from '../TextInput/TextInput';
import { FirstLevelListItem, SecondLevelListItem, SecondLevelListMap, Item } from './DoublyNestedCheckList.types';
import FirstLevelList from './components/FirstLevelList/FirstLevelList';

import * as S from './DoublyNestedCheckList.style';

export type ListItem = FirstLevelListItem;
interface Props {
    data: Array<ListItem>;
    selectedValues: Array<Item>;
    setSelectedValues: Dispatch<SetStateAction<Array<Item>>>;
}

export default function DoublyNestedCheckList({ data, selectedValues, setSelectedValues }: Props): JSX.Element {
    const [searchText, setSearchText] = useState('');
    const [selectedFirstLevelListItem, setSelectedFirstLevelListItem] = useState<Array<string>>([]);
    const [selectedSecondLevelListItem, setSelectedSecondLevelListItem] = useState<SecondLevelListMap>({});

    useEffect(() => {
        const secondLevelDefaultItem: { [key: string]: Array<string> } = {};
        data.forEach((firstLevelListItem) => {
            secondLevelDefaultItem[firstLevelListItem.label] = [];
        });
        setSelectedSecondLevelListItem(secondLevelDefaultItem);
        // eslint-disable-next-line
    }, []);

    const filteredData = data.reduce((accumulator, currentValue): any => {
        const filteredSecondLevelItems = currentValue.subItems.reduce(
            (secondLevelAccumulator, secondLevelItem): any => {
                const filteredSubItems = secondLevelItem.subItems.filter((subItem) =>
                    ('Analysis ' + subItem.label).toLowerCase().includes(searchText.toLowerCase()),
                );
                if (filteredSubItems.length > 0) {
                    return [...secondLevelAccumulator, { ...secondLevelItem, subItems: filteredSubItems }];
                } else {
                    return secondLevelAccumulator;
                }
            },
            [] as Array<SecondLevelListItem>,
        );

        if (!!filteredSecondLevelItems && filteredSecondLevelItems.length > 0) {
            return [...accumulator, { ...currentValue, subItems: filteredSecondLevelItems }];
        } else {
            return accumulator;
        }
    }, [] as FirstLevelListItem[]);

    return (
        <S.Container>
            <TextInput
                value={searchText}
                placeholder="Search Analyses"
                dark
                handleChange={(event): void => setSearchText(event.target.value)}
            />
            {filteredData.map((firstLevelListItem) => (
                <FirstLevelList
                    key={firstLevelListItem.label}
                    firstLevelListItem={firstLevelListItem}
                    selectedValues={selectedValues}
                    selectedFirstLevelListItem={selectedFirstLevelListItem}
                    setSelectedFirstLevelListItem={setSelectedFirstLevelListItem}
                    selectedSecondLevelListItem={selectedSecondLevelListItem}
                    setSelectedSecondLevelListItem={setSelectedSecondLevelListItem}
                    setSelectedValues={setSelectedValues}
                    firstLevelLabels={data.map((item) => {
                        return item.label;
                    })}
                />
            ))}
        </S.Container>
    );
}
