import styled, { css } from 'styled-components';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        flex: 4;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundLight};
        ${theme.typography.robotoRegular12}
    `,
);

export const ClientDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 1rem;
    > div {
        flex-basis: 33.33%;
        padding: 1rem 0.5rem;
    }
`;

export const HeaderContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        padding: 1.5rem;
        border-radius: 10px 10px 0 0;
        justify-content: space-between;
        background-color: ${theme.colors.backgroundDark};
    `,
);

export const NameAndAddressContainer = styled.div`
    flex-basis: 33.33%;
    > div:last-child {
        margin-top: 0.5rem;
    }
`;

export const CheckListItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 1rem;
    cursor: pointer;
`;

export const CheckedIcon = styled(CheckBoxIcon)(
    ({ theme }) => css`
        color: ${theme.colors.accentPrimary};
    `,
);

export const DisabledCheckedIcon = styled(CheckBoxIcon)(
    ({ theme }) => css`
        color: ${theme.colors.greyScaleDark};
    `,
);

export const ContactDetailsContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        border-radius: 10px;
        margin-top: 2rem;
        background-color: ${theme.colors.greyScaleLight};
        margin: 0.5rem 0.5rem 0 0.5rem;
        > div {
            padding: 1rem 0.75rem;
            flex-basis: 33.33%;
        }
        > h5 {
            padding: 1rem 0.75rem 0 0.75rem;
            flex-basis: 100%;
        }
    `,
);

export const ContactDetailsContainerModal = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    margin: -10px;
    > div {
        padding: 1rem 0.75rem;
        flex-basis: 33.33%;
    }
    > h5 {
        padding: 1rem 0.75rem 0 0.75rem;
        flex-basis: 100%;
    }
`;

export const SectionTitle = styled.h5(
    ({ theme }) => css`
        ${theme.typography.robotoMedium14}
    `,
);

export const ContactTitle = styled.h5(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12}
    `,
);

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
    > div {
        margin-left: 1rem;
        width: 120px;
    }
    > div:first-child {
        margin-right: auto;
        margin-left: 0;
    }
`;

export const Asterisk = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.accentSecondary};
    `,
);

export const UploadContainer = styled.div(
    ({ theme }) => css`
        width: 80px;
        height: 80px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: ${theme.colors.greyScaleLight};
        color: ${theme.colors.accentPrimary};
    `,
);

export const Label = styled.label`
    cursor: pointer;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Img = styled.img`
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    object-fit: contain;
`;
