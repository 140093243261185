import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 1.5rem 1rem;
        overflow: auto;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const BodyContainer = styled.div`
    display: flex;
    height: 100%;
    margin-top: 1.5rem;
    > div {
        margin-right: 1rem;
    }
    > div:last-child {
        margin-right: 0;
    }
`;

export const LeftPane = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 2;
        border-radius: 10px;
        padding: 1.5rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundDark};

        > div {
            margin-bottom: 1rem;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    `,
);

export const SelectHeader = styled.span(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.greyScaleLight};
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    `,
);

export const ImageCardContainer = styled.div(
    () => css`
        height: 160px;
        width: 100%;
        border-radius: 4px;
        background-color: #dfdfdf;
    `,
);

export const ModuleName = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.textDark};
        ${theme.typography.robotoMedium16};
    `,
);

export const ModuleDescription = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.subtextDark};
        ${theme.typography.robotoRegular16};
        margin-top: 1.5rem;
        border-bottom: 1px solid ${theme.colors.greyScaleMedium};
        height: 5rem;
    `,
);

export const ModuleStatValue = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoLight24};
        color: ${theme.colors.textDark};
    `,
);

export const ModuleStatLabel = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14}
        color: ${theme.colors.textDark};
        margin-left: 1rem;
    `,
);

export const ModuleStatContainer = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        > div {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
        }
    `,
);

export const ButtonCard = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        flex: 2;
        border-radius: 10px;
        padding: 1.5rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundLight};

        > div {
            margin-bottom: 1rem;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    `,
);

export const TableCard = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex: 5;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundLight};
    `,
);

export const TableHeader = styled.h2(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12}
        padding: 1rem 1.5rem;
    `,
);

export const ModuleStat = styled.div`
    display: flex;
    justify-content: flex-start;
    > div:first-child {
        display: flex;
        width: 20%;
        justify-content: flex-end;
    }
`;
