import { RouteConfig } from '../hooks/useFetch';
import { Platform } from '../types/hierarchies.types';
import { Part } from '../pages/NewAnalysis/NewAnalysis.types';

export const getAnalysisListForModuleConfig: RouteConfig = {
    path: '/analyses',
    method: 'GET',
};

export type GetAnalysisListForModuleResponse = Array<{
    hierarchy_6: string;
    status: 'IN_PROGRESS' | 'SUBMITTED' | 'DEFAULT';
    title: string;
    id: number;
}>;

export const deleteAnalysisConfig: RouteConfig = {
    path: '/analyses/:id',
    method: 'DELETE',
};

export type DeleteAnalysisResponse = string;

export const getAnalysisByIdConfig: RouteConfig = {
    path: '/analyses/:id',
    method: 'GET',
};

export const uploadDataTypes = [
    { value: null, label: 'Select a type' },
    { value: 'BOM_DATA', label: 'BOM Data' },
    { value: 'SPEND_DATA', label: 'Spend Data' },
    { value: 'PORTFOLIO_DATA', label: 'Portfolio Data' },
    { value: 'INVENTORY_DATA', label: 'Inventory Data' },
    { value: 'LOCATION_DATA', label: 'Location Data' },
    { value: 'CATEGORY_DISTRIBUTION_DATA', label: 'Category Distribution Data' },
    { value: 'REWORK_REJECTION_DATA', label: 'Rework Rejection Logistics Data' },
    { value: 'NPV_IRR_DATA', label: 'NPV IRR Payback Period Data' },
];

export const superuserUploadDataTypes = [
    { value: 'SCALE_BENEFIT_DATA', label: 'Scale Benefit Data' },
    { value: 'BENCHMARK_DATA', label: 'Benchmark Data' },
    { value: 'BENCHMARK_QUICK_DATA', label: 'Benchmark Quick Analysis Data' },
];

export type GetAnalysisByIdResponsePartsList = {
    id: number;
    analysis_id: number;
    currentPart: Part;
    proposedPart: Part;
};

export type GetAnalysisByIdResponse = {
    id: string;
    hierarchy_5: string;
    hierarchy_6: string;
    status: string;
    title: string;
    originator: string;
    additional_info: string;
    detailed_description: string;
    impact_on_other_modules: string;
    additional_tooling: number;
    soft_tooling: number;
    depreciation_period: number;
    design_certification: number;
    design_hours: number;
    existing_tool_cost: number;
    operating_mode: string;
    line_investment_internal: number;
    line_investment_supplier: number;
    prototyping_cost: number;
    residual_penalty: number;
    supplier_rnd: number;
    tool_lifecycle: number;
    consultation_cost: number;
    other_investment: number;
    dmc_result: number;
    scale_benefit_result: number;
    variant_reduction_result: number;
    partsList: Array<GetAnalysisByIdResponsePartsList>;
    unique_current_parts: number;
    unique_proposed_parts: number;
    residual_tooling_result: number;
    cash_flow_release_result: number;
    working_capital_result: number;
    part_obsolescence_result: number;
    space_utilization_result: number;
    warranty_result: number;
    lr_material_result: number;
    inbound_logistics_result: number;
    intra_plant_logistics_result: number;
    handling_manpower_result: number;
    lr_rework_manpower_result: number;
    sc_efficiency_design_result: number;
    sc_efficiency_documentation_result: number;
    sc_efficiency_inspection_result: number;
    sc_efficiency_line_result: number;
    sc_efficiency_purchase_result: number;
    sc_efficiency_quality_result: number;
    handling_manhours_result: number;
    lr_rework_manhours_result: number;
    sc_efficiency_design_manhours_result: number;
    sc_efficiency_documentation_manhours_result: number;
    sc_efficiency_inspection_manhours_result: number;
    sc_efficiency_line_manhours_result: number;
    sc_efficiency_purchase_manhours_result: number;
    sc_efficiency_quality_manhours_result: number;
    tooling_investment_result: number;
    design_investment_result: number;
    line_investment_result: number;
    other_investment_result: number;
    npv: number;
    net_savings_arr: number[] | number[][];
};

export const getHierarchyDataConfig: RouteConfig = {
    path: '/hierarchies',
    method: 'GET',
};

export const getHierarchyPLDataConfig: RouteConfig = {
    path: '/hierarchies/pl',
    method: 'GET',
};

export type GetHierarchyDataResponse = {
    platforms: Array<Platform>;
};

export const getCustomPartsListConfig: RouteConfig = {
    path: '/user-parts',
    method: 'GET',
};

export type CustomParts = {
    id: number;
    part_number: string;
    hierarchy_1: string;
    hierarchy_2: string;
    hierarchy_3: string;
    hierarchy_4: string;
    hierarchy_5: string;
    hierarchy_6: string;
    operating_mode: string;
    volume: number;
    quantity: number;
    description: string;
    parts_cost: number;
    avg_inventory_days: number;
    units_per_bin: number;
    obsolescence: number;
    handling_time_per_unit: number;
    first_pass_rate: string;
    rework_time_per_unit: number;
    scrap_rate: string;
    inbound_logistics: number;
    intra_plant_logistics: number;
    warranty_instances: number;
    cost_per_unit: number;
    originator: string;
};

export type GetCustomPartsListResponse = Array<CustomParts>;

export const postCustomPartsListConfig: RouteConfig = {
    path: '/user-parts',
    method: 'POST',
};

export type PostCustomPartsListResponse = {
    id: number;
};

export const quickAnalysisExportConfig: RouteConfig = {
    path: '/export/module/quickAnalysis',
    method: 'POST',
    responseType: 'arraybuffer',
};

export const newAnalysisExportConfig: RouteConfig = {
    path: '/export/module/analysis',
    method: 'POST',
    responseType: 'arraybuffer',
};

export const newProjectExportConfig: RouteConfig = {
    path: '/export/projects',
    method: 'POST',
    responseType: 'arraybuffer',
};

export const getAnalysisListByVerticalHierarchyConfig: RouteConfig = {
    path: '/analyses',
    method: 'GET',
};

export type GetAnalysisListByVerticalHierarchyResponse = Array<
    {
        hierarchy_6: string;
        hierarchy_5: string;
        status: 'IN_PROGRESS' | 'SUBMITTED' | 'DEFAULT';
        title: string;
        analysis_id: number;
        unique_current_parts: number;
        unique_proposed_parts: number;
    } & GetAnalysisByIdResponse
>;

export const getProjectsForPlatformConfig: RouteConfig = {
    path: '/projects',
    method: 'GET',
};

export type GetProjectsForPlatformResponse = Array<{
    product: string;
    projects: Array<{
        id: number;
        models_count: number;
        title: string;
        product: string;
        status: string;
        modules_count: number;
    }>;
}>;

export const getProjectByIdConfig: RouteConfig = {
    path: '/projects/:id',
    method: 'GET',
};

export type GetProjectByIdResponse = {
    id: number;
    hierarchy_1: string;
    hierarchy_2: string;
    hierarchy_3: string[];
    hierarchy_4: string[] | null;
    status: string;
    title: string;
    originator: string;
    description: string;
    dmc_result: number;
    scale_benefit_result: number;
    variant_reduction_result: number;
    working_capital_result: number;
    cash_flow_release_result: number;
    part_obsolescence_result: number;
    space_utilization_result: number;
    tooling_investment_result: number;
    residual_tooling_result: number;
    design_investment_result: number;
    line_investment_result: number;
    other_investment_result: number;
    warranty_result: number;
    lr_material_result: number;
    lr_rework_manpower_result: number;
    inbound_logistics_result: number;
    intra_plant_logistics_result: number;
    handling_manpower_result: number;
    supplier_line_investments: number;
    supplier_internal_investments: number;
    other_investments: number;
    consultation_costs: number;
    design_manhours: number;
    prototyping_costs: number;
    supplier_rd_costs: number;
    certification_tooling_costs: number;
    integration_design_investments: number;
    integration_line_investments: number;
    total_other_investments: number;
    total_line_investments: number;
    total_design_investments: number;
    total_tooling_investments: number;
    created_at: string;
    updated_at: string;
    npv: number;
    irr: number;
    paybackPeriod: number;
    net_savings_arr: number[];
    projectAnalyses: Array<{
        id: string;
        hierarchy_5: string;
        project_id: number;
        analysis_id: number;
        created_at: string;
        updated_at: string;
    }>;
};

export const createNewProjectConfig: RouteConfig = {
    path: '/projects',
    method: 'POST',
};

export const patchProjectConfig: RouteConfig = {
    path: '/projects/:id',
    method: 'PATCH',
};

export const runProjectConfig: RouteConfig = {
    path: '/projects/:id/run',
    method: 'POST',
};

export const patchAnalysisConfig: RouteConfig = {
    path: '/analyses/:id',
    method: 'PATCH',
};

export const deleteProjectConfig: RouteConfig = {
    path: '/projects/:id',
    method: 'DELETE',
};

export type DeleteProjectResponse = string;
export const validateAnalysisConfig: RouteConfig = {
    path: '/analyses/:id/validate',
    method: 'POST',
};

export type ValidateAnalysisResponse = {
    duplicateAnalysisPartDetails: Array<{
        analysisId: number;
        platform: string;
        product: string;
        model: string;
        variant: string;
        partNumber: string;
        originator: string;
    }>;
    isValid: boolean;
};

export const uploadExcelConfig: RouteConfig = {
    path: '/uploads',
    method: 'POST',
};

export type UploadExcelResponse = string;

export const getAllUsersConfig: RouteConfig = {
    path: '/users',
    method: 'GET',
};

export type UsersResponse = {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    contact: string;
    designation: string;
    department: string;
    valid_from: string;
    valid_until: string;
    status: 'NEW' | 'ACTIVE' | 'DISABLED' | 'EXPIRED';
    charge_code: string;
    type: 'CSP' | 'C' | 'SU';
    roles: Array<string>;
    modules: Array<string>;
    platforms: Array<string>;
    created_at: string;
};

export type GetAllUsersResponse = {
    page_total: number;
    total: number;
    results: Array<UsersResponse>;
};

export const updateUserConfig: RouteConfig = {
    path: '/users/:emailId',
    method: 'PATCH',
};

export type UpdateUserResponse = string;

export const deleteUserConfig: RouteConfig = {
    path: '/users/:emailId',
    method: 'DELETE',
};

export const deleteDataConfig: RouteConfig = {
    path: '/clientData',
    method: 'DELETE',
};

export type DeleteUserResponse = string;

export const usersExportConfig: RouteConfig = {
    path: '/export/users',
    method: 'GET',
    responseType: 'arraybuffer',
};

export const usersTemplateConfig: RouteConfig = {
    path: '/uploads/templates/USER_DATA',
    method: 'GET',
    responseType: 'arraybuffer',
};

export const getClientDetailsConfig: RouteConfig = {
    path: '/clients',
    method: 'GET',
};

export type GetClientDetailsResponse = {
    client: {
        name: string;
        logo: string;
        address: string;
        location: string;
        region: string;
        currency: string;
        industry: string;
        sub_industry: string;
        employees: string;
        charge_code: string;
        enable_industry: boolean;
        first_contact_name: string;
        first_contact_phone_number: string;
        first_contact_email: string;
        second_contact_name: string;
        second_contact_phone_number: string;
        second_contact_email: string;
        ed_name: string;
        ed_phone_number: string;
        ed_email: string;
        em_name: string;
        em_phone_number: string;
        em_email: string;
        adm_name: string;
        adm_phone_number: string;
        adm_email: string;
        core_contact_name: string;
        core_contact_phone_number: string;
        core_contact_email: string;
        other_contact_name: string;
        other_contact_phone_number: string;
        other_contact_email: string;
        onboarding_status: 'DEFAULT' | 'SAVED' | 'SUBMITTED';
    };
};

export const putClientDetailsConfig: RouteConfig = {
    path: '/clients',
    method: 'PUT',
};
