import React, { Dispatch, SetStateAction } from 'react';
import {
    SubListItem,
    FirstLevelListItem,
    SecondLevelListItem,
    SecondLevelListMap,
    Item,
} from '../../DoublyNestedCheckList.types';
import * as S from './ListItem.style';
import { Tooltip } from '@material-ui/core';

export interface Props {
    firstLevelListItem: FirstLevelListItem;
    secondLevelListItem: SecondLevelListItem;
    selectedValues: Array<Item>;
    selectedFirstLevelListItem: Array<string>;
    setSelectedFirstLevelListItem: Dispatch<SetStateAction<Array<string>>>;
    selectedSecondLevelListItem: SecondLevelListMap;
    setSelectedSecondLevelListItem: Dispatch<SetStateAction<SecondLevelListMap>>;
    setSelectedValues: Dispatch<SetStateAction<Array<Item>>>;
}

export default function ListItem({
    firstLevelListItem,
    secondLevelListItem,
    selectedValues,
    selectedFirstLevelListItem,
    setSelectedFirstLevelListItem,
    selectedSecondLevelListItem,
    setSelectedSecondLevelListItem,
    setSelectedValues,
}: Props): JSX.Element {
    const countItemsInFirstLevelList = (firstLevelListItem: FirstLevelListItem): number => {
        return firstLevelListItem.subItems.reduce((acc, item) => {
            return acc + item.subItems.length;
        }, 0);
    };

    const handleSubListItemClick = (
        subListItem: SubListItem,
        firstLevelListItem: FirstLevelListItem,
        secondLevelListItem: SecondLevelListItem,
    ): void => {
        // if item is not checked
        if (
            !selectedValues.find(
                (item) =>
                    item.firstLevelListItem === firstLevelListItem.label &&
                    item.secondLevelListItem === secondLevelListItem.label &&
                    item.label === subListItem.label,
            )
        ) {
            // if all but one item is checked then check the last item and set parent item to checked
            if (
                selectedValues.filter(
                    (item) =>
                        item.firstLevelListItem === firstLevelListItem.label &&
                        item.secondLevelListItem === secondLevelListItem.label,
                ).length ===
                secondLevelListItem.subItems.length - 1
            ) {
                // if parent is not already checked then add to selected list
                if (
                    !selectedSecondLevelListItem[firstLevelListItem.label].find(
                        (item) => item === secondLevelListItem.label,
                    )
                ) {
                    setSelectedSecondLevelListItem((prevList) => ({
                        ...prevList,
                        [firstLevelListItem.label]: [
                            ...selectedSecondLevelListItem[firstLevelListItem.label],
                            secondLevelListItem.label,
                        ],
                    }));
                }
            }
            // add to selected items
            setSelectedValues([
                ...selectedValues,
                {
                    firstLevelListItem: firstLevelListItem.label,
                    secondLevelListItem: secondLevelListItem.label,
                    label: subListItem.label,
                },
            ]);

            // if all items are selected then add first level item to selected
            if (
                selectedValues.filter((item) => item.firstLevelListItem === firstLevelListItem.label).length ===
                countItemsInFirstLevelList(firstLevelListItem) - 1
            ) {
                if (!selectedFirstLevelListItem.includes(firstLevelListItem.label)) {
                    setSelectedFirstLevelListItem([...selectedFirstLevelListItem, firstLevelListItem.label]);
                }
            }
        } else {
            // remove second level item from selected
            setSelectedSecondLevelListItem((prevList) => ({
                ...prevList,
                [firstLevelListItem.label]: selectedSecondLevelListItem[firstLevelListItem.label].filter(
                    (item) => item !== secondLevelListItem.label,
                ),
            }));
            // if no items are checked then remove first level item from selected list
            if (selectedValues.filter((item) => item.firstLevelListItem === firstLevelListItem.label).length === 1) {
                setSelectedFirstLevelListItem(
                    selectedFirstLevelListItem.filter((item) => item !== firstLevelListItem.label),
                );
            }
            // uncheck this item
            setSelectedValues(
                selectedValues.filter(
                    (item) =>
                        item.firstLevelListItem !== firstLevelListItem.label ||
                        item.secondLevelListItem !== secondLevelListItem.label ||
                        item.label !== subListItem.label,
                ),
            );
        }
    };
    return (
        <S.SubListContainer>
            {secondLevelListItem.subItems.map((subItem) => (
                <S.ListItem
                    key={subItem.label}
                    onClick={(): void => handleSubListItemClick(subItem, firstLevelListItem, secondLevelListItem)}
                >
                    <S.StyledCheckbox
                        checkedIcon={<S.CheckedIcon />}
                        checked={
                            !!selectedValues.find(
                                (item) =>
                                    item.secondLevelListItem === secondLevelListItem.label &&
                                    item.firstLevelListItem === firstLevelListItem.label &&
                                    item.label === subItem.label,
                            )
                        }
                    />
                    <Tooltip title={subItem.hover || ''} placement="right">
                        <div>ANALYSIS {subItem.label}</div>
                    </Tooltip>
                </S.ListItem>
            ))}
        </S.SubListContainer>
    );
}
