import React, { ReactNode } from 'react';
import { AggregatedAnalysisData } from '../../AnalysisExplorer';
import CircularProgress from '@material-ui/core/CircularProgress';
import WaterfallChart from '../../../../components/WaterfallChart/WaterfallChart';

import * as S from './AnalysisChart.style';
import { Tooltip } from '@material-ui/core';

interface Props {
    data?: AggregatedAnalysisData;
    loading: boolean;
    error: boolean;
    isPL?: boolean;
}

export default function AnalysisChart({ data, loading, error, isPL = false }: Props): JSX.Element {
    const chartData = {
        data: [
            {
                key: 'MATERIAL<br />COST',
                value: data?.dmc_result ?? 0,
                label: data?.dmc_result.toFixed(2) ?? '',
            },
            {
                key: 'SCALE<br />BENEFIT',
                value: data?.scale_benefit_result ?? 0,
                label: data?.scale_benefit_result?.toFixed(2) ?? '',
            },
            {
                key: 'INVENTORY',
                value: data?.inventory_cost ?? 0,
                label: data?.inventory_cost?.toFixed(2) ?? '',
            },
            {
                key: 'WARRANTY',
                value: data?.warranty_cost ?? 0,
                label: data?.warranty_cost?.toFixed(2) ?? '',
            },
            {
                key: 'REJECTION',
                value: data?.rejection_cost ?? 0,
                label: data?.rejection_cost?.toFixed(2) ?? '',
            },
            {
                key: 'LOGISTICS',
                value: data?.logistics_cost ?? 0,
                label: data?.logistics_cost?.toFixed(2) ?? '',
            },
        ],
    };

    const renderChart = (): ReactNode => {
        if (loading) {
            return <CircularProgress />;
        }
        if (error) {
            return 'ERROR: UNABLE TO LOAD CHART DATA';
        }
        if (!data) {
            return isPL ? 'SELECT A PROJECT' : 'SELECT AN ANALYSIS';
        }

        return (
            <>
                <Tooltip title="Gross steady state impact">
                    <S.ChartTitle>OVERALL COST OF COMPLEXITY (INR LAKH)</S.ChartTitle>
                </Tooltip>
                <WaterfallChart chartDetails={chartData} />
            </>
        );
    };
    return (
        <S.Container>
            <S.AnalysisChartContainer>{renderChart()}</S.AnalysisChartContainer>
        </S.Container>
    );
}
