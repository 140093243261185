import { Locationstate, SensitivityInputs } from './AnalysisSummary.config';
import {
    calcEfficiencyEnchancement,
    calcEfficiencyEnchancementHours,
    calcInventory,
    calcLogistics,
} from '../../utils/analysesUtils';

export const formatAnalysisData = (analysisData: Locationstate, sensitivityInputs: SensitivityInputs) => {
    const { analysisResults } = analysisData;
    return {
        tooling_investment: analysisData.toolingInvestment * (1 + sensitivityInputs.toolingInvestment / 100),
        design_investment: analysisData.designInvestment * (1 + sensitivityInputs.designInvestment / 100) ?? 0,
        line_investment: Number(analysisData.lineInvestment) ?? 0,
        other_investment: Number(analysisData.otherInvestment) ?? 0,
        variant_reduction_result: Number(analysisResults.variantReduction) ?? 0,
        dmc_result:
            analysisResults.annualCurrentSpend -
                analysisResults.annualProposedSpend *
                    (1 + sensitivityInputs.materialCost / 100) *
                    (1 + sensitivityInputs.annualVolumes / 100) ?? 0,
        scale_benefit_result: Number(analysisResults.scaleBenefitResult) ?? 0,
        sunk_cost: Number(analysisData.residualToolingInvestment) ?? 0,
        cash_flow_release_result: Number(analysisResults.cashFlowReleaseResult) ?? 0,
        effiency_enhancement_result: calcEfficiencyEnchancement(analysisResults),
        effiency_enhancement_hours_result: calcEfficiencyEnchancementHours(analysisResults),
        inventory_cost: calcInventory(analysisResults) || 0,
        warranty_cost: Number(analysisResults.warrantyResult) ?? 0 ?? 0,
        rejection_cost: Number(analysisResults.rejectionCostResult) ?? 0,
        logistics_cost: calcLogistics(analysisResults) || 0,
        payback_period: analysisResults?.paybackPeriod,
        npv: analysisResults?.npv,
        irr: analysisResults?.irr,
    };
};

export const formatForExport = (analysisData: Locationstate, sensitivityInputs: SensitivityInputs) => {
    const { analysisResults } = analysisData;
    const logisticsResult = calcLogistics(analysisResults);
    const inventoryResult = calcInventory(analysisResults);
    const sensitivityDMC =
        analysisResults.annualCurrentSpend -
            analysisResults.annualProposedSpend *
                (1 + sensitivityInputs.materialCost / 100) *
                (1 + sensitivityInputs.annualVolumes / 100) ?? 0;
    const totalRecurringImpact = Number(
        sensitivityDMC +
            analysisResults.scaleBenefitResult +
            inventoryResult +
            analysisResults.warrantyResult +
            analysisResults.rejectionCostResult +
            logisticsResult,
    ).toFixed(2);
    const sensitivityToolingInvestment =
        analysisData.toolingInvestment * (1 + sensitivityInputs.toolingInvestment / 100);
    const sensitivityDesignInvestment = analysisData.designInvestment * (1 + sensitivityInputs.designInvestment / 100);
    return {
        analysisId: analysisData?.analysisId ?? '',
        analysisType: analysisData?.analysisType ?? '',
        module: analysisData?.selectedModule,
        subModule: analysisData?.selectedSubmodule,
        analysisTitle: analysisData?.analysisTitle,
        additionalInformation: analysisData?.additionalInfo,
        detailedAnalysisDescription: analysisData?.detailedAnalysisDesc,
        impactOnOtherModules: analysisData?.impactOnOthers,
        creator: analysisData?.originator,
        partsData: analysisData?.partsData,
        input: {
            toolingInvestment: Number(analysisData?.toolingInvestment)?.toFixed(2) || 0,
            designInvestment: Number(analysisData?.designInvestment)?.toFixed(2) || 0,
            residualToolingInvestment: Number(analysisData?.residualToolingInvestment)?.toFixed(2) || 0,
            lineInvestment: Number(analysisData?.lineInvestment)?.toFixed(2) || 0,
            otherInvestment: Number(analysisData?.otherInvestment)?.toFixed(2) || 0,
            proposedDesignInvestment: sensitivityInputs.designInvestment,
            proposedToolingInvestment: sensitivityInputs.toolingInvestment,
            proposedMaterialCost: sensitivityInputs.materialCost,
            proposedAnnualVolume: sensitivityInputs.annualVolumes,
        },
        output: {
            dmcResult: Number(sensitivityDMC)?.toFixed(2),
            scaleBenefitResult: Number(analysisResults?.scaleBenefitResult)?.toFixed(2),
            inventoryCost: inventoryResult?.toFixed(2),
            warrantyCost: analysisResults.warrantyResult?.toFixed(2),
            rejectionCost: analysisResults.rejectionCostResult?.toFixed(2),
            spareCost: null,
            logisticsCost: logisticsResult?.toFixed(2),
            totalCost: Number(totalRecurringImpact)?.toFixed(2),
            recurringImpact: Number(totalRecurringImpact)?.toFixed(2),
            totalInvestment: (
                Number(sensitivityToolingInvestment) +
                Number(sensitivityDesignInvestment) +
                Number(analysisData.otherInvestment) +
                Number(analysisData.lineInvestment)
            ).toFixed(2),
            variantReduction: (Number(analysisResults?.variantReduction) * 100)?.toFixed(2),
            sunkCost: Number(analysisData?.residualToolingInvestment)?.toFixed(2),
            efficiencyEnhancementCost: calcEfficiencyEnchancement(analysisResults)?.toFixed(2),
            efficiencyEnhancementTime: calcEfficiencyEnchancementHours(analysisResults)?.toFixed(2),
            paybackPeriod: Number(analysisResults?.paybackPeriod)?.toFixed(2),
            cashRelease: Number(analysisResults.cashFlowReleaseResult)?.toFixed(2),
            npv: Number(analysisResults?.npv)?.toFixed(2) ?? 0,
            irr: Number(analysisResults?.irr)?.toFixed(2) ?? 0,
            designInvestment: sensitivityDesignInvestment?.toFixed(2) || 0,
            toolingInvestment: sensitivityToolingInvestment?.toFixed(2) || 0,
            lifeInvestment: Number(analysisData?.lineInvestment)?.toFixed(2) || 0,
            otherInvestment: Number(analysisData?.otherInvestment)?.toFixed(2) || 0,
        },
    };
};
