import styled, { css } from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const BackIcon = styled(ArrowBackIcon)(
    ({ theme }) => css`
        color: ${theme.colors.accentPrimary};
        height: 2rem;
        width: 2rem;
        margin-right: 1.5rem;
    `,
);

export const Title = styled.h1(
    ({ theme }) => css`
        ${theme.typography.robotoLight32}
    `,
);
