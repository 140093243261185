import styled, { css } from 'styled-components';
import { columnSpacing } from '../PartsSelection.style';

export const Heading = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        ${columnSpacing};
        text-transform: uppercase;
        color: ${theme.colors.subtextLight};
        flex: 1;
        ::after {
            content: ' *';
            font-size: 1.2rem;
            color: ${theme.colors.textSalmon};
        }
    `,
);

export const SubHeading = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoMedium10};
        ${columnSpacing};
        text-transform: uppercase;
        margin-top: 0.25rem;
        color: ${theme.colors.textSalmon};
        flex: 1;
    `,
);
