import { GetProjectByIdResponse } from '../../constants/apiConfig';

export const formatForExport = (projectDetailsRes: GetProjectByIdResponse): {} => {
    const {
        dmc_result,
        scale_benefit_result,
        warranty_result,
        supplier_line_investments,
        supplier_internal_investments,
        tooling_investment_result,
        design_investment_result,
        projectAnalyses,
        line_investment_result,
        other_investment_result,
        other_investments,
        design_manhours,
        consultation_costs,
        prototyping_costs,
        certification_tooling_costs,
        supplier_rd_costs,
        working_capital_result,
        lr_material_result,
        inbound_logistics_result,
        intra_plant_logistics_result,
        part_obsolescence_result,
        space_utilization_result,
        cash_flow_release_result,
        integration_design_investments,
        integration_line_investments,
        total_design_investments,
        total_line_investments,
        total_other_investments,
        total_tooling_investments,
    } = projectDetailsRes;
    const modules = [...new Set(projectAnalyses.map((e) => e.hierarchy_5))];
    const designInvestment =
        design_manhours + consultation_costs + prototyping_costs + certification_tooling_costs + supplier_rd_costs;
    const lineInvestment = supplier_internal_investments + supplier_line_investments;
    const inventoryCost = working_capital_result + part_obsolescence_result + space_utilization_result;
    const logisticsCost = inbound_logistics_result + intra_plant_logistics_result;

    return {
        projectId: projectDetailsRes.id,
        platform: projectDetailsRes.hierarchy_1,
        product: projectDetailsRes.hierarchy_2,
        projectTitle: projectDetailsRes.title,
        description: projectDetailsRes.description,
        creator: projectDetailsRes.originator,
        input: {
            models: projectDetailsRes.hierarchy_3,
            modules: modules,
            designInvestments: designInvestment?.toFixed(2) ?? 0,
            lineInvestments: lineInvestment?.toFixed(2) ?? 0,
            otherInvestments: other_investment_result?.toFixed(2) ?? 0,
        },
        output: {
            dmcResult: Number(dmc_result).toFixed(2),
            scaleBenefitResult: Number(scale_benefit_result).toFixed(2),
            inventoryCost: inventoryCost.toFixed(2),
            warrantyCost: warranty_result?.toFixed(2) ?? 0,
            rejectionCost: lr_material_result?.toFixed(2) ?? 0,
            spareCost: null,
            logisticsCost: logisticsCost?.toFixed(2) ?? 0,
            totalCost: Number(
                dmc_result +
                    scale_benefit_result +
                    inventoryCost +
                    logisticsCost +
                    warranty_result +
                    lr_material_result,
            ).toFixed(2),
            recurringImpact: Number(
                dmc_result +
                    scale_benefit_result +
                    inventoryCost +
                    logisticsCost +
                    warranty_result +
                    lr_material_result,
            ).toFixed(2),
            totalInvestment: (
                Number(tooling_investment_result) +
                Number(design_investment_result) +
                Number(other_investment_result) +
                Number(line_investment_result)
            )?.toFixed(2),
            sunkCost: null,
            paybackPeriod: Number(projectDetailsRes?.paybackPeriod)?.toFixed(2) ?? 0,
            cashRelease: cash_flow_release_result?.toFixed(2) ?? 0,
            npv: Number(projectDetailsRes?.npv)?.toFixed(2) ?? 0,
            irr: Number(projectDetailsRes?.irr)?.toFixed(2) ?? 0,
            moduleDesignInvestment: design_investment_result?.toFixed(2) ?? 0,
            moduleToolingInvestment: tooling_investment_result?.toFixed(2) ?? 0,
            moduleLineInvestment: line_investment_result?.toFixed(2) ?? 0,
            moduleOtherInvestment: other_investment_result?.toFixed(2) ?? 0,
            integrationDesignInvestment: integration_design_investments?.toFixed(2) ?? 0,
            integrationToolingInvestment: null,
            integrationLineInvestment: integration_line_investments?.toFixed(2) ?? 0,
            integrationOtherInvestment: other_investments,
            totalDesignInvestment: total_design_investments?.toFixed(2) ?? 0,
            totalLineInvestment: total_line_investments?.toFixed(2) ?? 0,
            totalOtherInvestment: total_other_investments?.toFixed(2) ?? 0,
            totalToolingInvestment: total_tooling_investments?.toFixed(2) ?? 0,
        },
    };
};
