import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 1.5rem 1rem;
        overflow: auto;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 0.875rem;
    height: 3.5rem;
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
`;

export const BodyContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    > div:nth-of-type(odd) {
        width: 25%;
    }
`;
