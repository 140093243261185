import styled, { css } from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const AppHeader = styled(AppBar)(
    ({ theme }) => css`
        ${theme.typography.robotoMedium14};
        background: ${theme.colors.backgroundLight};
    `,
);

export const HeaderPrimaryText = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
        color: ${theme.colors.accentPrimary};
        flex-grow: 1;
    `,
);

export const UserIcon = styled(IconButton)(
    ({ theme }) => css`
        color: ${theme.colors.accentPrimary};
    `,
);

export const AccountInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > div {
        margin-right: 2%;
    }
`;

export const BoldText = styled.span(
    ({ theme }) => css`
        ${theme.typography.robotoMedium14};
    `,
);

export const Logo = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 0.25rem;
    cursor: pointer;
`;
