import React from 'react';
import { BackIcon, Title } from './Title.style';

interface Props {
    title: string;
    showBackButton?: boolean;
    handleBackButtonClick?: () => void;
}

export default function PageTitle({
    title,
    showBackButton = false,
    handleBackButtonClick = (): void => {
        return;
    },
}: Props): JSX.Element {
    return (
        <>
            {showBackButton && (
                <div onClick={handleBackButtonClick} style={{ cursor: 'pointer' }}>
                    <BackIcon />
                </div>
            )}
            <Title>{title}</Title>
        </>
    );
}
