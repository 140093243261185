import styled, { css } from 'styled-components';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledSelect = styled(Select)(
    ({ theme }) => css`
        background: ${theme.colors.backgroundLight};
        width: 100%;
        .MuiSelect-icon {
            color: ${theme.colors.accentPrimary};
        }
        color: ${theme.colors.accentPrimary};
    `,
);

export const Label = styled(InputLabel)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
    `,
);

export const StyledMenuItem = styled(MenuItem)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
        &&:hover {
            background-color: ${theme.colors.accentPrimary};
            color: ${theme.colors.textDark};
        }
    `,
);

export const SelectContainer = styled(FormControl)`
    width: 100%;
    .MuiInputLabel-shrink {
        display: none;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
        border-radius: 4px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    }
`;

export const Container = styled.div`
    width: 100%;
`;
