import React from 'react';
import CocLogo from '../../resources/logo.png';
import * as S from './CustomLoginHeader.style';

const CustomLoginHeader = (): JSX.Element => {
    return (
        <>
            <S.LoginGlobalStyle />
            <S.CustomHeaderContainer>
                <S.AppLogo src={CocLogo} alt="app logo" />
                <S.CustomHeader>Cost of Complexity</S.CustomHeader>
            </S.CustomHeaderContainer>
        </>
    );
};

export default CustomLoginHeader;
