import React, { useState, useEffect } from 'react';
import * as S from './EditModal.style';
import Select from '../../../../components/Select/Select';
import TextInput from '../../../../components/TextInput/TextInput';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import HierarchyInput from '../HierarchyInput/HierarchyInput';
import {
    VerticalHierarchyDetails,
    defaultVerticalHierarchyDetails,
    HorizontalHierarchyDetails,
    defaultHorizontalHierarchyDetails,
} from '../../UserManagement.config';
import Button, { ButtonType } from '../../../../components/Button/Button';
import { UsersResponse } from '../../../../constants/apiConfig';
import {
    AccountInfo,
    defaultAccountInfo,
    basicInfoConfig,
    dataAdminRadioOptions,
    userStatusOptions,
    userTypeOptions,
} from './EditModal.config';
import { zipObject, compact } from 'lodash';
import { Roles } from '../../../../constants/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
    handleClose: () => void;
    handleConfirm: (val: any) => void;
    isOpen: boolean;
    userData: UsersResponse | undefined;
    loading: boolean;
}

export default function StyledModal({ handleClose, handleConfirm, isOpen, userData, loading }: Props): JSX.Element {
    const [accountInfo, setAccountInfo] = useState<AccountInfo>(defaultAccountInfo);
    const [validFromDate, setValidFromDate] = useState<Date | null>();
    const [validToDate, setValidToDate] = useState<Date | null>();
    const [isVerticalHierarchySelected, setIsVerticalHierarchySelected] = useState<string>();
    const [isHorizontalHierarchySelected, setIsHorizontalHierarchySelected] = useState<string>();
    const [verticalHierarchyDetails, setVerticalHierarchyDetails] = useState<VerticalHierarchyDetails>(
        defaultVerticalHierarchyDetails,
    );
    const [horizontalHierarchyDetails, setHorizontalHierarchyDetails] = useState<HorizontalHierarchyDetails>(
        defaultHorizontalHierarchyDetails,
    );
    const [allPlatformsSelected, setAllPlatformsSelected] = useState<boolean>();
    const [allModulesSelected, setAllModulesSelected] = useState<boolean>();

    const handleAccountInfoChange = (key: string) => (event: React.ChangeEvent<{ value: unknown }>): void => {
        setAccountInfo({ ...accountInfo, [key]: event.target.value });
    };

    useEffect(() => {
        if (userData) {
            setAccountInfo({
                userStatus: userData.status,
                chargeCode: userData.charge_code,
                department: userData.department,
                designation: userData.designation,
                selectedUserType: userData.type,
                isDataAdmin: userData.roles.find((role) => role === Roles.DATA_ADMIN) ? 'Yes' : 'No',
                isSuperUser: userData.roles.find((role) => role === Roles.SUPER_USER) ? 'Yes' : 'No',
            });
            setValidFromDate(new Date(userData.valid_from));
            setValidToDate(new Date(userData.valid_until));
            setIsVerticalHierarchySelected(userData.roles.find((role) => role === Roles.PLATFORM_LEAD) ? 'Yes' : 'No');
            setIsHorizontalHierarchySelected(userData.roles.find((role) => role === Roles.MODULE_LEAD) ? 'Yes' : 'No');

            setAllModulesSelected(userData.modules.includes('ALL'));
            if (userData.platforms.includes('ALL')) {
                setAllPlatformsSelected(userData.platforms.includes('ALL'));
            } else {
                const platformsInfo = zipObject(Object.keys(defaultVerticalHierarchyDetails), userData.platforms);
                Object.keys(platformsInfo).forEach((item) => {
                    if (platformsInfo[item] === undefined) platformsInfo[item] = '';
                });
                setVerticalHierarchyDetails(platformsInfo as VerticalHierarchyDetails);
            }
            if (userData.modules.includes('ALL')) {
                setAllModulesSelected(userData.modules.includes('ALL'));
            } else {
                const modulesInfo = zipObject(Object.keys(defaultHorizontalHierarchyDetails), userData.modules);
                Object.keys(modulesInfo).forEach((item) => {
                    if (modulesInfo[item] === undefined) modulesInfo[item] = '';
                });
                setHorizontalHierarchyDetails(modulesInfo as HorizontalHierarchyDetails);
            }
        }
    }, [userData]);

    const handleSubmit = (): void => {
        const roles = [];
        if (isVerticalHierarchySelected === 'Yes') roles.push(Roles.PLATFORM_LEAD);
        if (isHorizontalHierarchySelected === 'Yes') roles.push(Roles.MODULE_LEAD);
        if (accountInfo.isDataAdmin === 'Yes') roles.push(Roles.DATA_ADMIN);
        if (accountInfo.isSuperUser === 'Yes') roles.push(Roles.SUPER_USER);

        /**
         *
         * CAN YOU GUYS HEAR ME??? YES WE CAN.
         */

        const updatedUserDetails = {
            id: userData?.id,
            designation: accountInfo.designation,
            department: accountInfo.department,
            valid_from: validFromDate,
            valid_until: validToDate,
            status: accountInfo.userStatus,
            charge_code: accountInfo.chargeCode,
            platforms: allPlatformsSelected
                ? ['ALL']
                : compact(Object.keys(verticalHierarchyDetails).map((key) => verticalHierarchyDetails[key])),
            modules: allModulesSelected
                ? ['ALL']
                : compact(Object.keys(horizontalHierarchyDetails).map((key) => horizontalHierarchyDetails[key])),
            roles: roles,
            type: accountInfo.selectedUserType,
        };
        handleConfirm(updatedUserDetails);
    };

    const userStatus = userData?.status;

    return (
        <S.StyledDialog onClose={handleClose} transitionDuration={0} scroll={'paper'} open={isOpen} maxWidth={false}>
            <S.StyledDialogTitle disableTypography>
                <S.TitleContainer>
                    <div>{userData?.first_name ?? '' + userData?.last_name}</div>
                    {userStatus && <S.Status userStatus={userStatus}>{userStatus}</S.Status>}
                </S.TitleContainer>
                <S.ContactDetails>
                    <div>{userData?.email}</div>
                    <div>{userData?.contact}</div>
                </S.ContactDetails>
            </S.StyledDialogTitle>
            <S.StyledDialogContent>
                <S.AccountInfo>
                    <Select
                        label="USER TYPE"
                        value={accountInfo.selectedUserType}
                        items={userTypeOptions}
                        handleChange={handleAccountInfoChange('selectedUserType')}
                    />
                    {basicInfoConfig.map((config) => (
                        <TextInput
                            key={config.key}
                            label={config.label}
                            value={(accountInfo as any)[config.key]}
                            handleChange={handleAccountInfoChange(config.key)}
                        />
                    ))}

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <S.StyledDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            label="VALID FROM"
                            value={validFromDate}
                            onChange={(date): void => setValidFromDate(date as Date)}
                        />
                        <S.StyledDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            label="VALID TO"
                            value={validToDate}
                            onChange={(date): void => setValidToDate(date as Date)}
                        />
                    </MuiPickersUtilsProvider>
                </S.AccountInfo>
                <HierarchyInput
                    isVerticalHierarchySelected={isVerticalHierarchySelected}
                    setIsVerticalHierarchySelected={setIsVerticalHierarchySelected}
                    verticalHierarchyDetails={verticalHierarchyDetails}
                    setVerticalHierarchyDetails={setVerticalHierarchyDetails}
                    isHorizontalHierarchySelected={isHorizontalHierarchySelected}
                    setIsHorizontalHierarchySelected={setIsHorizontalHierarchySelected}
                    horizontalHierarchyDetails={horizontalHierarchyDetails}
                    setHorizontalHierarchyDetails={setHorizontalHierarchyDetails}
                    allPlatformsSelected={allPlatformsSelected}
                    setAllPlatformsSelected={setAllPlatformsSelected}
                    allModulesSelected={allModulesSelected}
                    setAllModulesSelected={setAllModulesSelected}
                />
                <S.FlexRow>
                    <RadioButton
                        label="Data Admin"
                        items={dataAdminRadioOptions}
                        value={accountInfo.isDataAdmin}
                        handleChange={handleAccountInfoChange('isDataAdmin')}
                    />
                    <RadioButton
                        label="Super User"
                        items={dataAdminRadioOptions}
                        value={accountInfo.isSuperUser}
                        handleChange={handleAccountInfoChange('isSuperUser')}
                    />
                    <RadioButton
                        label="Status"
                        items={userStatusOptions}
                        value={accountInfo.userStatus}
                        handleChange={handleAccountInfoChange('userStatus')}
                    />
                </S.FlexRow>
                <S.ActionButtons>
                    <div>
                        <Button buttonType={ButtonType.TEXT} onClickHandler={handleClose} disabled={loading}>
                            CANCEL
                        </Button>
                    </div>
                    <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleSubmit} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </S.ActionButtons>
            </S.StyledDialogContent>
        </S.StyledDialog>
    );
}
