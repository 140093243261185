export interface TabConfig {
    title: string;
    headCells: Array<string>;
    bodyCells: Array<string>;
    path: string;
    key: string;
}

export const tabConfig: Array<TabConfig> = [
    {
        title: 'Parts',
        headCells: [
            'PartNumber',
            'Platform',
            'Product',
            'Model',
            'Variant',
            'Module',
            'Sub-Module',
            'Cost',
            'Volume',
            'Avg Inventory Days',
            'Obsolescence',
            'Units Per Bin',
            'First Pass Rate',
            'Scrap Rate',
            'Warranty Instances',
            'Cost Per Instance',
            'Inbound Logistics Cost',
            'Intra Plant Logistics Cost',
            'Rework Time Per Unit',
            'Added on',
            'Updated At',
        ],
        bodyCells: [
            'partNumber',
            'platform',
            'product',
            'model',
            'variant',
            'hierarchy_5',
            'hierarchy_6',
            'cost',
            'volume',
            'avgInventoryDays',
            'obsolescence',
            'unitsPerBin',
            'firstPassRate',
            'scrapRate',
            'warrantyInstances',
            'costPerInstance',
            'inboundLogisticsCost',
            'intraPlantLogisticsCost',
            'reworkTimePerUnit',
            'created_at',
            'updated_at',
        ],
        path: 'data-view/parts',
        key: 'parts',
    },
    {
        title: 'Location',
        headCells: [
            'ID',
            'Name',
            'Bin Size',
            'WACC',
            'Warehouse Cost',
            'Design Manhour Cost',
            'Handling Manhour Cost',
            'Assembly Manhour Cost',
            'EOL Inspection Manhour Cost',
            'Rework Labour Rate',
            'Contractual Assembly Manhour Cost',
            'Inspection Manhour Cost',
            'Documentation Manhour Cost',
            'Quality Manhour Cost',
            'Purchase Manhour Cost',
            'Added on',
            'Updated At',
        ],
        bodyCells: [
            'id',
            'name',
            'bin_size',
            'wacc',
            'warehouse_cost',
            'design_manhour_cost',
            'handling_manhour_cost',
            'assembly_manhour_cost',
            'eol_inspection_manhour_cost',
            'rework_labour_rate',
            'contractual_assembly_manhour_cost',
            'inspection_manhour_cost',
            'documentation_manhour_cost',
            'quality_manhour_cost',
            'purchase_manhour_cost',
            'created_at',
            'updated_at',
        ],
        path: 'locations',
        key: 'locations',
    },
    {
        title: 'Category Distribution',
        headCells: [
            'ID',
            'Module',
            'Sub-Module',
            'Category',
            'Purchase Manhour Cost',
            'Quality Manhour Cost',
            'Inspection Manhour Cost',
            'Documentation Manhour Cost',
            'Design Manhour Cost',
            'Line Manhour Cost',
            'Permanent Manpower %',
            'Added on',
            'Updated At',
        ],
        bodyCells: [
            'id',
            'hierarchy_5',
            'hierarchy_6',
            'category',
            'purchase_manhour_cost',
            'quality_manhour_cost',
            'inspection_manhour_cost',
            'documentation_manhour_cost',
            'design_manhour_cost',
            'line_manhour_cost',
            'permanent_manpower_percentage',
            'created_at',
            'updated_at',
        ],
        path: 'category-distribution',
        key: 'categoryDistribution',
    },
    {
        title: 'NPV, IRR & Payback',
        headCells: [
            'ID',
            'Analysis',
            'y1h1',
            'y1h2',
            'y2h1',
            'y2h2',
            'y3h1',
            'y3h2',
            'y4h1',
            'y4h2',
            'y5h1',
            'y5h2',
            'rate',
            'Added on',
            'Updated At',
        ],
        bodyCells: [
            'id',
            'analysis',
            'y1h1',
            'y1h2',
            'y2h1',
            'y2h2',
            'y3h1',
            'y3h2',
            'y4h1',
            'y4h2',
            'y5h1',
            'y5h2',
            'rate',
            'created_at',
            'updated_at',
        ],
        path: 'npv-irr',
        key: 'npvIrr',
    },
];

export const superuserTabs = [
    {
        title: 'Benchmark',
        headCells: [
            'ID',
            'Industry',
            'Sub-Industry',
            'Module',
            'Sub-Module',
            'Avg Inventory Days',
            'Discount Multiplier',
            'Discount Power',
            'Minimum Volume',
            'Maximum Volume',
            'First Pass Rate',
            'Handling Cost',
            'Handling Time Per Unit',
            'Inbound Logistics',
            'Intra Plant Logistics',
            'Module Maintenance Design',
            'Module Maintenance Documentation',
            'Module Maintenance Inspection',
            'Module Maintenance Line',
            'Module Maintenance Purchase',
            'Module Maintenance Quality',
            'Obsolescence',
            'Permanent Manpower Percent',
            'Scrap Rate',
            'Warehousing Cost',
            'Warranty Cost',
            'Added on',
            'Updated At',
        ],
        bodyCells: [
            'id',
            'industry',
            'sub_industry',
            'hierarchy_5',
            'hierarchy_6',
            'avg_inventory_days',
            'discount_multiplier',
            'discount_power',
            'min_volume',
            'max_volume',
            'first_pass_rate',
            'handling_cost',
            'handling_time_per_unit',
            'inbound_logistics',
            'intra_plant_logistics',
            'module_maintenance_design',
            'module_maintenance_documentation',
            'module_maintenance_inspection',
            'module_maintenance_line',
            'module_maintenance_purchase',
            'module_maintenance_quality',
            'obsolescence',
            'permanent_manpower_percent',
            'scrap_rate',
            'warehousing_cost',
            'warranty_cost',
            'created_at',
            'updated_at',
        ],
        path: 'benchmarks',
        key: 'benchmarks',
    },
    {
        title: 'Benchmark Quick',
        headCells: [
            'ID',
            'Industry',
            'Sub-Industry',
            'Avg Inventory Days',
            'Discount Multiplier',
            'Discount Power',
            'Minimum Volume',
            'Maximum Volume',
            'First Pass Rate',
            'Handling Cost',
            'Handling Time Per Unit',
            'Inbound Logistics',
            'Intra Plant Logistics',
            'Module Maintenance Design',
            'Module Maintenance Documentation',
            'Module Maintenance Inspection',
            'Module Maintenance Line',
            'Module Maintenance Purchase',
            'Module Maintenance Quality',
            'Obsolescence',
            'Permanent Manpower Percent',
            'Scrap Rate',
            'Warehousing Cost',
            'Warranty Cost',
            'Added on',
            'Updated At',
        ],
        bodyCells: [
            'id',
            'industry',
            'sub_industry',
            'avg_inventory_days',
            'discount_multiplier',
            'discount_power',
            'min_volume',
            'max_volume',
            'first_pass_rate',
            'handling_cost',
            'handling_time_per_unit',
            'inbound_logistics',
            'intra_plant_logistics',
            'module_maintenance_design',
            'module_maintenance_documentation',
            'module_maintenance_inspection',
            'module_maintenance_line',
            'module_maintenance_purchase',
            'module_maintenance_quality',
            'obsolescence',
            'permanent_manpower_percent',
            'scrap_rate',
            'warehousing_cost',
            'warranty_cost',
            'created_at',
            'updated_at',
        ],
        path: 'quickBenchmarks',
        key: 'quickBenchmarks',
    },
    {
        title: 'Scale Benefit',
        headCells: [
            'ID',
            'Category',
            'Discount Multiplier',
            'Discount Power',
            'Minimum Volume',
            'Maximum Volume',
            'Added on',
            'Updated At',
        ],
        bodyCells: [
            'id',
            'category',
            'discount_multiplier',
            'discount_power',
            'min_volume',
            'max_volume',
            'created_at',
            'updated_at',
        ],
        path: 'categories',
        key: 'categories',
    },
];
