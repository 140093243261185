import React from 'react';
import Select, { SelectItem } from '../../../../components/Select/Select';
import TextInput from '../../../../components/TextInput/TextInput';
import { ProjectDetails, projectDetailsConfig } from '../../NewProject.config';

import * as S from './ProjectDetails.style';

interface Props {
    selectedPlatform: string;
    platformOptions: Array<SelectItem>;
    setSelectedPlatform: React.Dispatch<React.SetStateAction<string>>;
    selectedProduct: string;
    productOptions: Array<SelectItem>;
    setSelectedProduct: React.Dispatch<React.SetStateAction<string>>;
    projectDetails: ProjectDetails;
    setProjectDetails: React.Dispatch<React.SetStateAction<ProjectDetails>>;
    showValidations: boolean;
    resetModelsAndModules: () => void;
    resetAnalysisList: () => void;
}

export default function ProjectDetailsPane({
    selectedPlatform,
    platformOptions,
    setSelectedPlatform,
    selectedProduct,
    productOptions,
    setSelectedProduct,
    projectDetails,
    setProjectDetails,
    showValidations,
    resetModelsAndModules,
    resetAnalysisList,
}: Props): JSX.Element {
    const handleChange = (key: keyof ProjectDetails) => (event: React.ChangeEvent<{ value: string }>): void => {
        const value = event?.target?.value ?? '';
        setProjectDetails((prevDetails) => ({ ...prevDetails, [key]: value }));
    };

    return (
        <S.Container>
            <S.SelectHeader>
                SELECT PLATFORM<span>*</span>
            </S.SelectHeader>
            <Select
                handleChange={(event): void => {
                    resetAnalysisList();
                    resetModelsAndModules();
                    setSelectedProduct('');
                    setSelectedPlatform(event?.target?.value as string);
                }}
                value={selectedPlatform}
                label="PLATFORM"
                items={platformOptions}
            />
            <S.SelectHeader>
                SELECT PRODUCT<span>*</span>
            </S.SelectHeader>
            <Select
                handleChange={(event): void => {
                    resetAnalysisList();
                    resetModelsAndModules();
                    setSelectedProduct(event?.target?.value as string);
                }}
                value={selectedProduct}
                label="PRODUCT"
                items={productOptions}
            />
            {projectDetailsConfig.map((inputConfig) => (
                <TextInput
                    key={inputConfig.key}
                    dark
                    label={inputConfig.label}
                    placeholder={inputConfig.placeholder}
                    rows={inputConfig.rows}
                    disabled={!inputConfig.isEditable}
                    required={inputConfig.isRequired}
                    value={projectDetails?.[inputConfig.key]}
                    handleChange={handleChange(inputConfig.key)}
                    error={showValidations && projectDetails?.[inputConfig.key]?.length === 0 ? 'Required!' : ''}
                />
            ))}
        </S.Container>
    );
}
