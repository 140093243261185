import React from 'react';
import { clientInfoConfig, ClientDetails, clientContactConfig, firmContactConfig } from '../../ClientOnboarding.config';
import CircularProgress from '@material-ui/core/CircularProgress';
import DisplayToolContactModal from '../DisplayToolContact/DisplayToolContact';

import * as S from './DisplayClientData.style';

type Props = {
    clientDetails: ClientDetails | undefined;
    getClientLoading: boolean;
    handleToolContactClose: () => void;
    isToolContactModelOpen: boolean;
};

function DisplayClientData({
    clientDetails,
    getClientLoading,
    handleToolContactClose,
    isToolContactModelOpen,
}: Props): JSX.Element {
    if (getClientLoading) {
        return (
            <S.Container>
                <S.CenteredElement>
                    <CircularProgress />
                </S.CenteredElement>
            </S.Container>
        );
    }

    return (
        <S.Container>
            <DisplayToolContactModal
                isOpen={isToolContactModelOpen}
                handleClose={handleToolContactClose}
                clientDetails={clientDetails}
            />
            <S.HeaderContainer>
                <S.NameAndAddressContainer>
                    {clientDetails?.name || '--'}
                    <div>{clientDetails?.address || '--'}</div>
                </S.NameAndAddressContainer>
                <S.LogoContainer>
                    {clientDetails?.logo?.length ? <S.Img src={clientDetails?.logo} /> : 'COMPANY LOGO'}
                </S.LogoContainer>
            </S.HeaderContainer>
            <S.ClientDetailsContainer>
                {clientInfoConfig.map((config) => (
                    <S.DisplayContainer key={config.key}>
                        {config.label}
                        <div>{clientDetails?.[config.key] || '--'}</div>
                    </S.DisplayContainer>
                ))}
                <S.DisplayContainer>
                    CHARGE CODE
                    <div>{clientDetails?.charge_code || '--'}</div>
                </S.DisplayContainer>
            </S.ClientDetailsContainer>
            <S.ClientDetailsContainer>
                <S.SectionTitle>CLIENT POINTS OF CONTACT</S.SectionTitle>
                {clientContactConfig.map((contactConfig) => (
                    <S.FlexContainer key={contactConfig.label}>
                        <S.ContactContainer key={contactConfig.label}>
                            {contactConfig.inputs.map((config) => (
                                <div key={`${config.key}${contactConfig.label}`}>
                                    {clientDetails?.[config.key] || '--'}
                                </div>
                            ))}
                        </S.ContactContainer>
                    </S.FlexContainer>
                ))}
            </S.ClientDetailsContainer>
            <S.ClientDetailsContainer>
                <S.SectionTitle>FIRM POINTS OF CONTACT</S.SectionTitle>
                {firmContactConfig.map((contactConfig) => (
                    <S.FlexContainer key={contactConfig.label}>
                        <S.ContactContainer key={contactConfig.label}>
                            {contactConfig.inputs.map((config) => (
                                <div key={`${config.key}${contactConfig.label}`}>
                                    {clientDetails?.[config.key] || '--'}
                                </div>
                            ))}
                        </S.ContactContainer>
                    </S.FlexContainer>
                ))}
            </S.ClientDetailsContainer>
        </S.Container>
    );
}

export default DisplayClientData;
