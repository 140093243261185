import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        ${theme.typography.robotoRegular12};
    `,
);

export const ExpansionPanelContent = styled.div`
    > div {
        margin-top: 1rem;
    }
`;

export const Asterisk = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.accentSecondary};
    `,
);
