import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 5%;
        > div {
            width: 45%;
        }
    `,
);
