import React from 'react';
import {
    ProjectInvestments,
    designInvestmentsConfig,
    lineInvestmentsConfig,
    otherInvestmentsConfig,
} from '../../NewProject.config';
import ExpansionPanel from '../../../../components/ExpansionPanel/ExpansionPanel';
import TextInput from '../../../../components/TextInput/TextInput';

import * as S from './ProjectInvestments.style';

interface Props {
    projectInvestments: ProjectInvestments;
    setProjectInvestments: React.Dispatch<React.SetStateAction<ProjectInvestments>>;
}

export default function ProjectInvestmentsPane({ projectInvestments, setProjectInvestments }: Props): JSX.Element {
    const handleChange = (key: keyof ProjectInvestments) => (event: React.ChangeEvent<{ value: string }>): void => {
        const value = event?.target?.value;
        setProjectInvestments((prevDetails) => ({ ...prevDetails, [key]: value }));
    };

    return (
        <S.Container>
            <ExpansionPanel title="DESIGN INVESTMENTS">
                <S.ExpansionPanelContent>
                    {designInvestmentsConfig.map((inputConfig) => (
                        <TextInput
                            key={inputConfig.key}
                            label={inputConfig.label}
                            required={inputConfig.isRequired}
                            value={projectInvestments[inputConfig.key]}
                            handleChange={handleChange(inputConfig.key)}
                        />
                    ))}
                </S.ExpansionPanelContent>
            </ExpansionPanel>
            <ExpansionPanel title="LINE INVESTMENTS">
                <S.ExpansionPanelContent>
                    {lineInvestmentsConfig.map((inputConfig) => (
                        <TextInput
                            key={inputConfig.key}
                            label={inputConfig.label}
                            required={inputConfig.isRequired}
                            value={projectInvestments[inputConfig.key]}
                            handleChange={handleChange(inputConfig.key)}
                        />
                    ))}
                </S.ExpansionPanelContent>
            </ExpansionPanel>
            <ExpansionPanel title="OTHER INVESTMENTS">
                <S.ExpansionPanelContent>
                    {otherInvestmentsConfig.map((inputConfig) => (
                        <TextInput
                            key={inputConfig.key}
                            label={inputConfig.label}
                            required={inputConfig.isRequired}
                            value={projectInvestments[inputConfig.key]}
                            handleChange={handleChange(inputConfig.key)}
                        />
                    ))}
                </S.ExpansionPanelContent>
            </ExpansionPanel>
            <span>
                <S.Asterisk>* </S.Asterisk>
                Mandatory Fields
            </span>
        </S.Container>
    );
}
