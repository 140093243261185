import { Module } from '../types/modules.types';

// Making this a class because the same can
// be instantiated to get the default store
export class GlobalStore {
    modules: Module[];

    constructor(modules: Module[] = []) {
        this.modules = modules;
    }
}

export type Action = {
    type: string;
    payload: any;
};

export type Reducer = (prevState: GlobalStore, action: Action) => GlobalStore;
