import React, { useState, useMemo } from 'react';
import PartsSelectionHeader from './PartsSelectionHeader/PartsSelectionHeader';
import EnterPartNumberRow from './EnterPartNumberRow/EnterPartNumberRow';
import AddNewPartRow from './AddNewPartRow/AddNewPartRow';
import MappingRow from './MappingRow/MappingRow';
import { PartMapping, Part, MappingType } from '../../NewAnalysis.types';
import PartsModal from '../../../../components/PartsModal/PartsModal';
import { CustomParts } from '../../../../constants/apiConfig';

type OperationType = 'extend' | 'replace';

interface Props {
    partsList: Array<Part>;
    partsMapping: Array<PartMapping>;
    setPartsMapping: (partsMapping: Array<PartMapping>) => void;
    openAddPartsModel: (destination: MappingType) => void;
    customParts: Array<CustomParts>;
    showSnackbar: (state: { isOpen: boolean; message: string }) => void;
}

function PartsSelection({
    partsList,
    openAddPartsModel,
    partsMapping,
    setPartsMapping,
    customParts,
    showSnackbar,
}: Props): JSX.Element {
    const [clipBoard, setClipBoard] = useState<Part | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    // This represents which side of the mapping the parts are being added to
    const [sideToModify, setSideToModify] = useState<MappingType>('from');
    // This represents whether the user wants to extend the list or replace at a certain index
    const [operationType, setOperationType] = useState<OperationType>('extend');
    // This represents which index to edit (used for replace only)
    const [focusIndex, setFocusIndex] = useState<number>(0);

    const [currentPartCount, proposedPartsCount, proposedRowsFilled] = useMemo(() => {
        const proposedPartExists: { [key in string]: boolean } = {};
        return partsMapping.reduce(
            (acc, curr) => {
                const proposedPartNumber = curr.to?.partNumber;
                const currentPartNumber = curr.from?.partNumber;
                if (currentPartNumber) acc[0] += 1;
                if (proposedPartNumber) acc[2] += 1;
                if (proposedPartNumber && !proposedPartExists[proposedPartNumber as string]) acc[1] += 1;
                proposedPartExists[proposedPartNumber as string] = true;

                return acc;
            },
            [0, 0, 0],
        );
    }, [partsMapping]);

    // when showing options in current parts selection,
    // use only unselected current parts
    const [currentPartOptions, customPartOptions] = useMemo(() => {
        return [
            partsList.filter(
                ({ partNumber }) => !partsMapping.some((mapping) => mapping.from?.partNumber === partNumber),
            ),
            customParts.filter(
                ({ part_number }) => !partsMapping.some((mapping) => mapping.from?.partNumber === part_number),
            ),
        ];
    }, [partsList, partsMapping, customParts]);

    const replacePart = (type: MappingType, index: number): void => {
        setFocusIndex(index); // don't worry
        setSideToModify(type); // about
        setOperationType('replace'); // multiple state updates
        setIsModalOpen(true); // react updates them together
    };

    const confirmReplace = (part: Part | null, type: MappingType, index: number): void => {
        const newMapping = [...partsMapping];
        let replacedMapping;
        if (type === 'from') {
            replacedMapping = { from: part, to: newMapping[index].to };
        } else {
            const currentPart = newMapping[index].from;
            if (!part?.isUserAdded && !currentPart) {
                setIsModalOpen(false);
                return;
            }
            replacedMapping = { from: newMapping[index].from, to: part };
        }
        newMapping.splice(index, 1, replacedMapping);

        setPartsMapping(newMapping);
        setIsModalOpen(false);
    };

    const openExtendListModal = (type: MappingType): void => {
        setSideToModify(type); // don't worry about
        setOperationType('extend'); // multiple state updates
        setIsModalOpen(true); // react updates them together
    };

    const confirmExtendList = (parts: Part[], customParts: Part[]): void => {
        let indexToInsert = partsMapping.findIndex((mapping) => !mapping[sideToModify]);
        indexToInsert = indexToInsert === -1 ? partsMapping.length : indexToInsert;
        const newParts = [...parts, ...customParts];
        let newPartMaps;
        if (sideToModify === 'from') {
            newPartMaps = newParts.map((part, index) => ({
                from: part,
                to: partsMapping[indexToInsert + index]?.to || null,
            }));
        } else {
            newPartMaps = newParts.map((part, index) => ({
                to: part,
                from: partsMapping[indexToInsert + index]?.from || null,
            }));
        }

        const newPartsMapping = [...partsMapping];
        newPartsMapping.splice(indexToInsert, newPartMaps.length, ...newPartMaps);
        setPartsMapping(newPartsMapping);
        setIsModalOpen(false);
    };

    const openAddNewPartModal = (type: MappingType): void => {
        openAddPartsModel(type);
    };

    const copyPart = (index: number): void => {
        const part = partsMapping[index].to;
        setClipBoard(part);
    };

    const pastePart = (index: number): void => {
        const isCurrentEmpty = !partsMapping[index].from;
        if (isCurrentEmpty && !clipBoard?.isUserAdded) return;

        const newPartsMapping = [...partsMapping];
        const newMapping = { ...partsMapping[index], to: clipBoard };
        newPartsMapping.splice(index, 1, newMapping);
        setPartsMapping(newPartsMapping);
    };

    const deleteRow = (index: number): void => {
        const newPartsMapping = [...partsMapping];
        newPartsMapping.splice(index, 1);
        setPartsMapping(newPartsMapping);
    };

    return (
        <div>
            <PartsSelectionHeader currentPartsCount={currentPartCount} proposedPartsCount={proposedPartsCount} />
            {partsMapping.map((mapping, index) => (
                <MappingRow
                    // Using index in key because we want it to refresh everytime
                    key={JSON.stringify({ mapping, index })}
                    currentPart={mapping.from?.partNumber}
                    proposedPart={mapping.to?.partNumber}
                    isCustomCurrentPart={mapping.from?.isUserAdded}
                    isCustomProposedPart={mapping.to?.isUserAdded}
                    replaceCurrentPart={(): void => replacePart('from', index)}
                    replaceProposedPart={(): void => replacePart('to', index)}
                    copyProposedPart={(): void => copyPart(index)}
                    pasteProposedPart={(): void => pastePart(index)}
                    deleteRow={(): void => deleteRow(index)}
                />
            ))}
            <EnterPartNumberRow
                onCurrentClick={(): void => openExtendListModal('from')}
                onProposedClick={(): void => openExtendListModal('to')}
            />
            <AddNewPartRow
                onCurrentClick={(): void => openAddNewPartModal('from')}
                onProposedClick={(): void => openAddNewPartModal('to')}
            />
            <PartsModal
                handleClose={(): void => setIsModalOpen(false)}
                open={isModalOpen}
                onConfirm={confirmExtendList}
                partsData={sideToModify === 'from' ? currentPartOptions : partsList}
                customParts={sideToModify === 'from' ? customPartOptions : customParts}
                destination={sideToModify}
                operation={operationType}
                fromPartsCount={currentPartCount - proposedRowsFilled}
                replaceIndex={focusIndex}
                onConfirmReplace={confirmReplace}
                showSnackbar={showSnackbar}
            />
        </div>
    );
}

export default PartsSelection;
