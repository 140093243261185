import React from 'react';
import * as S from './ProjectPopover.style';

interface Props {
    title: string;
    modulesImpacted: number;
    modelsImpacted: number;
    children: React.ReactNode;
}

export default function ProjectPopover({ title, modulesImpacted, modelsImpacted, children }: Props): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <S.ChildElementText
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {children}
            </S.ChildElementText>
            <S.StyledPopover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <S.PopoverTitle>{title}</S.PopoverTitle>
                <hr />
                <S.MetricRow>
                    <div>
                        <S.MetricValue>{modelsImpacted}</S.MetricValue>
                        <S.MetricLabel>Models Impacted</S.MetricLabel>
                    </div>
                    <div>
                        <S.MetricValue>{modulesImpacted}</S.MetricValue>
                        <S.MetricLabel>Modules Impacted</S.MetricLabel>
                    </div>
                </S.MetricRow>
            </S.StyledPopover>
        </div>
    );
}
