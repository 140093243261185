import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import { ButtonType } from './Button';

export const StyledButton = styled(Button)<{ buttonType: ButtonType }>(
    ({ theme, buttonType }) => css`
        width: 100%;
        white-space: nowrap;
        ${theme.typography.robotoRegular14};
        color: ${buttonType === ButtonType.PRIMARY
            ? theme.colors.textDark
            : buttonType === ButtonType.SECONDARY
            ? theme.colors.accentPrimary
            : theme.colors.accentPrimary};
        background-color: ${buttonType === ButtonType.PRIMARY
            ? theme.colors.accentPrimary
            : theme.colors.backgroundLight};
    `,
);

export const Container = styled.div`
    width: 100%;
`;
