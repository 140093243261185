import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 1.5rem 1rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 0.875rem;
    height: 3.5rem;
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
    > div {
        margin-left: 1rem;
    }
    > div:last-child {
        width: 200px;
    }
`;

export const BodyContainer = styled.div`
    display: flex;
    height: 100%;
    > div {
        flex-basis: 25%;
        margin-right: 1rem;
    }
    > div:last-child {
        margin-right: 0;
    }
`;

export const ProjectDetailsColumn = styled.div`
    display: flex;
    flex-direction: column;
    > div:first-child {
        flex: 5;
        margin-bottom: 1rem;
    }
    > div:last-child {
        flex: 1;
    }
`;
