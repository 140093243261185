export type ClientDetails = {
    name: string;
    logo: string;
    address: string;

    location: string;
    region: string;
    currency: string;
    industry: string;
    sub_industry: string;
    employees: string;

    charge_code: string;

    enable_industry: boolean;

    first_contact_name: string;
    first_contact_phone_number: string;
    first_contact_email: string;

    second_contact_name: string;
    second_contact_phone_number: string;
    second_contact_email: string;

    ed_name: string;
    ed_phone_number: string;
    ed_email: string;

    em_name: string;
    em_phone_number: string;
    em_email: string;

    adm_name: string;
    adm_phone_number: string;
    adm_email: string;

    core_contact_name: string;
    core_contact_phone_number: string;
    core_contact_email: string;

    other_contact_name: string;
    other_contact_phone_number: string;
    other_contact_email: string;

    onboarding_status: string;
};

export const initialClientDetails: ClientDetails = {
    name: '',
    logo: '',
    address: '',

    location: '',
    region: '',
    currency: '',
    industry: '',
    sub_industry: '',
    employees: '',

    charge_code: '',

    enable_industry: false,

    first_contact_name: '',
    first_contact_phone_number: '',
    first_contact_email: '',

    second_contact_name: '',
    second_contact_phone_number: '',
    second_contact_email: '',

    ed_name: '',
    ed_phone_number: '',
    ed_email: '',

    em_name: '',
    em_phone_number: '',
    em_email: '',

    adm_name: '',
    adm_phone_number: '',
    adm_email: '',

    core_contact_name: '',
    core_contact_phone_number: '',
    core_contact_email: '',

    other_contact_name: '',
    other_contact_phone_number: '',
    other_contact_email: '',

    onboarding_status: 'DEFAULT',
};

type ClientInfoConfig = Array<{
    type: 'TEXT' | 'SELECT';
    label: string;
    key: keyof ClientDetails;
    placeholder: string;
    mandatory: boolean;
    options?: Array<{
        value: string;
        label: string;
    }>;
}>;

export const clientInfoConfig: ClientInfoConfig = [
    {
        type: 'TEXT',
        label: 'HEAD OFFICE LOCATION',
        key: 'location',
        placeholder: 'Enter location',
        mandatory: false,
    },
    {
        type: 'SELECT',
        label: 'REGION',
        key: 'region',
        placeholder: 'Select region',
        mandatory: false,
        options: [
            { value: 'Africa', label: 'Africa' },
            { value: 'China', label: 'China' },
            { value: 'CIS', label: 'CIS' },
            { value: 'Eastern Europe', label: 'Eastern Europe' },
            { value: 'India', label: 'India' },
            { value: 'MENA', label: 'MENA' },
            { value: 'Mexico', label: 'Mexico' },
            { value: 'North America', label: 'North America' },
            { value: 'Oceania', label: 'Oceania' },
            { value: 'SEA', label: 'SEA' },
            { value: 'South Africa', label: 'South Africa' },
            { value: 'South America', label: 'South America' },
            { value: 'Western Europe', label: 'Western Europe' },
        ],
    },
    {
        type: 'SELECT',
        label: 'CURRENCY',
        key: 'currency',
        placeholder: 'Select currency',
        mandatory: false,
        options: [
            {
                value: 'INR',
                label: 'INR',
            },
        ],
    },
    {
        type: 'SELECT',
        label: 'INDUSTRY',
        key: 'industry',
        placeholder: 'Select industry',
        mandatory: true,
        options: [
            {
                value: 'AUTOMOTIVE',
                label: 'AUTOMOTIVE',
            },
        ],
    },
    {
        type: 'SELECT',
        label: 'SUB-INDUSTRY',
        key: 'sub_industry',
        placeholder: 'Select sub-industry',
        mandatory: true,
        options: [
            {
                value: 'COMMERCIAL VEHICLES',
                label: 'COMMERCIAL VEHICLES',
            },
            {
                value: 'PASSENGER VEHICLES',
                label: 'PASSENGER VEHICLES',
            },
            {
                value: '2 WHEELER',
                label: '2 WHEELER',
            },
            {
                value: 'FARM EQUIPMENT',
                label: 'FARM EQUIPMENT',
            },
        ],
    },
    {
        type: 'TEXT',
        label: 'EMPLOYEES',
        key: 'employees',
        placeholder: 'Enter number of employees',
        mandatory: false,
    },
];

type ContactConfig = Array<{
    label: string;
    mandatory: boolean;
    inputs: Array<{
        label: string;
        key: keyof ClientDetails;
        placeholder: string;
    }>;
}>;

export const clientContactConfig: ContactConfig = [
    {
        label: 'FIRST CONTACT',
        mandatory: false,
        inputs: [
            {
                key: 'first_contact_name',
                label: 'NAME',
                placeholder: 'Enter name',
            },
            {
                key: 'first_contact_phone_number',
                label: 'PHONE NUMBER',
                placeholder: 'Enter phone number',
            },
            {
                key: 'first_contact_email',
                label: 'EMAIL',
                placeholder: 'Enter email',
            },
        ],
    },
    {
        label: 'SECOND CONTACT',
        mandatory: false,
        inputs: [
            {
                key: 'second_contact_name',
                label: 'NAME',
                placeholder: 'Enter name',
            },
            {
                key: 'second_contact_phone_number',
                label: 'PHONE NUMBER',
                placeholder: 'Enter phone number',
            },
            {
                key: 'second_contact_email',
                label: 'EMAIL',
                placeholder: 'Enter email',
            },
        ],
    },
];

export const firmContactConfig: ContactConfig = [
    {
        label: 'ENGAGEMENT DIRECTOR',
        mandatory: true,
        inputs: [
            {
                key: 'ed_name',
                label: 'NAME',
                placeholder: 'Enter name',
            },
            {
                key: 'ed_phone_number',
                label: 'PHONE NUMBER',
                placeholder: 'Enter phone number',
            },
            {
                key: 'ed_email',
                label: 'EMAIL',
                placeholder: 'Enter email',
            },
        ],
    },
    {
        label: 'ENGAGEMENT MANAGER',
        mandatory: false,
        inputs: [
            {
                key: 'em_name',
                label: 'NAME',
                placeholder: 'Enter name',
            },
            {
                key: 'em_phone_number',
                label: 'PHONE NUMBER',
                placeholder: 'Enter phone number',
            },
            {
                key: 'em_email',
                label: 'EMAIL',
                placeholder: 'Enter email',
            },
        ],
    },
];

export const toolContactConfig: ContactConfig = [
    {
        label: 'ADM',
        mandatory: false,
        inputs: [
            {
                key: 'adm_name',
                label: 'NAME',
                placeholder: 'Enter name',
            },
            {
                key: 'adm_phone_number',
                label: 'PHONE NUMBER',
                placeholder: 'Enter phone number',
            },
            {
                key: 'adm_email',
                label: 'EMAIL',
                placeholder: 'Enter email',
            },
        ],
    },
    {
        label: 'CORE CONTACT',
        mandatory: false,
        inputs: [
            {
                key: 'core_contact_name',
                label: 'NAME',
                placeholder: 'Enter name',
            },
            {
                key: 'core_contact_phone_number',
                label: 'PHONE NUMBER',
                placeholder: 'Enter phone number',
            },
            {
                key: 'core_contact_email',
                label: 'EMAIL',
                placeholder: 'Enter email',
            },
        ],
    },
    {
        label: 'OTHER CONTACT',
        mandatory: false,
        inputs: [
            {
                key: 'other_contact_name',
                label: 'NAME',
                placeholder: 'Enter name',
            },
            {
                key: 'other_contact_phone_number',
                label: 'PHONE NUMBER',
                placeholder: 'Enter phone number',
            },
            {
                key: 'other_contact_email',
                label: 'EMAIL',
                placeholder: 'Enter email',
            },
        ],
    },
];

export const mandatoryFields: Array<keyof ClientDetails> = [
    'name',
    'industry',
    'sub_industry',
    'charge_code',
    'ed_name',
    'ed_phone_number',
    'ed_email',
];
