import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const TextInput = styled(TextField)<{ dark: boolean }>(
    ({ theme, dark }) =>
        css`
            width: 100%;
            .MuiFormLabel-root {
                ${theme.typography.robotoRegular14}
                color: ${dark ? theme.colors.subtextDark : theme.colors.subtextLight};
            }
            .MuiInputLabel-shrink {
                ${theme.typography.robotoRegular14}
                color: ${dark ? theme.colors.subtextDark : theme.colors.subtextLight};
            }
            .MuiInputLabel-formControl {
                position: static;
            }
            .MuiInput-formControl {
                margin-top: 0px;
            }
            && .MuiInput-underline::before {
                border-bottom-color: ${dark ? theme.colors.subtextDark : theme.colors.greyScaleMedium};
            }
            .MuiInput-underline::after {
                border-bottom-color: ${dark ? theme.colors.textDark : theme.colors.textLight};
            }
            .MuiInputBase-input {
                ${theme.typography.robotoRegular14}
                color: ${dark ? theme.colors.textDark : theme.colors.textLight};
            }
            .MuiInputLabel-asterisk {
                color: ${theme.colors.textSalmon};
                font-size: 1.5rem;
            }
            input[type=number]::-webkit-inner-spin-button, 
                input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
            }
            .Mui-disabled {
                color: ${theme.colors.greyScaleDark};
            }
          `,
);

export const Container = styled.div`
    width: 100%;
`;
