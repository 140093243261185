import React from 'react';

import * as S from './SelectModels.style';

interface Props {
    selectedModels: Array<string>;
    setSelectedModels: React.Dispatch<React.SetStateAction<Array<string>>>;
    setSelectedModules: React.Dispatch<React.SetStateAction<Array<string>>>;
    modelOptions: Array<string>;
    resetAnalysisList: () => void;
}

export default function SelectModels({
    selectedModels,
    setSelectedModels,
    modelOptions,
    resetAnalysisList,
    setSelectedModules,
}: Props): JSX.Element {
    const handleModelSelection = (model: string): void => {
        let newModels = [];
        if (selectedModels.includes(model)) {
            newModels = selectedModels.filter((value) => value !== model);
            setSelectedModels(newModels);
        } else {
            newModels = [...selectedModels, model];
            setSelectedModels(newModels);
        }
        setSelectedModules([]);
        if (newModels.length === 0) {
            resetAnalysisList();
        }
    };

    return (
        <S.Container>
            {modelOptions.length > 0 ? (
                <>
                    <S.RequiredField> SELECT MODELS </S.RequiredField>
                    {modelOptions.map((model) => (
                        <S.CheckListItem key={model} onClick={(): void => handleModelSelection(model)}>
                            <S.StyledCheckbox
                                checked={selectedModels.includes(model)}
                                checkedIcon={<S.CheckedIcon />}
                            />
                            {model}
                        </S.CheckListItem>
                    ))}
                </>
            ) : (
                <>SELECT PLATFORM AND PRODUCT</>
            )}
        </S.Container>
    );
}
