import React, { useState, Dispatch, SetStateAction } from 'react';
import { FirstLevelListItem, SecondLevelListMap, Item } from '../../DoublyNestedCheckList.types';
import Expand from '@material-ui/icons/Add';
import Collapse from '@material-ui/icons/Remove';
import * as S from './FirstLevelList.style';
import SecondLevelList from '../SecondLevelList/SecondLevelList';

export interface Props {
    firstLevelListItem: FirstLevelListItem;
    selectedValues: Array<Item>;
    selectedFirstLevelListItem: Array<string>;
    setSelectedFirstLevelListItem: Dispatch<SetStateAction<Array<string>>>;
    selectedSecondLevelListItem: SecondLevelListMap;
    setSelectedSecondLevelListItem: Dispatch<SetStateAction<SecondLevelListMap>>;
    setSelectedValues: Dispatch<SetStateAction<Array<Item>>>;
    firstLevelLabels: Array<string>;
}

export default function FirstLevelList({
    firstLevelListItem,
    selectedValues,
    selectedFirstLevelListItem,
    setSelectedFirstLevelListItem,
    selectedSecondLevelListItem,
    setSelectedSecondLevelListItem,
    setSelectedValues,
    firstLevelLabels,
}: Props): JSX.Element {
    const [expandedFirstLevelListItems, setExpandedFirstLevelListItems] = useState<Array<string>>([]);

    const toggleFirstLevelListItem = (listItemLabel: string): void => {
        if (expandedFirstLevelListItems.indexOf(listItemLabel) === -1) {
            setExpandedFirstLevelListItems([...expandedFirstLevelListItems, listItemLabel]);
        } else {
            setExpandedFirstLevelListItems(expandedFirstLevelListItems.filter((item) => item !== listItemLabel));
        }
    };

    const expandFirstLevelListItem = (listItemLabel: string): void => {
        if (expandedFirstLevelListItems.indexOf(listItemLabel) === -1) {
            setExpandedFirstLevelListItems([...expandedFirstLevelListItems, listItemLabel]);
        }
    };

    const handleFirstLevelListItemClick = (listItem: FirstLevelListItem): void => {
        if (!selectedFirstLevelListItem.includes(listItem.label)) {
            // When first level list item is unchecked
            setSelectedFirstLevelListItem([...selectedFirstLevelListItem, listItem.label]);
            const selectedSubItems = listItem.subItems.map((subItem) => subItem.label);
            setSelectedSecondLevelListItem((prevList) => ({ ...prevList, [listItem.label]: selectedSubItems }));
            // Add all items under the current first level item to selected
            const subListItems: Item[] = [];
            listItem.subItems.forEach((secondLevelItem) => {
                secondLevelItem.subItems.forEach((item) => {
                    if (
                        !selectedValues.find(
                            (selectedItem) =>
                                selectedItem.firstLevelListItem === listItem.label &&
                                selectedItem.secondLevelListItem === secondLevelItem.label &&
                                selectedItem.label === item.label,
                        )
                    ) {
                        subListItems.push({
                            label: item.label,
                            firstLevelListItem: listItem.label,
                            secondLevelListItem: secondLevelItem.label,
                        });
                    }
                });
            });
            setSelectedValues([...selectedValues, ...subListItems]);
        } else {
            // When first level list item is partially checked
            if (selectedSecondLevelListItem[listItem.label].length < listItem.subItems.length) {
                const selectedSubItems = listItem.subItems.map((subItem) => subItem.label);
                setSelectedSecondLevelListItem((prevList) => ({ ...prevList, [listItem.label]: selectedSubItems }));
            } else {
                // When first level list item is checked
                setSelectedFirstLevelListItem(selectedFirstLevelListItem.filter((value) => value !== listItem.label));
                setSelectedSecondLevelListItem((prevList) => ({ ...prevList, [listItem.label]: [] }));
                setSelectedValues(selectedValues.filter((item) => item.firstLevelListItem !== listItem.label));
            }
        }
        expandFirstLevelListItem(listItem.label);
    };

    const countItemsInFirstLevelList = (firstLevelListItem: FirstLevelListItem): number => {
        return firstLevelListItem.subItems.reduce((acc, item) => {
            return acc + item.subItems.length;
        }, 0);
    };

    return (
        <div key={firstLevelListItem.label}>
            <S.ListItem>
                <S.IconButton onClick={(): void => toggleFirstLevelListItem(firstLevelListItem.label)}>
                    {expandedFirstLevelListItems.includes(firstLevelListItem.label) ? <Collapse /> : <Expand />}
                </S.IconButton>
                <S.StyledCheckbox
                    onClick={(): void => handleFirstLevelListItemClick(firstLevelListItem)}
                    checkedIcon={<S.CheckedIcon />}
                    checked={selectedFirstLevelListItem.includes(firstLevelListItem.label)}
                    indeterminateIcon={<S.PartialIcon />}
                    indeterminate={
                        selectedValues.filter((item) => item.firstLevelListItem === firstLevelListItem.label).length >
                            0 &&
                        selectedValues.filter((item) => item.firstLevelListItem === firstLevelListItem.label).length <
                            countItemsInFirstLevelList(firstLevelListItem)
                    }
                />
                {firstLevelListItem.label.toUpperCase()}
            </S.ListItem>
            {expandedFirstLevelListItems.includes(firstLevelListItem.label) && (
                <SecondLevelList
                    firstLevelListItem={firstLevelListItem}
                    selectedValues={selectedValues}
                    selectedFirstLevelListItem={selectedFirstLevelListItem}
                    setSelectedFirstLevelListItem={setSelectedFirstLevelListItem}
                    selectedSecondLevelListItem={selectedSecondLevelListItem}
                    setSelectedSecondLevelListItem={setSelectedSecondLevelListItem}
                    setSelectedValues={setSelectedValues}
                    expandedFirstLevelListItems={expandedFirstLevelListItems}
                    firstLevelLabels={firstLevelLabels}
                />
            )}
        </div>
    );
}
