import styled, { css } from 'styled-components';

export const RoundButton = styled.button`
    background: #fff;
    border: none;
    outline: none;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: #000c7b;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 2px #88888888;

    &:hover {
        box-shadow: 0 1px 4px #88888888;
    }
`;

export const Container = styled.div`
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const Label = styled.div(
    ({ theme }) => css`
        margin-top: 0.4rem;
        ${theme.typography.robotoMedium08};
    `,
);
