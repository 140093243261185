import styled, { css } from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/Publish';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import LinearProgress from '@material-ui/core/LinearProgress';

export const RoundButton = styled.button`
    background: #fff;
    border: none;
    outline: none;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: #000c7b;
    margin-right: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 2px #88888888;

    &:hover {
        box-shadow: 0 1px 4px #88888888;
    }
`;

export const Container = styled(Paper)(
    ({ theme }) => css`
        && {
            display: flex;
            width: 100%;
            height: 100%;
            overflow: hidden;
            flex-direction: column;
            justify-content: flex-start;
            align-self: flex-end;
            background-color: ${theme.colors.backgroundPrimary};
        }
    `,
);

export const PageHeader = styled.div(
    () => css`
        && {
            display: flex;
            justify-content: space-between;
            margin: 2%;
        }
    `,
);

export const PageHeaderBody = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        color: ${theme.colors.backgroundDark};
        > div:first-child {
            display: flex;
            width: 50%;
        }
    `,
);

export const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    > div {
        margin-left: 0.25rem;
        width: 200px;
    }
`;

export const PageHeaderBodyRight = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            justify-content: flex-end;
            width: 20%;
            align-items: center;
            color: ${theme.colors.backgroundDark};
        }
    `,
);

export const CardContainer = styled.div`
    display: grid;
    height: 100%;
    overflow: auto;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 19fr 1fr;
    gap: 15px 15px;
    justify-items: stretch;
    align-items: stretch;
    padding: 15px;
`;

interface CellParams {
    gridColumnStart?: number;
    gridColumnEnd?: number;
    gridRowStart?: number;
    gridRowEnd?: number;
    secondaryBackground?: boolean;
}

export const Card = styled(Paper)(
    ({ theme }) => css`
        && {
            min-height: 0;
            overflow: auto;
            border-radius: 15px;
            padding-left: 7%;
            padding-right: 7%;
            grid-column-start: ${(props: CellParams): number | string => props.gridColumnStart || 'auto'};
            grid-column-end: ${(props: CellParams): number | string => props.gridColumnEnd || 'auto'};
            grid-row-start: ${(props: CellParams): number | string => props.gridRowStart || 'auto'};
            grid-row-end: ${(props: CellParams): number | string => props.gridRowEnd || 'auto'};
            background-color: ${(props: CellParams): string =>
                props.secondaryBackground ? theme.colors.backgroundDark : theme.colors.backgroundLight};
        }
    `,
);

export const ModuleInfoLabel = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            margin-top: 10%;
            font-size: 80%;
            font-weight: lighter;
        }
    `,
);

export const InputStatsContainer = styled(Typography)(
    () => css`
        && {
            display: flex;
            height: 50%;
            flex-direction: column;
            justify-content: space-evenly;
        }
    `,
);

export const PaginationContainer = styled(Box)(
    ({ theme }) => css`
        && {
            min-height: 0;
            overflow: auto;
            border-radius: 15px;
            padding-right: 7%;
            margin-top: -1%;
            background-color: transparent;
        }
    `,
);

export const TableCard = styled(Paper)(
    ({ theme }) => css`
        && {
            min-height: 0;
            overflow: auto;
            height: 100%;
            border-radius: 15px;
            grid-column-start: ${(props: CellParams): number | string => props.gridColumnStart || 'auto'};
            grid-column-end: ${(props: CellParams): number | string => props.gridColumnEnd || 'auto'};
            grid-row-start: ${(props: CellParams): number | string => props.gridRowStart || 'auto'};
            grid-row-end: ${(props: CellParams): number | string => props.gridRowEnd || 'auto'};
            background-color: ${(props: CellParams): string =>
                props.secondaryBackground ? theme.colors.backgroundDark : theme.colors.backgroundLight};
        }
    `,
);

export const FileIOContainer = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            flex-direction: row;
            height: 10%;
            border-bottom: 1px solid grey;
            align-items: center;
        }
    `,
);

export const ActionText = styled(Typography)(
    ({ theme }) => css`
        && {
            font-size: 1rem;
            font-weight: normal;
            color: tomato;
        }
    `,
);

export const StyledUploadIcon = styled(UploadIcon)(
    ({ theme }) => css`
        color: ${theme.colors.backgroundLight};
        height: 3rem;
        width: 3rem;
        margin-right: 1.5rem;
        cursor: pointer;
    `,
);

export const FileNameText = styled(Typography)(
    ({ theme }) => css`
        font-size: 0.8rem;
        font-weight: lighter;
        color: ${theme.colors.greyScaleDark};
    `,
);

export const FormControlActions = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            height: 10%;
            align-items: center;
            padding-right: 0%;
        }
    `,
);

export const FormSaveText = styled(Typography)(
    ({ theme }) => css`
        font-size: 1rem;
        width: 20%;
        margin-top: 20%;
        font-weight: normal;
        color: tomato;
        cursor: pointer;
    `,
);

export const FormResetText = styled(Typography)(
    ({ theme }) => css`
        font-size: 1rem;
        width: 20%;
        margin-top: 20%;
        font-weight: normal;
        color: ${theme.colors.greyScaleDark};
        cursor: pointer;
    `,
);

export const StyledDownloadIcon = styled(DownloadIcon)(
    ({ theme }) => css`
        color: ${theme.colors.backgroundLight};
        height: 3rem;
        width: 3rem;
        margin-right: 1.5rem;
        cursor: pointer;
    `,
);

export const StyledProgressBar = styled(LinearProgress)(
    ({ theme }) => css`
        color: ${theme.colors.backgroundLight};
        margin-top: 1%;
        border-radius: 10px;
        .MuiLinearProgress-barColorPrimary {
            background-color: ${theme.colors.backgroundLight};
        }
        &.MuiLinearProgress-colorPrimary {
            background-color: ${theme.colors.greyScaleDark};
        }
    `,
);

export const UploadContainer = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            flex-direction: row;
            height: 10%;
            align-items: center;
        }
    `,
);

export const Divider = styled.div(
    ({ theme }) => css`
        && {
            border-bottom: 1px solid grey;
        }
    `,
);

export const ProgressBarContainer = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid grey;
        }
    `,
);

export const StyledErrorText = styled.div(
    ({ theme }) => css`
        && {
            color: red;
            ${theme.typography.robotoMedium12}
        }
    `,
);

export const ModalBody = styled.div(
    () => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 5%;
        > div {
            width: 45%;
        }
    `,
);
