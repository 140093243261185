import { Action } from './store.types';

const actionCreator = (actionType: string) => (payload: any): Action => {
    return {
        type: actionType,
        payload,
    };
};

export const SET_MODULES = 'SET_MODULES';

export const setModules = actionCreator(SET_MODULES);
