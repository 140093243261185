import { createGlobalStyle, FlattenSimpleInterpolation } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props): string => props.theme.colors.backgroundPage};
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${(props): FlattenSimpleInterpolation => props.theme.typography.robotoRegular16};
    color: ${(props): string => props.theme.colors.textLight};
  }
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 1264px;
    @media screen and (max-width: 1263px) {
      width: 100vw;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
  }
`;
