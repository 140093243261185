import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { Platform } from '../../types/hierarchies.types';
import Title from '../../components/Title/Title';
import CircularButton, { CircularButtonType } from '../../components/CircularButton/CircularButton';
import AnalysisSelector from './components/AnalysisSelector/AnalysisSelector';
import { Item } from '../../components/DoublyNestedCheckList/DoublyNestedCheckList.types';
import {
    getAnalysisListByVerticalHierarchyConfig,
    GetAnalysisListByVerticalHierarchyResponse,
    getHierarchyPLDataConfig,
    GetHierarchyDataResponse,
    getAnalysisByIdConfig,
    GetAnalysisByIdResponse,
    newAnalysisExportConfig,
} from '../../constants/apiConfig';
import { formatAnalysisDataForNestedList, getAggregatedAnalysisData, formatForExport } from './ModuleExplorer.utils';
import { downloadExcel } from '../../utils/fileUtils';
import AnalysisChart from '../AnalysisExplorer/components/AnalysisChart/AnalysisChart';
import AnalysisMetrics from '../AnalysisExplorer/components/AnalysisMetrics/AnalysisMetrics';

import * as S from './ModuleExplorer.style';

export type AggregatedAnalysisData = {
    tooling_investment: number;
    design_investment: number;
    line_investment: number;
    other_investment: number;
    variant_reduction_result: number;
    dmc_result: number;
    scale_benefit_result: number;
    sunk_cost: number;
    cash_flow_release_result: number;
    effiency_enhancement_result: number;
    effiency_enhancement_hours_result: number;
    inventory_cost: number;
    warranty_cost: number;
    rejection_cost: number;
    logistics_cost: number;
    payback_period: number;
    npv: number;
    irr: number;
};

export default function AnalysisExplorer(): JSX.Element {
    const history = useHistory();

    const [analysisList, getAnalysisList] = useFetch<GetAnalysisListByVerticalHierarchyResponse>(
        getAnalysisListByVerticalHierarchyConfig,
    );
    const [hierarchyList, getHierarchyList] = useFetch<GetHierarchyDataResponse>(getHierarchyPLDataConfig);
    const [analysisDetails, , getAnalysisDetails] = useFetch<GetAnalysisByIdResponse>(getAnalysisByIdConfig);
    const [exportExcelStatus, exportExcelPost, , exportExcelReset] = useFetch<Blob>(newAnalysisExportConfig);

    const [selectedPlatform, setSelectedPlatform] = useState<Platform>();
    const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);
    const [selectedModels, setSelectedModels] = useState<Array<string>>([]);
    const [selectedAnalyses, setSelectedAnalyses] = useState<Array<Item>>([]);

    useEffect(() => {
        getHierarchyList();
    }, [getHierarchyList]);

    useEffect(() => {
        if (selectedPlatform && selectedProducts.length > 0 && selectedModels.length > 0) {
            const params = {
                platform: selectedPlatform.label,
                product: selectedProducts.toString(),
                models: selectedModels.toString(),
            };
            getAnalysisList({ params });
        }
    }, [selectedPlatform, selectedProducts, selectedModels, getAnalysisList]);

    useEffect(() => {
        if (selectedAnalyses) {
            const routeVariables = selectedAnalyses.map((analysis) => ({
                pathParams: { id: analysis.label },
            }));
            getAnalysisDetails(routeVariables);
        }
    }, [selectedAnalyses, getAnalysisDetails]);

    useEffect(() => {
        if (exportExcelStatus.loading === false && exportExcelStatus.data) {
            downloadExcel(exportExcelStatus.data, `Analysis-${selectedAnalyses[0].label}-Summary.xlsx`);
            exportExcelReset();
        }
    }, [exportExcelStatus.loading, exportExcelStatus.data, selectedAnalyses, exportExcelReset]);

    const filteredAnalysisList = analysisList.data?.filter((analysis) => analysis.status === 'SUBMITTED');

    const selectedAnalysesDetails =
        analysisList?.data?.filter((item) =>
            selectedAnalyses.find((analysis) => analysis.label === item?.analysis_id?.toString()),
        ) ?? [];

    const anyVerticalHierarchiesNull = (): boolean =>
        !selectedPlatform || selectedProducts.length === 0 || selectedModels.length === 0;

    const formattedAnalysisList =
        filteredAnalysisList && !anyVerticalHierarchiesNull()
            ? formatAnalysisDataForNestedList(filteredAnalysisList ?? [])
            : undefined;

    const aggregatedAnalysisData =
        anyVerticalHierarchiesNull() || selectedAnalyses.length === 0
            ? undefined
            : getAggregatedAnalysisData(selectedAnalysesDetails ?? []);

    const handleExport = (): void => {
        const firstAnalysis = analysisDetails?.dataAll?.[0];
        if (selectedAnalysesDetails?.length > 0) {
            const analysisDetailsRes: GetAnalysisByIdResponse = selectedAnalysesDetails[0];
            const data = formatForExport({
                ...analysisDetailsRes,
                partsList: firstAnalysis?.partsList ?? [],
                id: firstAnalysis?.id ?? '',
                originator: firstAnalysis?.originator ?? '',
                operating_mode: firstAnalysis?.operating_mode ?? '',
                additional_info: firstAnalysis?.additional_info ?? '',
                detailed_description: firstAnalysis?.detailed_description ?? '',
                impact_on_other_modules: firstAnalysis?.impact_on_other_modules ?? '',
            });
            exportExcelPost({ data });
        }
    };

    const platformList = hierarchyList.data?.platforms ?? [];

    return (
        <S.Container>
            <S.TitleContainer>
                <Title title="Explore Modules" showBackButton handleBackButtonClick={(): void => history.goBack()} />
                <S.ActionButtonContainer>
                    {selectedAnalyses.length === 1 && hierarchyList?.data && (
                        <CircularButton
                            variant={CircularButtonType.EXPORT}
                            onClickHandler={handleExport}
                            label="EXPORT"
                        />
                    )}
                </S.ActionButtonContainer>
            </S.TitleContainer>
            <S.BodyContainer>
                <AnalysisSelector
                    data={formattedAnalysisList}
                    loading={hierarchyList.loading || analysisList.loading}
                    error={!!hierarchyList.error}
                    selectedAnalysis={selectedAnalyses}
                    setSelectedAnalysis={setSelectedAnalyses}
                    platforms={platformList}
                    selectedPlatform={selectedPlatform}
                    setSelectedPlatform={setSelectedPlatform}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    selectedModels={selectedModels}
                    setSelectedModels={setSelectedModels}
                />
                <AnalysisChart
                    data={aggregatedAnalysisData}
                    loading={analysisList.loading}
                    error={!!analysisList.error}
                />
                <AnalysisMetrics
                    data={aggregatedAnalysisData}
                    loading={analysisList.loading}
                    error={!!analysisList.error}
                />
            </S.BodyContainer>
        </S.Container>
    );
}
