import styled, { css } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

export const ProjectTooltip = styled(Tooltip)``;

export const Container = styled.span(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    `,
);

export const Section = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoRegular14};
        color: ${theme.colors.textDark};
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        > span:first-child {
            ${theme.typography.robotoMedium10};
            color: ${theme.colors.subtextDark};
            flex-basis: 100%;
            margin-top: 1.5rem;
        }
        > span {
            flex-basis: 50%;
            margin-top: 1rem;
        }
    `,
);

export const Title = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.textDark};
        width: 100%;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid ${theme.colors.subtextDark};
    `,
);
