import React, { useState } from 'react';
import {
    StyledDialogContent,
    StyledButton,
    DialogActions,
    PartsContainer,
    PartsBox,
    PartsBoxTitle,
    StyledTableCell,
    StyledTableRow,
    TableSubHeader,
    StyledCheckbox,
    PartNumberCell,
    SearchContainer,
} from './AnalysisDetailsModal.style';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { PartsDetails } from '../../NewAnalysis.types';
import { FormControl, Select, Chip, Input, MenuItem, ListItemText } from '@material-ui/core';
import Modal from '../../../../components/Modal/Modal';
import TextInput from '../../../../components/TextInput/TextInput';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    formControl: {
        margin: 1,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    listItemText: {
        fontSize: '0.7em',
    },
});

interface TableProps {
    selectedParts: string[];
    handleInputChange: any;
    partsMapping: PartsDetails;
    tableType: 'current' | 'proposed';
    selectedVerticalLinks: { [key: string]: string[] };
}

function PartsTable({
    selectedParts,
    handleInputChange,
    tableType,
    partsMapping,
    selectedVerticalLinks,
}: TableProps): JSX.Element {
    const tableHeadValues = [
        'Vertical Links',
        'Part No.',
        'Cost',
        'Volume',
        'Inventory',
        'Parts Obsolescence',
        'Num Units/Bin',
        'Pass Rate',
        'Scrap%',
        'Warranty Instances',
        'Avg. Cost/instances',
        'Inbound Logistics Cost',
        'IntraPlant Logistics Cost',
        'Rework Time Per Work',
        '',
    ];
    const tableSubHeader = ['', '', 'INR/UNIT', '', 'AVERAGE DAYS', '', '', '', '', '', '', '', '', ''];
    if (tableType === 'proposed') {
        tableHeadValues.shift();
        tableSubHeader.shift();
    }
    const tableBodyKeys = [
        'cost',
        'volume',
        'inventoryDays',
        'partObsolescence',
        'numUnitsBin',
        'firstPassRate',
        'scrap',
        'warrantyInstances',
        'avgCostPerInstance',
        'inboundLogisticsCost',
        'intraPlantLogisticsCost',
        'reworkTimePerWork',
    ];
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 200,
                fontSize: '0.7em',
            },
        },
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {tableHeadValues.map((title, index) => {
                            return (
                                <StyledTableCell key={index} align="left">
                                    <div>{title}</div>
                                    <TableSubHeader>{tableSubHeader[index]}</TableSubHeader>
                                </StyledTableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedParts.map((part: any, index: number) => (
                        <StyledTableRow key={tableType + index}>
                            {tableType === 'current' ? (
                                part ? (
                                    <StyledTableCell key={tableType + index + 'index'} align="left">
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                disabled={partsMapping[part].isUserAdded}
                                                labelId="demo-mutiple-chip-label"
                                                id="demo-mutiple-chip"
                                                multiple
                                                value={selectedVerticalLinks[part]}
                                                onChange={(e): void => handleInputChange(e, part)}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={(selected: any) => (
                                                    <div className={classes.chips}>
                                                        {selected.map((value: any) => {
                                                            const verticalLink = partsMapping[
                                                                part
                                                            ]?.verticalLinks?.find(
                                                                (item) => JSON.stringify(item) === value,
                                                            );

                                                            return (
                                                                <Chip
                                                                    key={value}
                                                                    label={
                                                                        verticalLink?.platform +
                                                                        ', ' +
                                                                        verticalLink?.product +
                                                                        ', ' +
                                                                        verticalLink?.model +
                                                                        ', ' +
                                                                        verticalLink?.variant
                                                                    }
                                                                    className={classes.chip}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {partsMapping[part]?.verticalLinks?.map((verticalLink) => (
                                                    <MenuItem
                                                        key={verticalLink.platform + verticalLink.product}
                                                        value={JSON.stringify(verticalLink)}
                                                    >
                                                        <StyledCheckbox
                                                            checked={
                                                                selectedVerticalLinks[part].indexOf(
                                                                    JSON.stringify(verticalLink),
                                                                ) > -1
                                                            }
                                                        />
                                                        <ListItemText
                                                            classes={{ primary: classes.listItemText }}
                                                            primary={
                                                                verticalLink.platform +
                                                                ', ' +
                                                                verticalLink.product +
                                                                ', ' +
                                                                verticalLink.model +
                                                                ', ' +
                                                                verticalLink.variant
                                                            }
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </StyledTableCell>
                                ) : (
                                    <StyledTableCell key={tableType + index + 'index'} align="left"></StyledTableCell>
                                )
                            ) : (
                                ''
                            )}
                            <PartNumberCell isCustom={partsMapping[part]?.isUserAdded} key={'partNo'} align="left">
                                {part}
                            </PartNumberCell>
                            {tableBodyKeys.map((fieldName) => {
                                return (
                                    <StyledTableCell key={fieldName} align="left">
                                        {partsMapping[part] ? partsMapping[part][fieldName] : ''}
                                    </StyledTableCell>
                                );
                            })}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

interface Props {
    handleClose: () => void;
    open: boolean;
    onConfirm: (selectedVerticalLinks: { [key: string]: string[] }) => void;
    selectedParts: Array<{ from: string; to: string }>;
    partsMapping: PartsDetails;
    prevSelectedVerticalLinks: { [key: string]: string[] };
    showSnackbar: (state: { isOpen: boolean; message: string }) => void;
}

export default function AnalysisDetailsModal({
    handleClose,
    open,
    onConfirm,
    selectedParts,
    partsMapping,
    prevSelectedVerticalLinks,
    showSnackbar,
}: Props): JSX.Element {
    const [selectedVerticalLinks, setSelectedVerticalLinks] = useState<{ [key: string]: string[] }>({});
    const [partsData, setPartsData] = useState<PartsDetails>(partsMapping);
    const [searchText, setSearchText] = useState('');

    React.useEffect(() => {
        setSelectedVerticalLinks(prevSelectedVerticalLinks);
        setPartsData(partsMapping);
    }, [prevSelectedVerticalLinks, partsMapping]);

    const partsDataWithVolume = Object.keys(partsData).reduce((acc, curr) => {
        const selectedHierarchies = selectedVerticalLinks[curr];
        const volume = selectedHierarchies
            ? selectedHierarchies.reduce((acc, curr) => acc + JSON.parse(curr).volume, 0)
            : partsData[curr].volume;

        return {
            ...acc,
            [curr]: {
                ...partsData[curr],
                volume,
            },
        };
    }, {});

    const handleCurrentPartChange = (event: React.ChangeEvent<{ value: number[] }>, part: string): void => {
        const selectedHierarchies = event.target.value as [];
        setSelectedVerticalLinks((currVerticalLinks: { [key: string]: string[] }) => ({
            ...currVerticalLinks,
            [part]: selectedHierarchies as [],
        }));
    };

    const validate = (): void => {
        let isValid = true;
        let errorText = '';
        Object.keys(selectedVerticalLinks).forEach((partNumber) => {
            if (
                selectedParts.find((part) => part.from === partNumber) &&
                selectedVerticalLinks[partNumber].length === 0
            ) {
                isValid = false;
                errorText = 'At least one vertical link should be selected';
            }
        });
        if (isValid) {
            onConfirm(selectedVerticalLinks);
        } else {
            showSnackbar({ isOpen: true, message: errorText });
        }
    };

    const filteredParts = selectedParts.filter(
        (part) =>
            part.from.toLowerCase().includes(searchText.toLowerCase()) ||
            part.to.toLowerCase().includes(searchText.toLowerCase()),
    );

    return (
        <Modal isOpen={open} title="ANALYSIS DETAILS" handleClose={handleClose} disableMaxWidth scroll="body">
            <StyledDialogContent>
                <SearchContainer>
                    <TextInput
                        value={searchText}
                        placeholder="Search Part"
                        handleChange={(event): void => setSearchText(event.target.value)}
                    />
                </SearchContainer>
                <PartsContainer>
                    <PartsBox>
                        <PartsBoxTitle>CURRENT</PartsBoxTitle>
                        <PartsTable
                            handleInputChange={handleCurrentPartChange}
                            selectedParts={filteredParts.map((part) => part.from)}
                            partsMapping={partsDataWithVolume}
                            tableType={'current'}
                            selectedVerticalLinks={selectedVerticalLinks}
                        />
                    </PartsBox>
                    <PartsBox>
                        <PartsBoxTitle>PROPOSED</PartsBoxTitle>
                        <PartsTable
                            handleInputChange={handleCurrentPartChange}
                            selectedParts={filteredParts.map((part) => part.to)}
                            partsMapping={partsDataWithVolume}
                            tableType={'proposed'}
                            selectedVerticalLinks={selectedVerticalLinks}
                        />
                    </PartsBox>
                </PartsContainer>
            </StyledDialogContent>
            <DialogActions>
                <StyledButton autoFocus onClick={validate} variant="contained">
                    CONFIRM
                </StyledButton>
            </DialogActions>
        </Modal>
    );
}
