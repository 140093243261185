import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

export const ListItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

export const IconButton = styled(Button)(
    ({ theme }) => css`
        padding: 0;
        color: ${theme.colors.textDark};
    `,
);

export const StyledCheckbox = styled(Checkbox)(
    ({ theme }) => css`
        color: ${theme.colors.textDark};
    `,
);

export const CheckedIcon = styled(CheckBoxIcon)(
    ({ theme }) => css`
        color: ${theme.colors.accentSecondary};
    `,
);

export const SecondLevelListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;
    width: 100%;
`;

export const PartialIcon = styled(IndeterminateCheckBoxIcon)(
    ({ theme }) => css`
        color: ${theme.colors.accentSecondary};
    `,
);
