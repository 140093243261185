import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import MLOverview from './pages/Overview/Overview';
import PLOverview from './pages/PLOverview/PLOverview';
import NewAnalysis from './pages/NewAnalysis/NewAnalysis';
import NewProject from './pages/NewProject/NewProject';
import QuickAnalysis from './pages/QuickAnalysis/QuickAnalysis';
import AnalysisSummary from './pages/AnalysisSummary/AnalysisSummary';
import ProjectSummary from './pages/ProjectSummary/ProjectSummary';
import AnalysisExplorer from './pages/AnalysisExplorer/AnalysisExplorer';
import quickAnalysisSummary from './pages/QuickAnalysisOverview/QuickAnalysisOverview';
import DataManagement from './pages/DataManagement/DataManagement';
import ProjectExplorer from './pages/ProjectExplorer/ProjectExplorer';
import Landing from './pages/Landing/Landing';
import ModuleExplorer from './pages/ModuleExplorer/ModuleExplorer';
import ClientOnboarding from './pages/ClientOnboarding/ClientOnboarding';
import { getModulesList } from './utils/apiUtil';
import Header from './components/Header/Header';
import DataView from './pages/DataView/DataView';
import UserManagement from './pages/UserManagement/UserManagement';
import { GlobalStoreContext } from './store/store.provider';
import { setModules } from './store/store.actions';
import get from 'lodash/get';
import find from 'lodash/find';
import { useAuthContext } from './context/Auth';
import { Redirect } from 'react-router';
import { LandingPath, Roles } from './constants/auth';

function App(): JSX.Element {
    const { dispatch } = useContext(GlobalStoreContext);
    const { info, logout } = useAuthContext();

    // TODO: this is not required on app load once
    // user authentication in implemented
    useEffect(() => {
        const asyncFn = async (): Promise<void> => {
            const { data } = await getModulesList(info, logout);
            const action = setModules({ modules: data.modules });
            dispatch(action);
        };
        asyncFn();
    }, [dispatch, info, logout]);

    const getRoles = (): Array<Roles> => {
        const userAssignedGroups = get(info, 'accessInfo.groups', []);
        const roles = [];
        if (userAssignedGroups.includes(Roles.SUPER_USER)) {
            roles.push(Roles.SUPER_USER);
        } else {
            roles.push(...userAssignedGroups);
        }
        return roles;
    };

    const roles = getRoles();
    return (
        <>
            <Header />
            <Switch>
                <Route
                    path="/"
                    exact
                    render={(): JSX.Element => {
                        return <Landing roles={roles} />;
                    }}
                />

                <Route path="/MLOverview" exact>
                    <MLOverview />
                </Route>
                <Route path="/PLOverview" exact>
                    <PLOverview />
                </Route>
                <Route path="/newAnalysis/:analysisId" exact>
                    <NewAnalysis />
                </Route>
                <Route path="/quickAnalysis/:analysisId" exact>
                    <QuickAnalysis />
                </Route>
                <Route path="/analysisSummary/:analysisId" exact component={AnalysisSummary} />
                <Route path="/quickAnalysisSummary/:analysisId" exact component={quickAnalysisSummary} />
                <Route path="/analysisExplorer" exact>
                    <AnalysisExplorer />
                </Route>
                <Route path="/newProject/:projectId" exact>
                    <NewProject />
                </Route>
                <Route path="/ProjectExplorer" exact>
                    <ProjectExplorer />
                </Route>
                <Route path="/moduleExplorer" exact>
                    <ModuleExplorer />
                </Route>
                <Route path="/dataView" exact>
                    <DataView roles={roles} />
                </Route>
                <Route path="/userManagement" exact>
                    <UserManagement />
                </Route>
                <Route path="/projectSummary" exact component={ProjectSummary} />
                <Route path="/dataManagement">
                    <DataManagement roles={roles} />
                </Route>
                <Route path="/home" component={ClientOnboarding} />
                <Route path="/landing" component={Landing} />
                <Route
                    path="/auth/callback"
                    render={(): JSX.Element => {
                        const roles = getRoles();
                        let defaultLandingPath = '/';
                        if (roles.length === 1) {
                            defaultLandingPath = find(LandingPath, ['role', roles[0]])?.path ?? '/';
                        }
                        return <Redirect to={defaultLandingPath} />;
                    }}
                />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}

export default App;
