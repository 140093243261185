import React, { useState, createContext, useContext, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomLoginHeader from '../components/CustomLoginHeader/CustomLoginHeader';
import { LoginComponent } from '@mid/react-widget/redirect';
import { Mid as MidType } from '@mid/sdk';

export const AuthContext = createContext({} as { isAuthed: string; info: any; logout: Function });

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children, mid }: { children: JSX.Element; mid: MidType }) => {
    const [state, setState] = useState({
        isAuthed: 'LOADING',
        info: null,
    } as { isAuthed: string; info: any });

    const checkIfAuth = async () => {
        try {
            const isAuthed = await mid.isAuthed();
            setState((prev) => ({ ...prev, isAuthed: isAuthed ? 'AUTHED' : 'NOT_AUTHED' }));
        } catch (err) {
            setState({ isAuthed: 'NOT_AUTHED', info: null });
        }
    };

    const login = async () => {
        try {
            const info = await mid.login();
            setState((prev) => ({ ...prev, info: info }));
            checkIfAuth();
        } catch (err) {
            setState((prev) => ({ ...prev, isAuthed: 'NOT_AUTHED', info: null }));
        }
    };

    const logout = async () => {
        try {
            await mid.logout();
            checkIfAuth();
        } catch (err) {
            setState({ isAuthed: 'NOT_AUTHED', info: null });
        }
    };

    useEffect(() => {
        checkIfAuth();
        login();
        //eslint-disable-next-line
    }, []);

    const renderApp = (children: JSX.Element) => {
        const authState =
            state.isAuthed === 'LOADING' || (state.isAuthed === 'AUTHED' && state.info === null)
                ? 'LOADING'
                : state.isAuthed;
        switch (authState) {
            case 'LOADING':
                return <CircularProgress />;
            case 'NOT_AUTHED':
                return <LoginComponent mid={mid} customHeader={() => <CustomLoginHeader />} />;
            case 'AUTHED':
                return children;
            default:
                return <div>something went wrong</div>;
        }
    };
    return <AuthContext.Provider value={{ ...state, logout }}>{renderApp(children)}</AuthContext.Provider>;
};
