import React, { ReactNode, Dispatch, SetStateAction } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Platform } from '../../../../types/hierarchies.types';
import Select from '../../../../components/Select/Select';
import MultiSelect from '../../../../components/MultiSelect/MultiSelect';
import NestedCheckList, { ListItem } from '../../../../components/DoublyNestedCheckList/DoublyNestedCheckList';
import { Item } from '../../../../components/DoublyNestedCheckList/DoublyNestedCheckList.types';

import * as S from './AnalysisSelector.style';

interface Props {
    data: Array<ListItem> | undefined;
    loading: boolean;
    error: boolean;
    selectedAnalysis: Array<Item>;
    setSelectedAnalysis: Dispatch<SetStateAction<Array<Item>>>;
    platforms: Array<Platform> | undefined;
    selectedPlatform: Platform | undefined;
    setSelectedPlatform: (platform: Platform | undefined) => void;
    selectedProducts: Array<string>;
    setSelectedProducts: (product: Array<string>) => void;
    selectedModels: Array<string>;
    setSelectedModels: (model: Array<string>) => void;
}

export default function AnalysisSelector({
    data,
    loading,
    error,
    selectedAnalysis,
    setSelectedAnalysis,
    platforms,
    selectedPlatform,
    setSelectedPlatform,
    selectedProducts,
    setSelectedProducts,
    selectedModels,
    setSelectedModels,
}: Props): JSX.Element {
    const platformDropdownOptions = platforms?.map((module) => ({ value: module.label, label: module.label }));

    const productDropdownOptions = selectedPlatform?.products.map((product) => product.label);

    // Get all model labels under the selected products
    const modelDropdownOptions = selectedProducts.reduce((accumulator, currentValue) => {
        const selectedProduct = selectedPlatform?.products.find((product) => product.label === currentValue);
        return [...accumulator, ...selectedProduct?.models.map((model) => model.label)];
    }, [] as Array<string>);

    const handlePlatformChange = (platformLabel: string): void => {
        setSelectedProducts([]);
        setSelectedModels([]);
        setSelectedAnalysis([]);
        setSelectedPlatform(platforms?.find((platform) => platform.label === platformLabel));
    };

    const handleProductChange = (selectedProducts: Array<string>): void => {
        setSelectedModels([]);
        setSelectedAnalysis([]);
        setSelectedProducts(selectedProducts);
    };

    const handleModelChange = (selectedModels: Array<string>): void => {
        setSelectedAnalysis([]);
        setSelectedModels(selectedModels);
    };

    const renderAnalysisList = (): ReactNode => {
        if (loading) {
            return <CircularProgress />;
        }
        if (error) {
            return 'ERROR: UNABLE TO LOAD ANALYSIS LIST';
        }
        if (!data) {
            return 'SELECT A MODULE';
        }
        if (data?.length === 0) {
            return 'NO ANALYSIS FOUND';
        }

        return (
            <NestedCheckList data={data} selectedValues={selectedAnalysis} setSelectedValues={setSelectedAnalysis} />
        );
    };

    return (
        <S.Container>
            <Select
                handleChange={(event): void => handlePlatformChange(event.target.value as string)}
                value={selectedPlatform?.label ?? ''}
                label="Platform"
                items={platformDropdownOptions ?? []}
            />
            <MultiSelect
                handleChange={(event): void => handleProductChange(event.target.value as string[])}
                value={selectedProducts}
                label="Product"
                items={productDropdownOptions ?? []}
            />
            <MultiSelect
                handleChange={(event): void => handleModelChange(event.target.value as string[])}
                value={selectedModels}
                label="Model"
                items={modelDropdownOptions ?? []}
            />
            <S.AnalysisListContainer>{renderAnalysisList()}</S.AnalysisListContainer>
        </S.Container>
    );
}
