import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
    AppHeader,
    HeaderContainer,
    HeaderPrimaryText,
    UserIcon,
    AccountInfoContainer,
    BoldText,
    Logo,
} from './Header.style';
import ImageCard from '../ImageCard/ImageCard';
import CocLogo from '../../resources/logo.png';
import { useHistory } from 'react-router';
import { useAuthContext } from '../../context/Auth';
import { useFetch } from '../../hooks/useFetch';
import { getClientDetailsConfig, GetClientDetailsResponse } from '../../constants/apiConfig';

export default function MenuAppBar(): JSX.Element {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { info, logout } = useAuthContext();
    const [getClientStatus] = useFetch<GetClientDetailsResponse>(getClientDetailsConfig, true);

    const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <HeaderContainer>
            <AppHeader position="static">
                <Toolbar>
                    <Logo onClick={(): void => history.push('/')}>
                        <ImageCard imageSource={CocLogo} />
                    </Logo>
                    <HeaderPrimaryText variant="subtitle1">Cost of Complexity</HeaderPrimaryText>
                    <AccountInfoContainer>
                        <HeaderPrimaryText variant="subtitle2">
                            Welcome Back! <BoldText>{info?.idInfo?.name ?? ''}</BoldText>
                        </HeaderPrimaryText>
                        <UserIcon
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                        >
                            <AccountCircle />
                        </UserIcon>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={(): void => {
                                    logout();
                                }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </AccountInfoContainer>
                    <Logo onClick={(): void => history.push('/')}>
                        <ImageCard imageSource={getClientStatus.data?.client?.logo ?? ''} />
                    </Logo>
                </Toolbar>
            </AppHeader>
        </HeaderContainer>
    );
}
