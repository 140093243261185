import React, { useState } from 'react';
import get from 'lodash/get';
import { GetHierarchyDataResponse } from '../../../../constants/apiConfig';
import Modal from '../../../../components/Modal/Modal';
import Select from '../../../../components/Select/Select';
import TextInput from '../../../../components/TextInput/TextInput';
import Button, { ButtonType } from '../../../../components/Button/Button';

import {
    partDataSelectConfig,
    partDataConfig,
    advancedOperatingModeConfig,
    sparePartConfig,
    PartDetails,
    initialPartDetails,
    HierarchiesSelection,
} from './AddPartsModal.config';

import * as S from './AddPartModal.style';

interface Props {
    isOpen: boolean;
    isAdvanced: boolean;
    handleClose: () => void;
    handleAddPart: (partDetails: PartDetails) => void;
    hierarchyData: GetHierarchyDataResponse | undefined;
}

export default function AddPartsModal({
    isAdvanced,
    isOpen,
    handleClose,
    handleAddPart,
    hierarchyData,
}: Props): JSX.Element {
    const [partDetails, setPartDetails] = useState<PartDetails>(initialPartDetails);
    const [selectedIndices, setSelectedIndices] = useState<HierarchiesSelection>({});
    const [showValidation, setShowValidation] = useState<boolean>(false);

    const handleChange = (key: string) => (event: React.ChangeEvent<{ value: unknown }>): void => {
        const value = event.target.value;
        setPartDetails((previousData) => ({ ...previousData, [key]: value }));
    };

    // separating this to incorporate the progressive hierarchy selection
    const handleHierarchyChange = (key: string, hierarchy: string) => (
        event: React.ChangeEvent<{ value: any }>,
    ): void => {
        const index = event.target.value;
        const value = (get(hierarchyData, key, [])[index] as any).label as string;
        setPartDetails((previousData) => ({ ...previousData, [hierarchy]: value }));
        setSelectedIndices({ ...selectedIndices, [hierarchy]: index });
    };

    const handleAddButtonClick = (): void => {
        setShowValidation(true);

        if (partDataSelectConfig.find((config) => partDetails[config.key] === '')) {
            return;
        }
        if (partDataConfig.find((config) => partDetails[config.key] === '')) {
            return;
        }
        if (isAdvanced && advancedOperatingModeConfig.find((config) => partDetails[config.key] === '')) {
            return;
        }
        handleAddPart(partDetails);
        setPartDetails(initialPartDetails);
        setShowValidation(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            title="New Part"
            handleClose={(): void => {
                setPartDetails(initialPartDetails);
                setShowValidation(false);
                handleClose();
            }}
            disableMaxWidth
            scroll="body"
        >
            <S.Container>
                <S.SectionTitle>Please enter details of the new part(All fields are required)</S.SectionTitle>
                <S.PartDetails>
                    {partDataSelectConfig.map((configItem) => (
                        <div key={configItem.key}>
                            <Select
                                items={(get(hierarchyData, configItem.pathToOptions(selectedIndices), []) as Array<
                                    any
                                >)?.map((option: { label: string }, index: number) => ({
                                    label: option.label,
                                    value: index,
                                }))}
                                handleChange={handleHierarchyChange(
                                    configItem.pathToOptions(selectedIndices),
                                    configItem.key,
                                )}
                                label={configItem.label}
                                value={selectedIndices[configItem.key as keyof HierarchiesSelection]}
                            />
                            {showValidation && partDetails[configItem.key as keyof PartDetails] === '' && (
                                <S.Validation>Required!</S.Validation>
                            )}
                        </div>
                    ))}
                    {partDataConfig.map((configItem) => (
                        <TextInput
                            key={configItem.key}
                            handleChange={handleChange(configItem.key)}
                            label={configItem.label}
                            placeholder={configItem.placeholder}
                            value={partDetails[configItem.key as keyof PartDetails]}
                            type={configItem.type}
                            error={
                                showValidation && partDetails[configItem.key as keyof PartDetails] === ''
                                    ? 'Required!'
                                    : ''
                            }
                        />
                    ))}
                </S.PartDetails>
                {isAdvanced && (
                    <S.PartDetails>
                        <S.SectionTitle>ADVANCE OPERATING MODEL</S.SectionTitle>
                        {advancedOperatingModeConfig.map((configItem) => (
                            <TextInput
                                key={configItem.key}
                                handleChange={handleChange(configItem.key)}
                                label={configItem.label}
                                placeholder={configItem.placeholder}
                                value={partDetails[configItem.key as keyof PartDetails]}
                                type={configItem.type}
                                error={
                                    showValidation && partDetails[configItem.key as keyof PartDetails] === ''
                                        ? 'Required!'
                                        : ''
                                }
                            />
                        ))}
                    </S.PartDetails>
                )}
                <S.SparePartsDetails>
                    {['FIRST' /*, 'SECOND', 'THIRD', 'FOURTH', 'FIFTH'*/].map((key, index) => (
                        <>
                            <S.SparePartSectionTitle>{`${key} SPARE PART`}</S.SparePartSectionTitle>
                            {sparePartConfig.map((configItem) => (
                                <TextInput
                                    key={`${configItem.key}${key}`}
                                    handleChange={handleChange(`${configItem.key}${index}`)}
                                    label={configItem.label}
                                    placeholder={configItem.placeholder}
                                    type={configItem.type}
                                    disabled
                                />
                            ))}
                        </>
                    ))}
                </S.SparePartsDetails>
                <S.ButtonContainer>
                    <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleAddButtonClick}>
                        ADD PART
                    </Button>
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
}
