import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const MetricValue = styled(Typography)<{ dark: boolean; distinctColor?: boolean }>(
    ({ dark = false, distinctColor = false, theme }) => css`
        ${theme.typography.robotoBold18};
        color: ${distinctColor ? theme.colors.textSalmon : dark ? theme.colors.textDark : theme.colors.textLight};
        margin-bottom: 2px;
    `,
);

export const MetricLabel = styled(Typography)<{
    dark: boolean;
    uppercase?: boolean;
    showUnitMarker?: boolean;
    distinctColor?: boolean;
}>(
    ({ dark = false, theme, uppercase = false, showUnitMarker = false, distinctColor = false }) => css`
        ${theme.typography.robotoRegular12};
        color: ${distinctColor ? theme.colors.textSalmon : dark ? theme.colors.subtextDark : theme.colors.subtextLight};
        text-transform: ${uppercase ? 'uppercase' : 'none'};

        /* Tried doing it the same way as text-transform but not working with ::after */
        ${showUnitMarker === true
            ? `::after {
            content: ' *';
            font-size: 1.1rem;
            vertical-align: middle;
        }`
            : null};
    `,
);

export const Metric = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    `,
);
