import React from 'react';
import { StyledSelect, SelectContainer, Label, StyledMenuItem, Container } from './MultiSelect.style';

export interface SelectItem {
    value: number | string;
    label: string;
}

interface Props {
    items: string[];
    handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    value?: number[] | string[];
    label: string;
    disabled?: boolean;
}

export default function SimpleSelect({ items, handleChange, value, label, disabled = false }: Props): JSX.Element {
    return (
        <Container>
            <SelectContainer variant="outlined" size="small" disabled={disabled}>
                <Label id="select-label">{label}</Label>
                <StyledSelect
                    labelId="select-label"
                    value={value}
                    onChange={handleChange}
                    multiple
                    renderValue={(selected: unknown): string => (selected as string[]).join(', ')}
                    MenuProps={{ variant: 'menu', getContentAnchorEl: null }}
                >
                    {items.map((item) => (
                        <StyledMenuItem key={item} value={item}>
                            {item}
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
            </SelectContainer>
        </Container>
    );
}
