import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Module } from '../../../../types/modules.types';
import Select from '../../../../components/Select/Select';
import NestedCheckList, { ListItem } from '../../../../components/NestedCheckList/NestedCheckList';

import * as S from './AnalysisSelector.style';

interface Props {
    data: Array<ListItem> | undefined;
    loading: boolean;
    error: boolean;
    modules: Array<Module>;
    selectedModule: Module | undefined;
    setSelectedModule: (module: Module | undefined) => void;
    selectedAnalysis: Array<string>;
    setSelectedAnalysis: (ids: Array<string>) => void;
    isPL?: boolean;
}

export default function AnalysisSelector({
    data,
    loading,
    error,
    modules,
    selectedModule,
    setSelectedModule,
    selectedAnalysis,
    setSelectedAnalysis,
    isPL = false,
}: Props): JSX.Element {
    const moduleDropdownOptions = modules.map((module) => ({ value: module.label, label: module.label }));
    const handleModuleChange = (moduleLabel: string): void =>
        setSelectedModule(modules.find((module) => module.label === moduleLabel));

    const renderAnalysisList = (): ReactNode => {
        if (loading) {
            return <CircularProgress />;
        }
        if (error) {
            return `ERROR: UNABLE TO LOAD ${isPL ? 'PROJECT' : 'ANALYSIS'} LIST`;
        }
        if (!data) {
            return `SELECT A ${isPL ? 'PLATFORM' : 'MODULE'}`;
        }
        if (data?.length === 0) {
            return `NO ${isPL ? 'PROJECT' : 'ANALYSIS'} FOUND`;
        }

        return (
            <NestedCheckList data={data} selectedValues={selectedAnalysis} setSelectedValues={setSelectedAnalysis} />
        );
    };

    return (
        <S.Container>
            <Select
                handleChange={(event): void => handleModuleChange(event.target.value as string)}
                value={selectedModule?.label ?? ''}
                label={isPL ? 'PLATFORM' : 'MODULE'}
                items={moduleDropdownOptions}
            />
            <S.AnalysisListContainer>{renderAnalysisList()}</S.AnalysisListContainer>
        </S.Container>
    );
}
