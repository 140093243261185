import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
        height: 100%;
        width: 100%;
    `,
);

export const Image = styled.img(
    () => css`
        height: 100%;
        width: 100%;
        object-fit: contain;
    `,
);
