import styled, { css } from 'styled-components';
import { columnSpacing } from '../PartsSelection.style';

export const HandlerButtonContainer = styled.div(
    () => css`
        position: absolute;
        right: 0;
        bottom: 0.2rem;
        padding: 0.1rem;
        display: none;
    `,
);

export const HandlerButton = styled.button(
    ({ theme }) => css`
        position: relative;
        border: none;
        outline: none;
        border-right: 1px solid ${theme.colors.greyScaleMedium};
        background: ${theme.colors.greyScaleLight};
        cursor: pointer;
        flex: 1;

        &:first-child {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }

        &:last-child {
            border: none;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    `,
);

export const PartBlank = styled.button<{ isCustom: boolean }>(
    ({ theme, isCustom }) => css`
        ${theme.typography.robotoRegular14};
        ${columnSpacing};
        margin-top: 1.5rem;
        flex: 1;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        text-align: left;
        padding: 0.25rem;
        position: relative;
        color: ${isCustom ? theme.colors.accentPrimary : null};
        border-bottom: 1px solid ${theme.colors.greyScaleMedium};

        &:hover {
            border-bottom: 1px solid ${theme.colors.greyScaleDark};
        }

        &:hover ${HandlerButtonContainer} {
            display: flex;
        }
    `,
);
