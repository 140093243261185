import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        padding: 1.5rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundDark};
        > div {
            margin-bottom: 1.5rem;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    `,
);

export const SelectHeader = styled.span(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.greyScaleLight};
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        > span {
            color: ${theme.colors.accentSecondary};
        }
    `,
);
