import styled, { css, createGlobalStyle } from 'styled-components';

export const CustomHeaderContainer = styled.div(
    () => css`
        display: flex;
        padding-bottom: 1.75rem;
        align-items: center;
    `,
);

export const CustomHeader = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoLight32}
        color: ${theme.colors.textDark};
        padding-left: 0.5rem;
    `,
);

export const AppLogo = styled.img(
    () => css`
        height: 65px;
        cursor: pointer;
    `,
);

export const LoginGlobalStyle = createGlobalStyle(
    ({ theme }) => css`
        form {
            border-radius: 10px;
            background-image: none !important;
        }
        form > div:first-child {
            visibility: hidden;
        }
        button {
            background-color: ${theme.colors.accentPrimary} !important;
        }
        nav > div:first-child {
            background-color: #1e1f42 !important;
            background-image: linear-gradient(0deg, #1e1f42 0%, #40415a 100%);
        }
    `,
);
