import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const ListItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

export const SubListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 3.5rem;
    width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)(
    ({ theme }) => css`
        color: ${theme.colors.textDark};
    `,
);

export const CheckedIcon = styled(CheckBoxIcon)(
    ({ theme }) => css`
        color: ${theme.colors.accentSecondary};
    `,
);
