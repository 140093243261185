import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogContent from '@material-ui/core/DialogContent';

export const Container = styled(Box)(
    () => css`
        margin: 1rem;
    `,
);

export const StyledDialogTitle = styled(MuiDialogTitle)(
    ({ theme }) => css`
        color: ${theme.colors.backgroundDark};
        font-weight: bold;
        padding: 8px 12px;
        margin: 0;
        ${theme.typography.robotoLight24}
    `,
);

export const StyledDialog = styled(Dialog)(
    () => css`
        .MuiDialog-root {
            margin: 1em;
            box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
        }
        .MuiDialog-paper {
            padding: 15px;
            border-radius: 16px;
        }
    `,
);

export const StyledIconButton = styled(IconButton)(
    ({ theme }) => css`
        position: absolute;
        right: 1px;
        top: 1px;
        color: ${theme.colors.accentPrimary};
    `,
);

export const StyledDialogContent = styled(MuiDialogContent)(
    () => css`
        display: flex;
        flex-direction: column;
        padding: 10px;
        min-width: 40vh;
        min-height: 10vh;
    `,
);
