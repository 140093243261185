import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        border-radius: 10px;
        padding: 1.5rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        overflow: auto;
        background-color: ${theme.colors.backgroundDark};
        > div {
            margin-bottom: 1.7rem;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    `,
);

export const SensitivityTitle = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.textDark};
        margin-top: 1.5rem;
        margin-bottom: 2rem !important;
    `,
);

export const AnalysisInfo = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: ${theme.colors.textDark};
        ${theme.typography.robotoRegular12};
        > div {
            margin-top: 4%;
            ${theme.typography.robotoRegular14};
        }
    `,
);

export const Divider = styled.div(
    ({ theme }) => css`
        height: 1px;
        width: 100%;
        background-color: ${theme.colors.greyScaleMedium};
        margin-bottom: 5%;
    `,
);

export const FlexRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3.5rem !important;
    > div {
        width: 50%;
    }
`;

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
`;
