import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button, { ButtonType } from '../../../../components/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as S from './ConfirmationDialog.style';

interface Props {
    isOpen: boolean;
    email: string;
    handleConfirm: () => void;
    handleCancel: () => void;
    loading: boolean;
}

export default function ConfirmationDialog({
    isOpen,
    handleConfirm,
    handleCancel,
    email,
    loading,
}: Props): JSX.Element {
    return (
        <Modal isOpen={isOpen} title="Confirm Delete" handleClose={handleCancel}>
            {`User ${email} will be permanently deleted.`}
            <S.Container>
                <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleConfirm} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Confirm'}
                </Button>
                <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleCancel} disabled={loading}>
                    Cancel
                </Button>
            </S.Container>
        </Modal>
    );
}
