import React from 'react';
import * as S from './ProjectBoxTooltip.style';

interface Props {
    selectedModels: Array<string>;
    selectedModules: Array<string>;
    children: React.ReactElement;
    title: string;
}

export default function ProjectBoxTooltip({ selectedModels, selectedModules, title, children }: Props): JSX.Element {
    const content = (
        <S.Container>
            <S.Title>{title}</S.Title>
            {selectedModels.length > 0 && (
                <S.Section>
                    <span>IMPACTED MODEL(S)</span>
                    {selectedModels.map((model) => (
                        <span key={model}>{model}</span>
                    ))}
                </S.Section>
            )}
            {selectedModules.length > 0 && (
                <S.Section>
                    <span>IMPACTED MODULE(S)</span>
                    {selectedModules.map((module) => (
                        <span key={module}>{module}</span>
                    ))}
                </S.Section>
            )}
        </S.Container>
    );

    return (
        <S.ProjectTooltip
            title={selectedModels.length + selectedModules.length > 0 ? content : ''}
            placement="right"
            arrow
        >
            {children}
        </S.ProjectTooltip>
    );
}
