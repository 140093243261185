import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { Platform } from '../../types/hierarchies.types';
import Title from '../../components/Title/Title';
import CircularButton, { CircularButtonType } from '../../components/CircularButton/CircularButton';
import ProjectSelector from './components/ProjectSelector/ProjectSelector';
import AnalysisChart from '../AnalysisExplorer/components/AnalysisChart/AnalysisChart';
import ProjectMetrics from '../../components/ProjectMetrics/ProjectMetrics';
import {
    deleteProjectConfig,
    DeleteProjectResponse,
    GetProjectByIdResponse,
    getHierarchyPLDataConfig,
    GetHierarchyDataResponse,
    getProjectsForPlatformConfig,
    GetProjectsForPlatformResponse,
    getProjectByIdConfig,
    newProjectExportConfig,
} from '../../constants/apiConfig';
import { formatProjectDataForNestedList, getAggregatedProjectData } from './ProjectExplorer.utils';
import ConfirmationModal from '../AnalysisExplorer/components/ConfirmationDialog/ConfirmationDialog';

import * as S from './ProjectExplorer.style';
import { formatForExport } from './ProjectExplorer.utils';
import { downloadExcel } from '../../utils/fileUtils';

export type AggregatedAnalysisData = {
    tooling_investment: number;
    design_investment: number;
    line_investment: number;
    other_investment: number;
    design_investment_integration?: number;
    line_investment_integration?: number;
    other_investment_integration?: number;
    design_investment_total?: number;
    line_investment_total?: number;
    other_investment_total?: number;
    tooling_investment_total?: number;
    variant_reduction_result: number;
    dmc_result: number;
    scale_benefit_result: number;
    sunk_cost: number;
    cash_flow_release_result: number;
    effiency_enhancement_result: number;
    effiency_enhancement_hours_result: number;
    inventory_cost: number;
    warranty_cost: number;
    rejection_cost: number;
    logistics_cost: number;
    payback_period: number;
    npv: number;
    irr: number;
};

export default function ProjectExplorer(): JSX.Element {
    const history = useHistory();

    const [hierarchyList, getHierarchyList] = useFetch<GetHierarchyDataResponse>(getHierarchyPLDataConfig);
    const [projectList, getProjectList] = useFetch<GetProjectsForPlatformResponse>(getProjectsForPlatformConfig);
    const [deleteProjectStatus, deleteProject] = useFetch<DeleteProjectResponse>(deleteProjectConfig);
    const [projectDetails, , getProjectDetails] = useFetch<GetProjectByIdResponse>(getProjectByIdConfig);
    const [exportExcelStatus, exportExcelPost, , exportExcelReset] = useFetch<Blob>(newProjectExportConfig);

    const [selectedPlatform, setSelectedPlatform] = useState<Platform>();
    const [selectedProjects, setSelectedProjects] = useState<Array<string>>([]);
    const [confirmationModalState, setConfirmationModalState] = useState<boolean>(false);

    useEffect(() => {
        getHierarchyList();
    }, [getHierarchyList]);

    useEffect(() => {
        if (selectedPlatform) {
            const params = { platform: selectedPlatform.label };
            getProjectList({ params });
        }
    }, [selectedPlatform, getProjectList]);

    useEffect(() => {
        if (deleteProjectStatus.loading === false && deleteProjectStatus.data && selectedPlatform) {
            setSelectedProjects([]);
            const params = { platform: selectedPlatform.label };
            getProjectList({ params });
        }
    }, [deleteProjectStatus.data, deleteProjectStatus.loading, selectedPlatform, getProjectList]);

    useEffect(() => {
        if (selectedProjects) {
            const routeVariables = selectedProjects.map((project) => ({
                pathParams: { id: project },
            }));
            getProjectDetails(routeVariables);
        }
    }, [selectedProjects, getProjectDetails]);

    useEffect(() => {
        if (exportExcelStatus.loading === false && exportExcelStatus.data) {
            downloadExcel(exportExcelStatus.data, `ProjectAnalysis-${selectedProjects[0]}-Summary.xlsx`);
            exportExcelReset();
        }
    }, [exportExcelStatus.data, exportExcelStatus.loading, selectedProjects, exportExcelReset]);

    const handleDeleteProject = (): void => {
        const pathParams = { id: selectedProjects[0] };
        deleteProject({ pathParams });
        setConfirmationModalState(false);
    };

    const handleEditProject = (): void => {
        if (projectDetails.dataAll) {
            history.push(`/newProject/${selectedProjects[0]}`, {
                ...projectDetails.dataAll?.[0],
                isEdit: true,
            });
        }
    };

    const handleExport = (): void => {
        if (projectDetails.dataAll) {
            const projectAnalysesDetailsRes: GetProjectByIdResponse = projectDetails.dataAll[0];
            const data = formatForExport(projectAnalysesDetailsRes);
            exportExcelPost({ data });
        }
    };

    const handleConfirmationModalClose = (): void => {
        setConfirmationModalState(false);
    };

    const handleConfirmationModalOpen = (): void => {
        setConfirmationModalState(true);
    };

    const formattedProjectList = projectList.data ? formatProjectDataForNestedList(projectList.data) : undefined;

    const aggregatedProjectData = getAggregatedProjectData(projectDetails.dataAll ?? []);

    const platformList = hierarchyList.data?.platforms ?? [];

    return (
        <S.Container>
            <S.TitleContainer>
                <ConfirmationModal
                    analysisId={selectedProjects[0]}
                    isOpen={confirmationModalState}
                    handleConfirm={handleDeleteProject}
                    handleCancel={handleConfirmationModalClose}
                    isPL
                />
                <Title title="Explore Projects" showBackButton handleBackButtonClick={(): void => history.goBack()} />
                <S.ActionButtonContainer>
                    {selectedProjects.length === 1 && (
                        <CircularButton
                            variant={CircularButtonType.DELETE}
                            label="DELETE"
                            onClickHandler={handleConfirmationModalOpen}
                        />
                    )}
                    {selectedProjects.length === 1 && projectDetails?.dataAll && (
                        <CircularButton
                            variant={CircularButtonType.EDIT}
                            label="EDIT"
                            onClickHandler={handleEditProject}
                        />
                    )}
                    {selectedProjects.length === 1 && projectDetails?.dataAll && (
                        <CircularButton
                            variant={CircularButtonType.EXPORT}
                            label="EXPORT"
                            onClickHandler={handleExport}
                        />
                    )}
                </S.ActionButtonContainer>
            </S.TitleContainer>
            <S.BodyContainer>
                <ProjectSelector
                    data={formattedProjectList}
                    loading={projectList.loading}
                    error={!!projectList.error}
                    platforms={platformList}
                    selectedPlatform={selectedPlatform}
                    setSelectedPlatform={setSelectedPlatform}
                    selectedProject={selectedProjects}
                    setSelectedProject={setSelectedProjects}
                />
                <AnalysisChart
                    data={aggregatedProjectData}
                    loading={projectDetails.loading}
                    error={!!projectDetails.error}
                    isPL
                />
                <ProjectMetrics
                    data={aggregatedProjectData}
                    loading={projectDetails.loading}
                    error={!!projectDetails.error}
                />
            </S.BodyContainer>
        </S.Container>
    );
}
