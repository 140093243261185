import { ListItem } from './components/ProjectCheckList/ProjectCheckList';
import { GetProjectsForPlatformResponse, GetProjectByIdResponse } from '../../constants/apiConfig';
import { AggregatedAnalysisData } from './ProjectExplorer';
import { Finance } from 'financejs';

const finance = new Finance();

export const formatProjectDataForNestedList = (data: GetProjectsForPlatformResponse): Array<ListItem> => {
    // TODO: replace filter map with reduce
    const renamedProductList = data
        .map((product) => {
            const renamedProjectList = product.projects
                .filter((project) => project.status === 'IN_PROGRESS' || project.status === 'SUBMITTED')
                .map((project) => {
                    return {
                        label: project?.id?.toString(),
                        value: project?.id?.toString(),
                        modelCount: project?.models_count,
                        moduleCount: project?.modules_count,
                        title: project?.title,
                        status: project?.status,
                    };
                });
            return { label: product.product, subItems: renamedProjectList };
        })
        .filter((product) => product.subItems?.length > 0);
    return renamedProductList;
};

export const getAggregatedProjectData = (data: Array<GetProjectByIdResponse>): AggregatedAnalysisData | undefined => {
    if (data.length === 0) {
        return undefined;
    }
    const aggregate = data.reduce(
        (accumulator, currentValue) => {
            const newData = { ...accumulator };
            (Object.keys(accumulator) as Array<keyof typeof accumulator>).forEach((key) => {
                newData.tooling_investment =
                    accumulator.tooling_investment + (currentValue.tooling_investment_result || 0);
                newData.design_investment =
                    accumulator.design_investment + (currentValue.design_investment_result || 0);
                newData.design_investment_integration =
                    accumulator.design_investment_integration + (currentValue.integration_design_investments || 0);
                newData.line_investment_integration =
                    accumulator.line_investment_integration + (currentValue.integration_line_investments || 0);
                newData.other_investment_integration =
                    accumulator.other_investment_integration + (currentValue.other_investments || 0);
                newData.line_investment = accumulator.line_investment + (currentValue.line_investment_result || 0);
                newData.other_investment = accumulator.other_investment + (currentValue.other_investment_result || 0);
                newData.dmc_result = accumulator.dmc_result + (currentValue.dmc_result || 0);
                newData.scale_benefit_result =
                    accumulator.scale_benefit_result + (currentValue.scale_benefit_result || 0);
                newData.cash_flow_release_result =
                    accumulator.cash_flow_release_result + (currentValue.cash_flow_release_result || 0);
                newData.inventory_cost =
                    accumulator.inventory_cost +
                        (currentValue.working_capital_result +
                            currentValue.part_obsolescence_result +
                            currentValue.space_utilization_result) || 0;
                newData.warranty_cost = accumulator.warranty_cost + (currentValue.warranty_result || 0);
                newData.rejection_cost = accumulator.rejection_cost + (currentValue.lr_material_result || 0);
                newData.logistics_cost =
                    accumulator.logistics_cost +
                        (currentValue.inbound_logistics_result + currentValue.intra_plant_logistics_result) || 0;
                newData.design_investment_total =
                    accumulator.design_investment_total + (currentValue.total_design_investments || 0);
                newData.line_investment_total =
                    accumulator.line_investment_total + (currentValue.total_line_investments || 0);
                newData.other_investment_total =
                    accumulator.other_investment_total + (currentValue.total_other_investments || 0);
                newData.tooling_investment_total =
                    accumulator.tooling_investment_total + (currentValue.total_tooling_investments || 0);
                newData.benefit =
                    accumulator.benefit +
                    currentValue.dmc_result +
                    currentValue.working_capital_result +
                    currentValue.part_obsolescence_result +
                    currentValue.space_utilization_result +
                    currentValue.warranty_result +
                    currentValue.lr_material_result +
                    currentValue.inbound_logistics_result +
                    currentValue.intra_plant_logistics_result +
                    currentValue.lr_rework_manpower_result +
                    currentValue.handling_manpower_result +
                    newData.effiency_enhancement_result;
                newData.investment =
                    accumulator.investment +
                    newData.design_investment_total +
                    newData.line_investment_total +
                    newData.other_investment_total +
                    newData.tooling_investment_total;
                newData.net_savings_arr = currentValue.net_savings_arr.map((ele, index) => {
                    return newData.net_savings_arr[index] + ele;
                });
                newData.npv = accumulator.npv + currentValue.npv;
            });
            return newData;
        },
        {
            tooling_investment: 0,
            design_investment: 0,
            line_investment: 0,
            other_investment: 0,
            variant_reduction_result: 0,
            dmc_result: 0,
            scale_benefit_result: 0,
            unique_current_parts: 0,
            unique_proposed_parts: 0,
            cash_flow_release_result: 0,
            sunk_cost: 0,
            effiency_enhancement_result: 0,
            effiency_enhancement_hours_result: 0,
            inventory_cost: 0,
            warranty_cost: 0,
            rejection_cost: 0,
            logistics_cost: 0,
            design_investment_integration: 0,
            line_investment_integration: 0,
            other_investment_integration: 0,
            design_investment_total: 0,
            line_investment_total: 0,
            other_investment_total: 0,
            tooling_investment_total: 0,
            benefit: 0,
            investment: 0,
            net_savings_arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            npv: 0,
            irr: 0,
            payback_period: 0,
        },
    );

    try {
        aggregate.irr = finance.IRR(aggregate.net_savings_arr[0], ...aggregate.net_savings_arr.slice(1));
    } catch (e) {
        aggregate.irr = 0;
    }
    aggregate.payback_period = aggregate.investment !== 0 ? aggregate.benefit / aggregate.investment : 0;

    return aggregate;
};

export const formatForExport = (projectDetailsRes: GetProjectByIdResponse): {} => {
    const {
        dmc_result,
        scale_benefit_result,
        warranty_result,
        supplier_line_investments,
        supplier_internal_investments,
        tooling_investment_result,
        design_investment_result,
        projectAnalyses,
        line_investment_result,
        other_investment_result,
        other_investments,
        design_manhours,
        consultation_costs,
        prototyping_costs,
        certification_tooling_costs,
        supplier_rd_costs,
        working_capital_result,
        lr_material_result,
        inbound_logistics_result,
        intra_plant_logistics_result,
        part_obsolescence_result,
        space_utilization_result,
        cash_flow_release_result,
        integration_design_investments,
        integration_line_investments,
        total_design_investments,
        total_line_investments,
        total_other_investments,
        total_tooling_investments,
        net_savings_arr,
    } = projectDetailsRes;
    const modules = [...new Set(projectAnalyses.map((e) => e.hierarchy_5))];
    const designInvestment =
        design_manhours + consultation_costs + prototyping_costs + certification_tooling_costs + supplier_rd_costs;
    const lineInvestment = supplier_internal_investments + supplier_line_investments;
    const inventoryCost = working_capital_result + part_obsolescence_result + space_utilization_result;
    const logisticsCost = inbound_logistics_result + intra_plant_logistics_result;

    const benefit =
        projectDetailsRes.dmc_result +
        projectDetailsRes.working_capital_result +
        projectDetailsRes.part_obsolescence_result +
        projectDetailsRes.space_utilization_result +
        projectDetailsRes.warranty_result +
        projectDetailsRes.lr_material_result +
        projectDetailsRes.inbound_logistics_result +
        projectDetailsRes.intra_plant_logistics_result +
        projectDetailsRes.lr_rework_manpower_result +
        projectDetailsRes.handling_manpower_result;
    const investment =
        total_design_investments + total_line_investments + total_other_investments + total_tooling_investments;
    const payback_period = investment !== 0 ? benefit / investment : 0;

    let irr;
    try {
        irr = finance.IRR((net_savings_arr as number[])[0], ...(net_savings_arr as number[]).slice(1));
    } catch {
        irr = 0;
    }

    return {
        projectId: projectDetailsRes.id,
        platform: projectDetailsRes.hierarchy_1,
        product: projectDetailsRes.hierarchy_2,
        projectTitle: projectDetailsRes.title,
        description: projectDetailsRes.description,
        creator: projectDetailsRes.originator,
        input: {
            models: projectDetailsRes.hierarchy_3,
            modules: modules,
            designInvestments: designInvestment?.toFixed(2) ?? 0,
            lineInvestments: lineInvestment?.toFixed(2) ?? 0,
            otherInvestments: other_investment_result?.toFixed(2) ?? 0,
        },
        output: {
            dmcResult: Number(dmc_result).toFixed(2),
            scaleBenefitResult: Number(scale_benefit_result).toFixed(2),
            inventoryCost: inventoryCost.toFixed(2),
            warrantyCost: warranty_result?.toFixed(2) ?? 0,
            rejectionCost: lr_material_result?.toFixed(2) ?? 0,
            spareCost: null,
            logisticsCost: logisticsCost?.toFixed(2) ?? 0,
            totalCost: Number(
                dmc_result +
                    scale_benefit_result +
                    inventoryCost +
                    logisticsCost +
                    warranty_result +
                    lr_material_result,
            ).toFixed(2),
            recurringImpact: Number(
                dmc_result +
                    scale_benefit_result +
                    inventoryCost +
                    logisticsCost +
                    warranty_result +
                    lr_material_result,
            ).toFixed(2),
            totalInvestment: (
                Number(tooling_investment_result) +
                Number(design_investment_result) +
                Number(other_investment_result) +
                Number(line_investment_result)
            )?.toFixed(2),
            sunkCost: null,
            paybackPeriod: Number(payback_period)?.toFixed(2) ?? 0,
            cashRelease: cash_flow_release_result?.toFixed(2) ?? 0,
            npv: Number(projectDetailsRes.npv)?.toFixed(2) ?? 0,
            irr: Number(irr)?.toFixed(2) ?? 0,
            moduleDesignInvestment: design_investment_result?.toFixed(2) ?? 0,
            moduleToolingInvestment: tooling_investment_result?.toFixed(2) ?? 0,
            moduleLineInvestment: line_investment_result?.toFixed(2) ?? 0,
            moduleOtherInvestment: other_investment_result?.toFixed(2) ?? 0,
            integrationDesignInvestment: integration_design_investments?.toFixed(2) ?? 0,
            integrationToolingInvestment: null,
            integrationLineInvestment: integration_line_investments?.toFixed(2) ?? 0,
            integrationOtherInvestment: other_investments,
            totalDesignInvestment: total_design_investments?.toFixed(2) ?? 0,
            totalLineInvestment: total_line_investments?.toFixed(2) ?? 0,
            totalOtherInvestment: total_other_investments?.toFixed(2) ?? 0,
            totalToolingInvestment: total_tooling_investments?.toFixed(2) ?? 0,
        },
    };
};
