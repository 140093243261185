import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tab } from '@material-ui/core';
import Title from '../../components/Title/Title';
import { tabConfig, superuserTabs } from './DataView.config';
import DataTable from './components/DataTable/DataTable';

import * as S from './DataView.style';
import { Roles } from '../../constants/auth';

export default function DataView({ roles }: { roles: string[] }): JSX.Element {
    const history = useHistory();

    const [currentTab, setCurrentTab] = React.useState(0);

    const onTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
        setCurrentTab(newValue);
    };

    const tabsToShow = roles?.includes(Roles.SUPER_USER) ? [...tabConfig, ...superuserTabs] : tabConfig;

    return (
        <S.Container>
            <S.TitleContainer>
                <Title title="View Data" showBackButton handleBackButtonClick={(): void => history.goBack()} />
            </S.TitleContainer>
            <S.BodyContainer>
                <S.StyledAppBar position="static" color="transparent">
                    <S.StyledTabs
                        value={currentTab}
                        onChange={onTabChange}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabsToShow.map((config) => (
                            <Tab key={config.title} label={config.title} />
                        ))}
                    </S.StyledTabs>
                </S.StyledAppBar>
                <DataTable tabIndex={currentTab} tabConfig={tabsToShow} />
            </S.BodyContainer>
        </S.Container>
    );
}
