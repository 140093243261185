// import React from 'react';
import styled, { css } from 'styled-components';

export const Row = styled.div`
    display: flex;
`;

export const columnSpacing = css`
    margin: 0 0.75rem 0;
    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;
