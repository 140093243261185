import { TabConfig } from '../../DataView.config';

interface Data {
    [key: string]: { results: Array<{ created_at: string; updated_at: string; details: {}; portfolio: Array<{}> }> };
}

export const formatTableData = (data: Data, tabConfig: TabConfig) => {
    switch (tabConfig.key) {
        case 'npvIrr':
            return (
                (data as any)?.[tabConfig.key].map((npvData: any) => {
                    return {
                        ...npvData,
                        created_at: npvData.created_at?.substring(0, 10),
                        updated_at: npvData.updated_at?.substring(0, 10),
                    };
                }) ?? []
            );
        case 'parts':
            const flattenedPartsData = data?.[tabConfig.key]?.results?.reduce((accumulator, currentValue) => {
                const verticalHierarchies = currentValue.portfolio.map((verticalHierarchy) => {
                    const partDataWithVerticalHierarchy = {
                        ...currentValue,
                        ...verticalHierarchy,
                    };
                    delete partDataWithVerticalHierarchy.portfolio;
                    return partDataWithVerticalHierarchy;
                });
                return [...accumulator, ...verticalHierarchies];
            }, [] as any);
            return (
                flattenedPartsData?.map((part: any) => {
                    return {
                        ...part,
                        created_at: part.created_at?.substring(0, 10),
                        updated_at: part.updated_at?.substring(0, 10),
                    };
                }) ?? []
            );
        case 'benchmarks':
        case 'quickBenchmarks':
            return (
                data?.[tabConfig.key]?.results?.map((benchmark) => {
                    return {
                        ...benchmark,
                        ...benchmark.details,
                        created_at: benchmark.created_at.substring(0, 10),
                        updated_at: benchmark.updated_at?.substring(0, 10),
                    };
                }) ?? []
            );
        default:
            return (
                data?.[tabConfig.key]?.results?.map((fields) => {
                    return {
                        ...fields,
                        created_at: fields.created_at.substring(0, 10),
                        updated_at: fields.updated_at?.substring(0, 10),
                    };
                }) ?? []
            );
    }
};
