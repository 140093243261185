import React from 'react';
import { Row } from '../PartsSelection.style';
import { PartBlank, HandlerButtonContainer, HandlerButton } from './MappingRow.style';
import { FileCopyOutlinedIcon, DescriptionOutlinedIcon, DeleteOutlineOutlinedIcon } from '../../../NewAnalysis.style';

interface Props {
    currentPart?: string;
    proposedPart?: string;
    isCustomCurrentPart?: boolean;
    isCustomProposedPart?: boolean;
    replaceCurrentPart: (index: number) => unknown;
    replaceProposedPart: (index: number) => unknown;
    copyProposedPart: (index: number) => unknown;
    pasteProposedPart: (index: number) => unknown;
    deleteRow: (index: number) => unknown;
}

function MappingRow({
    currentPart,
    proposedPart,
    replaceCurrentPart,
    replaceProposedPart,
    copyProposedPart,
    pasteProposedPart,
    deleteRow,
    isCustomCurrentPart = false,
    isCustomProposedPart = false,
}: Props): JSX.Element {
    return (
        <Row>
            <PartBlank isCustom={isCustomCurrentPart} onClick={(): unknown => replaceCurrentPart(1)}>
                {currentPart}
            </PartBlank>
            <PartBlank isCustom={isCustomProposedPart} onClick={(): unknown => replaceProposedPart(1)}>
                {proposedPart}
                <HandlerButtonContainer>
                    <HandlerButton
                        onClick={(e): void => {
                            e.stopPropagation();
                            copyProposedPart(1);
                        }}
                    >
                        <span title="Copy Part">
                            <FileCopyOutlinedIcon />
                        </span>
                    </HandlerButton>
                    <HandlerButton
                        onClick={(e): void => {
                            e.stopPropagation();
                            pasteProposedPart(1);
                        }}
                    >
                        <span title="Paste Part">
                            <DescriptionOutlinedIcon />
                        </span>
                    </HandlerButton>
                    <HandlerButton
                        onClick={(e): void => {
                            e.stopPropagation();
                            deleteRow(1);
                        }}
                    >
                        <span title="Delete Row">
                            <DeleteOutlineOutlinedIcon />
                        </span>
                    </HandlerButton>
                </HandlerButtonContainer>
            </PartBlank>
        </Row>
    );
}

export default MappingRow;
