import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const MetricTitle = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.accentPrimary};
        text-transform: uppercase;
    `,
);

export const GroupedMetricContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        ${theme.typography.robotoRegular12};
        color: ${theme.colors.accentPrimary};
        text-transform: uppercase;
    `,
);

export const Metrics = styled(Typography)(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        > div {
            margin-right: 1.4rem;
        }
    `,
);

export const Divider = styled.div(
    ({ theme }) => css`
        height: 1px;
        width: 100%;
        background-color: ${theme.colors.greyScaleMedium};
        margin-top: 1%;
        margin-bottom: 5%;
    `,
);
