import React from 'react';
import { Row } from '../PartsSelection.style';
import { EnterNewPartButton } from './EnterPartNumberRow.style';

interface Props {
    onCurrentClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onProposedClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function EnterPartNumberRow({ onCurrentClick, onProposedClick }: Props): JSX.Element {
    return (
        <Row>
            <EnterNewPartButton onClick={onCurrentClick}>Enter Part Number</EnterNewPartButton>
            <EnterNewPartButton onClick={onProposedClick}>Enter Part Number</EnterNewPartButton>
        </Row>
    );
}

export default EnterPartNumberRow;
