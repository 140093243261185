export const basicInfoConfig = [
    {
        label: 'CHARGE CODE',
        key: 'chargeCode',
    },
    {
        label: 'DESIGNATION',
        key: 'designation',
    },
    {
        label: 'DEPARTMENT',
        key: 'department',
    },
];

export interface AccountInfo {
    selectedUserType: string;
    chargeCode: string;
    designation: string;
    department: string;
    isDataAdmin: string;
    isSuperUser: string;
    userStatus: string;
}

export const defaultAccountInfo = {
    selectedUserType: '',
    chargeCode: '',
    designation: '',
    department: '',
    isDataAdmin: '',
    isSuperUser: '',
    userStatus: '',
};

export const dataAdminRadioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
];

export const userStatusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Disabled', value: 'DISABLED' },
];

export const userTypeOptions = [
    { label: 'Client Service Professional', value: 'CSP' },
    { label: 'Client', value: 'C' },
];
