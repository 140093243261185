import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import Title from '../../components/Title/Title';
import CircularButton, { CircularButtonType } from '../../components/CircularButton/CircularButton';
import Button, { ButtonType } from '../../components/Button/Button';
import ProjectDetailsPane from './components/ProjectDetails/ProjectDetails';
import ProjectBox from './components/ProjectBox/ProjectBox';
import SelectModels from './components/SelectModels/SelectModels';
import SelectModules from './components/SelectModules/SelectModules';
import ProjectInvestmentsPane from './components/ProjectInvestments/ProjectInvestments';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '../../components/Snackbar/Snackbar';
import { useAuthContext } from '../../context/Auth';

import {
    ProjectDetails,
    projectDetailsInitialState,
    ProjectInvestments,
    projectInvestmentsInitialState,
    LocationState,
} from './NewProject.config';
import {
    getHierarchyPLDataConfig,
    GetHierarchyDataResponse,
    getAnalysisListByVerticalHierarchyConfig as getAnalysisListConfig,
    GetAnalysisListByVerticalHierarchyResponse as getAnalysisListResponse,
    patchProjectConfig,
    runProjectConfig,
    GetProjectByIdResponse,
} from '../../constants/apiConfig';

import * as S from './NewProject.style';

export default function NewProject(): JSX.Element {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const locationState = location?.state;

    const { info } = useAuthContext();

    const [{ data: hierarchy }] = useFetch<GetHierarchyDataResponse>(getHierarchyPLDataConfig, true);
    const [{ data: analysisList }, getAnalysisList, , resetAnalysisList] = useFetch<getAnalysisListResponse>(
        getAnalysisListConfig,
    );
    const [patchProjectStatus, patchProject] = useFetch<string>(patchProjectConfig);
    const [runProjectStatus, runProject] = useFetch<GetProjectByIdResponse>(runProjectConfig);

    const [selectedPlatform, setSelectedPlatform] = useState<string>('');
    const [selectedProduct, setSelectedProduct] = useState<string>('');
    const [selectedModels, setSelectedModels] = useState<Array<string>>([]);
    const [selectedModules, setSelectedModules] = useState<Array<string>>([]);
    const [projectDetails, setProjectDetails] = useState<ProjectDetails>(projectDetailsInitialState);
    const [projectInvestments, setProjectInvestments] = useState<ProjectInvestments>(projectInvestmentsInitialState);
    const [showValidations, setShowValidations] = useState<boolean>(false);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);

    const platformOptions = useMemo(() => {
        return hierarchy?.platforms?.map((platform) => ({ label: platform?.label, value: platform?.label })) ?? [];
    }, [hierarchy]);

    const productOptions = useMemo(() => {
        const platform = hierarchy?.platforms?.find((platform) => platform?.label === selectedPlatform) ?? undefined;
        return platform?.products?.map((product) => ({ label: product?.label, value: product?.label })) ?? [];
    }, [hierarchy, selectedPlatform]);

    const modelOptions = useMemo(() => {
        const platform = hierarchy?.platforms?.find((platform) => platform?.label === selectedPlatform) ?? undefined;
        const product = platform?.products?.find((product) => product?.label === selectedProduct) ?? undefined;
        return product?.models?.map((model) => model?.label) ?? [];
    }, [hierarchy, selectedPlatform, selectedProduct]);

    const completedAnalysis = useMemo(() => analysisList?.filter((analysis) => analysis.status === 'SUBMITTED'), [
        analysisList,
    ]);

    useEffect(() => {
        setProjectDetails((prev) => ({ ...prev, createdBy: info?.idInfo?.email ?? '' }));
    }, [info]);

    useEffect(() => {
        if (selectedModels.length > 0) {
            const params = {
                platform: selectedPlatform,
                product: selectedProduct,
                models: selectedModels.toString(),
            };
            getAnalysisList({ params });
        }
    }, [selectedModels, selectedPlatform, selectedProduct, getAnalysisList]);

    useEffect(() => {
        if (!locationState?.isEdit) {
            setSelectedModels([]);
        }
    }, [selectedPlatform, selectedProduct, locationState]);

    useEffect(() => {
        setProjectDetails((prevDetails) => ({ ...prevDetails, id: locationState?.id?.toString() }));
        if (locationState?.isEdit) {
            setSelectedPlatform(locationState.hierarchy_1);
            setSelectedProduct(locationState.hierarchy_2);
            setSelectedModels(locationState.hierarchy_3);
            setSelectedModules(locationState.projectAnalyses.map((analysis) => analysis.hierarchy_5));
            setProjectDetails({
                description: locationState.description,
                createdBy: locationState.originator,
                title: locationState.title,
                id: locationState.id.toString(),
            });

            setProjectInvestments({
                certificationAndTesting: locationState.certification_tooling_costs.toString(),
                integrationDesign: locationState.design_manhours.toString(),
                consultationCost: locationState.consultation_costs.toString(),
                prototypingCost: locationState.prototyping_costs.toString(),
                supplierRnD: locationState.supplier_rd_costs.toString(),
                supplier: locationState.supplier_line_investments.toString(),
                internal: locationState.supplier_internal_investments.toString(),
                other: locationState.other_investments.toString(),
            });
        }
    }, [locationState]);

    useEffect(() => {
        if (!locationState?.isEdit) {
            setSelectedPlatform(platformOptions[0]?.label);
        }
    }, [platformOptions, locationState]);

    useEffect(() => {
        if (productOptions.length > 0 && !selectedProduct) {
            setSelectedProduct(productOptions[0]?.label);
        }
    }, [productOptions, selectedProduct]);

    const resetState = (): void => {
        setSelectedModules([]);
        setSelectedModels([]);
        setSelectedProduct('');
        setSelectedPlatform(platformOptions[0]?.label);
        setProjectDetails((prevState) => ({
            ...projectDetailsInitialState,
            id: prevState.id,
            createdBy: prevState.createdBy,
        }));
        setProjectInvestments(projectInvestmentsInitialState);
        setShowValidations(false);
        setShowSnackBar(false);
    };

    const handleAnalyseProject = async (): Promise<void> => {
        setShowValidations(true);
        setShowSnackBar(true);
        if (Object.keys(projectDetails).find((key) => projectDetails[key as keyof ProjectDetails] === '')) {
            return;
        }
        if (selectedModels.length === 0 || selectedModules.length === 0) {
            return;
        }
        const analysisList =
            completedAnalysis?.filter((analysis) => selectedModules.includes(analysis.hierarchy_5)) ?? [];
        const data = {
            platform: selectedPlatform,
            product: selectedProduct,
            models: selectedModels,
            status: 'DEFAULT',
            title: projectDetails.title,
            originator: projectDetails.createdBy,
            description: projectDetails.description,
            supplier_line_investments: Number(projectInvestments.supplier),
            supplier_internal_investments: Number(projectInvestments.internal),
            other_investments: Number(projectInvestments.other),
            consultation_costs: Number(projectInvestments.consultationCost),
            design_manhours: Number(projectInvestments.integrationDesign),
            prototyping_costs: Number(projectInvestments.prototypingCost),
            supplier_rd_costs: Number(projectInvestments.supplierRnD),
            certification_tooling_costs: Number(projectInvestments.certificationAndTesting),
            analysisDetails: analysisList.map((analysis) => ({
                analysis_id: analysis.analysis_id,
                module: analysis.hierarchy_5,
            })),
        };
        if (await patchProject({ data, pathParams: { id: projectDetails.id } })) {
            runProject({ data, pathParams: { id: projectDetails.id } });
        }
    };

    useEffect(() => {
        if (runProjectStatus.loading === false && runProjectStatus.data !== undefined) {
            history.push('/projectSummary', {
                run: runProjectStatus.data,
                projectDetails,
                selectedModels,
                selectedModules,
            });
        }
    }, [
        runProjectStatus.loading,
        runProjectStatus.data,
        projectDetails.id,
        selectedModels,
        selectedModules,
        history,
        projectDetails,
    ]);

    const resetModelsAndModules = (): void => {
        setSelectedModels([]);
        setSelectedModules([]);
    };

    return (
        <S.Container>
            <Snackbar
                message="At least one model and one module should be selected "
                isOpen={showSnackBar && (selectedModels.length === 0 || selectedModules.length === 0)}
                onClose={(): void => setShowSnackBar(false)}
            />
            <S.TitleContainer>
                <Title
                    title={`${location?.state?.isEdit ? 'Edit' : 'New'} Project`}
                    showBackButton
                    handleBackButtonClick={(): void => history.goBack()}
                />
                <S.ActionButtonContainer>
                    <CircularButton variant={CircularButtonType.RESET} label="RESET" onClickHandler={resetState} />
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={handleAnalyseProject}
                        disabled={patchProjectStatus.loading || runProjectStatus.loading}
                    >
                        {patchProjectStatus.loading || runProjectStatus.loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            ' Analyse Project'
                        )}
                    </Button>
                </S.ActionButtonContainer>
            </S.TitleContainer>
            <S.BodyContainer>
                <S.ProjectDetailsColumn>
                    <ProjectDetailsPane
                        selectedPlatform={selectedPlatform}
                        platformOptions={platformOptions}
                        setSelectedPlatform={setSelectedPlatform}
                        selectedProduct={selectedProduct}
                        productOptions={productOptions}
                        setSelectedProduct={setSelectedProduct}
                        projectDetails={projectDetails}
                        setProjectDetails={setProjectDetails}
                        showValidations={showValidations}
                        resetModelsAndModules={resetModelsAndModules}
                        resetAnalysisList={resetAnalysisList}
                    />
                    <ProjectBox selectedModels={selectedModels} selectedModules={selectedModules} />
                </S.ProjectDetailsColumn>
                <SelectModels
                    selectedModels={selectedModels}
                    setSelectedModels={setSelectedModels}
                    setSelectedModules={setSelectedModules}
                    resetAnalysisList={resetAnalysisList}
                    modelOptions={modelOptions}
                />
                <SelectModules
                    analysisList={completedAnalysis}
                    setSelectedModules={setSelectedModules}
                    selectedModules={selectedModules}
                />
                <ProjectInvestmentsPane
                    projectInvestments={projectInvestments}
                    setProjectInvestments={setProjectInvestments}
                />
            </S.BodyContainer>
        </S.Container>
    );
}
