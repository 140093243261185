import React from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AccessConfig, LandingPath, Roles } from './constants/auth';
import { useAuthContext } from './context/Auth';

function Authorise({ children }: { children: JSX.Element }) {
    const { info } = useAuthContext();
    const history = useHistory();
    const userAssignedGroups = get(info, 'accessInfo.groups', []);

    const roles = [];
    if (userAssignedGroups.includes(Roles.SUPER_USER)) {
        roles.push(Roles.SUPER_USER);
    } else {
        roles.push(...userAssignedGroups);
    }

    const routeAccessInfo = find(AccessConfig, ['path', history.location.pathname]);
    if (!routeAccessInfo || intersection(routeAccessInfo.accessRoles, roles).length > 0) {
        return children;
    } else {
        if (roles.length === 1) {
            const defaultLandingPath = find(LandingPath, ['role', roles[0]])?.path;
            if (history.location.pathname !== defaultLandingPath) {
                history.push(defaultLandingPath || '/');
            }
        } else {
            if (history.location.pathname !== '/') {
                history.push('/');
            }
        }

        return <CircularProgress />;
    }
}

export default Authorise;
