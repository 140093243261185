import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell, StyledTableRow, StyledTableCount, TableText } from './Table.style';

interface Props {
    headCells?: string[];
    data: { [key: string]: string | number | React.ReactNode }[];
    bodyCells: string[];
    hideSerialNumberColumn?: boolean;
    totalTableEntries?: number | null;
    totalTableEntriesLabel?: string;
    columnAlignment?: Array<'left' | 'right' | 'inherit' | 'center' | 'justify'>;
    wrapText?: boolean;
}

enum Order {
    asc = 'asc',
    desc = 'desc',
}

export default function CustomizedTable({
    headCells,
    data,
    bodyCells,
    totalTableEntries = null,
    hideSerialNumberColumn = false,
    columnAlignment,
    wrapText = false,
    totalTableEntriesLabel = 'Recent Data',
}: Props): JSX.Element {
    const [activeColumn, setActiveColumn] = useState<number>(-1);
    const [sortOrder, setSortOrder] = useState<Order>(Order.asc);

    const sortTableByColumn = (sortOrder: Order, colToSort: string): void => {
        data.sort(function (rowA, rowB) {
            const keyA = (rowA as { [key: string]: string | number })[colToSort],
                keyB = (rowB as { [key: string]: string | number })[colToSort];
            if (typeof keyA === 'string' && typeof keyB === 'string' && /\d/.test(keyA)) {
                const comparator =
                    Number((keyA.match(/(\d+)/g) as string[])[0]) - Number((keyB.match(/(\d+)/g) as string[])[0]);
                return sortOrder === Order.asc ? comparator : comparator * -1;
            }
            if (keyA < keyB) return sortOrder === Order.asc ? -1 : 1;
            if (keyA > keyB) return sortOrder === Order.asc ? 1 : -1;
            return 0;
        });
    };

    const sortHandler = (currentColumnId: number): void => {
        const colId = !hideSerialNumberColumn ? currentColumnId : currentColumnId + 1;
        const colToSort = Object.keys(data[0])[colId];
        let currentSortOrder = Order.asc;
        if (activeColumn === colId) {
            currentSortOrder = sortOrder === Order.asc ? Order.desc : Order.asc;
        } else {
            setActiveColumn(colId);
            currentSortOrder = Order.asc;
        }
        sortTableByColumn(currentSortOrder, colToSort);
        setSortOrder(currentSortOrder);
    };

    return (
        <TableContainer component={'div'}>
            {!!totalTableEntries && (
                <StyledTableCount>
                    {totalTableEntries} {totalTableEntriesLabel}
                </StyledTableCount>
            )}
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {!hideSerialNumberColumn && <StyledTableCell align={'center'}>S.No</StyledTableCell>}
                        {headCells?.map((cellVal, index) => {
                            return (
                                <StyledTableCell
                                    style={{ cursor: 'pointer' }}
                                    key={index}
                                    onClick={(): void => sortHandler(index)}
                                    align={columnAlignment?.[index] ?? 'left'}
                                >
                                    <TableText noWrap={!wrapText}>{cellVal}</TableText>
                                </StyledTableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((row, index) => (
                        <StyledTableRow key={index}>
                            {!hideSerialNumberColumn && (
                                <StyledTableCell align={'center'} scope="row">
                                    {index + 1}
                                </StyledTableCell>
                            )}
                            {bodyCells.map((key, index) => {
                                return (
                                    <StyledTableCell align={columnAlignment?.[index] ?? 'left'} key={index}>
                                        <TableText noWrap>{(row as { [key: string]: string | number })[key]}</TableText>
                                    </StyledTableCell>
                                );
                            })}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
