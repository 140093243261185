import React from 'react';
import { Container, Image } from './ImageCard.style';

export default function RecipeReviewCard({ imageSource }: { imageSource: string }): JSX.Element {
    return (
        <Container>
            <Image src={imageSource} alt="upload" />
        </Container>
    );
}
