import React, { useState, useMemo } from 'react';
import omit from 'lodash/omit';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {
    Container,
    StyledSelect,
    StyledDialogTitle,
    StyledIconButton,
    StyledDialog,
    StyledDialogContent,
    StyledButton,
    DialogActions,
    StyledFormGroup,
    StyledFormControlLabel,
    SelectContainer,
    StyledTextField,
    StyledCheckbox,
    StyledTitle,
    SectionTitle,
    StyledRadio,
    StyledDescription,
} from './PartsModal.style';
import { CustomParts } from '../../constants/apiConfig';
import { Part } from '../../pages/NewAnalysis/NewAnalysis.types';

export interface Props {
    handleClose: () => void;
    onConfirm: (parts: Part[], customParts: Part[]) => void;
    partsData: Array<Part>;
    open: boolean;
    fromPartsCount?: number;
    modalType?: string;
    destination: 'from' | 'to';
    operation: 'extend' | 'replace';
    resetProposedParts?: boolean;
    customParts: Array<CustomParts>;
    replaceIndex: number;
    onConfirmReplace: (part: Part | null, type: 'from' | 'to', index: number) => void;
    showSnackbar: (state: { isOpen: boolean; message: string }) => void;
}
export interface DialogTitleProps {
    children: React.ReactNode;
    onClose: () => void;
}

export interface Filters {
    platform: string;
    product: string;
    model: string;
    variant: string;
}

const initialFilters: Filters = { platform: '', product: '', model: '', variant: '' };

const DialogTitle = (props: DialogTitleProps): JSX.Element => {
    const { children, onClose } = props;
    return (
        <StyledDialogTitle disableTypography>
            <StyledTitle variant="h6">{children}</StyledTitle>
            {onClose ? (
                <StyledIconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </StyledIconButton>
            ) : null}
        </StyledDialogTitle>
    );
};

export default function PartsModal({
    handleClose,
    open,
    onConfirm,
    partsData,
    fromPartsCount = 0,
    customParts,
    destination,
    operation,
    replaceIndex,
    onConfirmReplace,
    showSnackbar,
}: Props): JSX.Element {
    const [filters, setFilterType] = React.useState<Filters>(initialFilters);

    const onFilterSelect = (key: string) => (event: any): void => {
        const newFilters = { ...filters, [key]: event.target.value };
        setFilterType(newFilters);
    };

    const [searchText, setSearchText] = useState<string>('');
    const [selectedParts, setSelectedParts] = useState<{ [key in string]: Part }>({});
    const [selectedCustomParts, setSelectedCustomParts] = useState<{ [key in string]: Part }>({});

    const [selectedPart, setSelectedPart] = useState<Part | null>(null);

    const filteredParts = useMemo(() => {
        return partsData.filter((part) => {
            const { platform, product, model, variant } = filters;
            return part.portfolio.some((hierarchy) => {
                const platformMatched = platform ? platform === hierarchy.platform : true;
                const productMatched = product ? product === hierarchy.product : true;
                const modelMatched = model ? model === hierarchy.model : true;
                const variantMatched = variant ? variant === hierarchy.variant : true;
                const searchMatched = searchText !== '' ? part.partNumber.toLowerCase().includes(searchText) : true;

                return platformMatched && productMatched && modelMatched && variantMatched && searchMatched;
            });
        });
    }, [partsData, filters, searchText]);

    const [platforms, products, models, variants] = useMemo(() => {
        const hierarchies = partsData.reduce(
            (acc: { [key in string]: { [key in string]: boolean } }, part) => {
                part.portfolio.forEach((hierarchy) => {
                    acc.platforms[hierarchy.platform] = true;
                    acc.products[hierarchy.product] = true;
                    acc.models[hierarchy.model] = true;
                    acc.variants[hierarchy.variant] = true;
                });
                return acc;
            },
            {
                platforms: {},
                products: {},
                models: {},
                variants: {},
            },
        );
        customParts.forEach((part) => {
            hierarchies.platforms[part.hierarchy_1] = true;
            hierarchies.products[part.hierarchy_2] = true;
            hierarchies.models[part.hierarchy_3] = true;
            hierarchies.variants[part.hierarchy_4] = true;
        });
        return [
            Object.keys(hierarchies.platforms),
            Object.keys(hierarchies.products),
            Object.keys(hierarchies.models),
            Object.keys(hierarchies.variants),
        ];
    }, [partsData, customParts]);

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, checked } = event.target;
        if (checked) {
            const selectedPart = filteredParts.find((part) => part.partNumber === name) as Part;
            setSelectedParts({ ...selectedParts, [name]: selectedPart });
            return;
        }
        setSelectedParts(omit(selectedParts, name));
    };

    const handleCustomPartSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const isChecked = event.target.checked;
        const partNumber = event.target.name;
        if (isChecked) {
            setSelectedCustomParts({
                ...selectedCustomParts,
                [partNumber]: { partNumber, description: '', isUserAdded: true, portfolio: [] },
            });
            return;
        }
        setSelectedCustomParts(omit(selectedCustomParts, partNumber));
    };

    const addParts = () => {
        if (operation === 'replace') {
            setSelectedPart(null);
            onConfirmReplace(selectedPart, destination, replaceIndex);
            return;
        }
        if (destination === 'to') {
            if (Object.values(selectedParts).length > Math.max(fromPartsCount, 0)) {
                showSnackbar({ isOpen: true, message: 'proposed parts may not exceed current parts' });
                return;
            }
        }
        setSelectedParts({});
        setSelectedCustomParts({});
        onConfirm(Object.values(selectedParts), Object.values(selectedCustomParts));
    };

    const filteredCustomParts = useMemo(
        () =>
            customParts.filter((part) => {
                if (filters.platform !== '' && part.hierarchy_1 !== filters.platform) {
                    return false;
                }
                if (filters.product !== '' && part.hierarchy_2 !== filters.product) {
                    return false;
                }
                if (filters.model !== '' && part.hierarchy_3 !== filters.model) {
                    return false;
                }
                if (filters.variant !== '' && part.hierarchy_4 !== filters.variant) {
                    return false;
                }
                if (searchText !== '' && !part.part_number.toLowerCase().includes(searchText)) {
                    return false;
                }
                return true;
            }),
        [customParts, filters, searchText],
    );

    const onClose = (): void => {
        setSelectedParts({});
        setSelectedCustomParts({});
        handleClose();
    };

    return (
        <Container>
            <StyledDialog onClose={onClose} open={open} maxWidth="md">
                <DialogTitle onClose={onClose}>
                    Select {destination === 'from' ? 'Current' : 'Proposed'} Parts
                </DialogTitle>
                <StyledDialogContent>
                    {'ADVANCE FILTER'}
                    <SelectContainer>
                        <FormControl>
                            <InputLabel>Platform</InputLabel>
                            <StyledSelect value={filters.platform} onChange={onFilterSelect('platform')}>
                                {platforms.map((ele: any, index) => {
                                    return (
                                        <MenuItem key={index} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                        </FormControl>
                        <FormControl>
                            <InputLabel>Product</InputLabel>
                            <StyledSelect value={filters.product} onChange={onFilterSelect('product')}>
                                {products.map((ele: any, index) => {
                                    return (
                                        <MenuItem key={index} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                        </FormControl>
                    </SelectContainer>
                    <SelectContainer>
                        <FormControl>
                            <InputLabel>Model</InputLabel>
                            <StyledSelect value={filters.model} onChange={onFilterSelect('model')}>
                                {models.map((ele: any, index) => {
                                    return (
                                        <MenuItem key={index} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                        </FormControl>
                        <FormControl>
                            <InputLabel>Variant</InputLabel>
                            <StyledSelect value={filters.variant} onChange={onFilterSelect('variant')}>
                                {variants.map((ele: any, index) => {
                                    return (
                                        <MenuItem key={index} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                        </FormControl>
                    </SelectContainer>
                    <FormControl>
                        <TextField
                            placeholder="Search Part number"
                            variant="filled"
                            onChange={(e): void => setSearchText(e.target.value.toLowerCase())}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            style={StyledTextField.containerStyle}
                            inputProps={{ style: StyledTextField.inputStyle }}
                        />
                    </FormControl>
                    <StyledFormGroup>
                        {filteredParts.map((part, index) => {
                            return (
                                <StyledFormControlLabel
                                    key={index}
                                    control={
                                        operation === 'extend' ? (
                                            <StyledCheckbox
                                                checked={!!selectedParts[part.partNumber]}
                                                onChange={handleSelect}
                                                name={part.partNumber}
                                            />
                                        ) : (
                                            <StyledRadio
                                                onChange={(): void => setSelectedPart(part)}
                                                checked={part.partNumber === selectedPart?.partNumber}
                                                name={'replace-list'}
                                            />
                                        )
                                    }
                                    label={
                                        <div>
                                            {part.partNumber}{' '}
                                            {part.description && (
                                                <StyledDescription>
                                                    (<i>{part.description}</i>)
                                                </StyledDescription>
                                            )}
                                        </div>
                                    }
                                />
                            );
                        })}
                        {filteredCustomParts.length > 0 && <SectionTitle>User Added Parts:</SectionTitle>}
                        {filteredCustomParts.map((part, index) => {
                            return (
                                <StyledFormControlLabel
                                    isCustom
                                    key={index}
                                    control={
                                        operation === 'extend' ? (
                                            <StyledCheckbox
                                                checked={!!selectedCustomParts[part.part_number]}
                                                onChange={handleCustomPartSelect}
                                                name={part.part_number}
                                            />
                                        ) : (
                                            <StyledRadio
                                                onChange={(): void =>
                                                    setSelectedPart({
                                                        partNumber: part.part_number,
                                                        description: part.description,
                                                        isUserAdded: true,
                                                        portfolio: [],
                                                    })
                                                }
                                                checked={part.part_number === selectedPart?.partNumber}
                                                name={'replace-list'}
                                            />
                                        )
                                    }
                                    label={
                                        <div>
                                            {part.part_number}{' '}
                                            {part.description && (
                                                <StyledDescription>
                                                    (<i>{part.description}</i>)
                                                </StyledDescription>
                                            )}
                                        </div>
                                    }
                                />
                            );
                        })}
                    </StyledFormGroup>
                </StyledDialogContent>
                <DialogActions>
                    <StyledButton autoFocus onClick={(): void => setFilterType(initialFilters)} variant="contained">
                        CLEAR FILTER
                    </StyledButton>
                    <StyledButton autoFocus onClick={addParts} variant="contained">
                        CONFIRM
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        </Container>
    );
}
