import { css } from 'styled-components';

export const fontFamilies = {
    roboto: {
        light: 'Roboto-Light, sans-serif',
        regular: 'Roboto-Regular, sans-serif',
        medium: 'Roboto-Medium, sans-serif',
        bold: 'Roboto-Bold, sans-serif',
    },
} as const;

export type FontFamilies = typeof fontFamilies;

export const typography = {
    robotoLight24: css`
        font-family: ${fontFamilies.roboto.light};
        font-size: 1.5rem;
        font-weight: lighter;
    `,
    robotoLight32: css`
        font-family: ${fontFamilies.roboto.light};
        font-size: 2rem;
        font-weight: lighter;
    `,

    robotoRegular8: css`
        font-family: ${fontFamilies.roboto.regular};
        font-size: 0.5rem;
        font-weight: normal;
    `,

    robotoRegular12: css`
        font-family: ${fontFamilies.roboto.regular};
        font-size: 0.75rem;
        font-weight: normal;
    `,
    robotoRegular14: css`
        font-family: ${fontFamilies.roboto.regular};
        font-size: 0.875rem;
        font-weight: normal;
    `,
    robotoRegular16: css`
        font-family: ${fontFamilies.roboto.regular};
        font-size: 1rem;
        font-weight: normal;
    `,

    robotoMedium08: css`
        font-family: ${fontFamilies.roboto.medium};
        font-size: 0.5rem;
    `,
    robotoMedium10: css`
        font-family: ${fontFamilies.roboto.medium};
        font-size: 0.625rem;
    `,
    robotoMedium12: css`
        font-family: ${fontFamilies.roboto.medium};
        font-size: 0.75rem;
    `,
    robotoMedium14: css`
        font-family: ${fontFamilies.roboto.medium};
        font-size: 0.875rem;
    `,
    robotoMedium16: css`
        font-family: ${fontFamilies.roboto.medium};
        font-size: 1rem;
    `,

    robotoBold18: css`
        font-family: ${fontFamilies.roboto.bold};
        font-size: 1.125rem;
    `,
};

export type Typography = typeof typography;
