import React from 'react';
import { StyledSelect, SelectContainer, Label, StyledMenuItem, Container, Asterisk, Error } from './Select.style';

export interface SelectItem {
    value: number | string;
    label: string;
}

interface Props {
    items: SelectItem[];
    handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    value?: number | string;
    label: string;
    disabled?: boolean;
    variant?: 'outlined' | 'standard';
    placeholder?: string;
    required?: boolean;
    error?: string;
}

export default function SimpleSelect({
    items,
    handleChange,
    value,
    label,
    disabled = false,
    variant = 'outlined',
    placeholder,
    required = false,
    error = '',
}: Props): JSX.Element {
    return (
        <Container>
            <SelectContainer variant={variant} size="small" disabled={disabled}>
                <Label id="select-label" shrink={variant === 'standard' ? true : undefined}>
                    {label}
                    {required && <Asterisk>*</Asterisk>}
                </Label>
                <StyledSelect
                    labelId="select-label"
                    value={value}
                    onChange={handleChange}
                    variant={variant}
                    displayEmpty
                >
                    {placeholder && (
                        <StyledMenuItem value="" disabled>
                            {placeholder}
                        </StyledMenuItem>
                    )}
                    {items.map((item) => (
                        <StyledMenuItem key={item.value} value={item.value}>
                            {item.label}
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
                <Error>{error}</Error>
            </SelectContainer>
        </Container>
    );
}
