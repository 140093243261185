import React, { useState, useEffect } from 'react';
import { useFetch } from '../../hooks/useFetch';
import Title from '../../components/Title/Title';
import { getClientDetailsConfig, putClientDetailsConfig, GetClientDetailsResponse } from '../../constants/apiConfig';
import NavigationPanel from './components/NavigationPannel/NavigationPanel';
import EditClientData from './components/EditClientData/EditClientData';
import DisplayClientData from './components/DisplayClientData/DisplayClientData';

import * as S from './ClientOnboarding.style';
import { ClientDetails } from './ClientOnboarding.config';

function ClientOnboarding(): JSX.Element {
    const [getClientStatus, getClient] = useFetch<GetClientDetailsResponse>(getClientDetailsConfig, true);
    const [putClientStatus, putClient] = useFetch<string>(putClientDetailsConfig);

    const [isEditMode, setIsEditMode] = useState(false);
    const [isFirstSetup, setIsFirstSetup] = useState(false);
    const [isToolContactModelOpen, setIsToolContactModelOpen] = useState(false);

    useEffect(() => {
        if (getClientStatus.loading === false && getClientStatus.data) {
            setIsEditMode(getClientStatus.data.client.onboarding_status !== 'SUBMITTED');
            setIsFirstSetup(getClientStatus.data.client.onboarding_status !== 'SUBMITTED');
        }
    }, [getClientStatus.data, getClientStatus.loading]);

    const handleSave = async (clientDetails: ClientDetails): Promise<void> => {
        if (clientDetails?.logo?.length === 0) {
            delete clientDetails.logo;
        }
        await putClient({ data: { ...clientDetails, onboarding_status: 'SAVED' } });
        getClient();
    };

    const handleSubmit = async (clientDetails: ClientDetails): Promise<void> => {
        if (clientDetails?.logo?.length === 0) {
            delete clientDetails.logo;
        }
        await putClient({ data: { ...clientDetails, onboarding_status: 'SUBMITTED' } });
        getClient();
    };

    const handleCancel = (): void => {
        setIsEditMode(false);
    };

    const handleEdit = (): void => {
        setIsEditMode(true);
    };

    const handleToolContactOpen = (): void => {
        setIsToolContactModelOpen(true);
    };

    const handleToolContactClose = (): void => {
        setIsToolContactModelOpen(false);
    };

    return (
        <S.Container>
            <Title title="Home" />
            <S.BodyContainer>
                <NavigationPanel
                    isEditMode={isEditMode}
                    handleEdit={handleEdit}
                    handleToolContactOpen={handleToolContactOpen}
                />
                {isEditMode ? (
                    <EditClientData
                        clientDetails={getClientStatus.data?.client}
                        putClientLoading={putClientStatus.loading}
                        handleSave={handleSave}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                        isFirstSetup={isFirstSetup}
                        handleToolContactClose={handleToolContactClose}
                        isToolContactModelOpen={isToolContactModelOpen}
                    />
                ) : (
                    <DisplayClientData
                        clientDetails={getClientStatus.data?.client}
                        getClientLoading={getClientStatus.loading}
                        handleToolContactClose={handleToolContactClose}
                        isToolContactModelOpen={isToolContactModelOpen}
                    />
                )}
            </S.BodyContainer>
        </S.Container>
    );
}

export default ClientOnboarding;
