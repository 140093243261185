import React from 'react';
import Button, { ButtonType } from '../../../../components/Button/Button';
import { useHistory } from 'react-router-dom';

import * as S from './NavigationPanel.style';

type Props = {
    handleEdit: () => void;
    isEditMode: boolean;
    handleToolContactOpen: () => void;
};

function NavigationPanel({ handleEdit, isEditMode, handleToolContactOpen }: Props): JSX.Element {
    const history = useHistory();

    return (
        <S.Container>
            <Button buttonType={ButtonType.SECONDARY} onClickHandler={handleEdit} disabled={isEditMode}>
                EDIT CLIENT PROFILE
            </Button>
            <Button
                buttonType={ButtonType.SECONDARY}
                onClickHandler={(): void => history.push('/userManagement')}
                disabled={isEditMode}
            >
                USER MANAGEMENT
            </Button>
            <Button
                buttonType={ButtonType.SECONDARY}
                onClickHandler={(): void => history.push('/dataManagement')}
                disabled={isEditMode}
            >
                DATA MANAGEMENT
            </Button>
            <Button
                buttonType={ButtonType.SECONDARY}
                onClickHandler={(): void => history.push('/')}
                disabled={isEditMode}
            >
                USER JOURNEY
            </Button>
            <S.Divider />
            <Button buttonType={ButtonType.SECONDARY} onClickHandler={handleToolContactOpen}>
                TOOL CONTACT
            </Button>
        </S.Container>
    );
}

export default NavigationPanel;
