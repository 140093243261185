import styled, { css } from 'styled-components';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        padding: 1.5rem 1rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundPrimary};
    `,
);

export const BodyContainer = styled.div`
    display: flex;
    height: 100%;
    margin-top: 1.5rem;
`;
