import React from 'react';
import { StyledDialog, StyledDialogTitle, StyledDialogContent, StyledIconButton } from './Modal.style';
import CloseIcon from '@material-ui/icons/Close';

interface DialogTitleProps {
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps): JSX.Element => {
    const { children, onClose } = props;
    return (
        <StyledDialogTitle disableTypography>
            {children}
            {onClose ? (
                <StyledIconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </StyledIconButton>
            ) : null}
        </StyledDialogTitle>
    );
};

interface Props {
    handleClose: () => void;
    children: React.ReactNode;
    isOpen: boolean;
    title: string;
    disableMaxWidth?: boolean;
    scroll?: 'body' | 'paper';
}

export default function StyledModal({
    title,
    handleClose,
    children,
    isOpen,
    disableMaxWidth = false,
    scroll = 'paper',
}: Props): JSX.Element {
    return (
        <StyledDialog
            onClose={handleClose}
            transitionDuration={0}
            scroll={scroll}
            open={isOpen}
            maxWidth={disableMaxWidth ? false : 'md'}
        >
            <DialogTitle onClose={handleClose}>{title}</DialogTitle>
            <StyledDialogContent>{children}</StyledDialogContent>
        </StyledDialog>
    );
}
