import { Mid, Widget } from '@mid/react-widget/redirect';
import { oktaDriver } from '@mid/driver-okta';
import { Mid as MidType, MidDriverCore } from '@mid/sdk';

function buildUrl(base: string, endpoint: string, params: Record<string, string>): string {
    const queryParams = Object.keys(params)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');
    return `${base}/${endpoint}?${queryParams}`;
}

export const midBuilder = (config: any): MidType => {
    const emailToLoginInfo = async (email: string) => {
        const url = buildUrl(config.apiServer, 'endpoints/login', { email });
        const res = await fetch(url.toString());
        if (!res.ok) {
            throw new Error('Unable to get login info!');
        }
        const loginInfo = await res.json();
        return loginInfo;
    };

    async function tenantListing(email: string, accessToken: string) {
        const url = buildUrl(config.apiServer, 'endpoints/tenants', { email });
        const res = await fetch(url.toString(), { headers: { Authorization: `Bearer ${accessToken}` } });
        if (!res.ok) {
            /**
             * What is happening here?
             *
             * If the /tenants API fails for any reason, we use the login info
             * as the tenant info (because this is a single tenant app)
             * Using that info, we forcibly log the user out of the application.
             *
             * Why?
             *
             * This is a way to work around the limitation of the mid-sdk
             * Discussed here: https://mckinsey-client-cap.slack.com/archives/C9XECT5NY/p1597216719004000
             *
             * Below, you can see `this` being used. don't worry about it.
             * We are binding this function to the `mid` at the bottom of this file
             */
            const loginInfo = JSON.parse(localStorage.getItem('_mid-login') as string);
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            await this.core.setTenant({
                ...loginInfo,
                displayName: 'AIMargin Tribe Cost of Complexity',
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            await this.logout();
            throw new Error('Unable to get tenants!');
        }
        const tenants = await res.json();
        return tenants;
    }

    const setCookie = async (props: any) => {
        let headers = {};
        if (props) {
            const { tenant, accessToken } = props;
            headers = { 'x-tenant': tenant, Authorization: `Bearer ${accessToken}` };
        }
        const url = `${config.apiServer}/endpoints/set-cookie`;
        const res = await fetch(url.toString(), { credentials: 'include', headers });
        if (!res.ok) {
            throw new Error('Unable to set cookie!');
        }
    };

    const port = `${window.location.port ? ':' + window.location.port : ''}`;
    const host = `${window.location.protocol}//${window.location.hostname}${port}`;
    const routerBase = () => {
        const path = new URL(document.baseURI).pathname;
        if (path) return path;
        return '/';
    };

    const basePath = config.basePath || routerBase();

    const mid: MidType = new Mid({
        emailToLoginInfo,
        tenantListing,
        setCookie: config.enableCookieAuth ? setCookie : undefined,
        loginWidget: new Widget(),
        logoutRedirectUrl: `${host}${basePath}logout`,
        redirectUrl: `${host}${basePath}auth/callback`,
        scopes: ['profile'],
        drivers: { okta: oktaDriver },
        mode: 'default',
        ...config.mid,
    });

    (mid.core as MidDriverCore).tenantListing = tenantListing.bind(mid);
    return mid;
};
