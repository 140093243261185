import styled, { css } from 'styled-components';
import { Checkbox } from '@material-ui/core';

export const HierarchyDetails = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        border-radius: 10px;
        background-color: ${theme.colors.greyScaleLight};
        > div:first-child {
            padding: 0.5rem 0.75rem;
            flex-basis: 100%;
        }
        > div {
            padding: 1rem 0.75rem;
            flex-basis: 50%;
        }
        > h5 {
            padding: 1rem 0.75rem 0 0.75rem;
            flex-basis: 100%;
        }
    `,
);

export const HierarchyDetailsContainer = styled.div(
    ({ theme }) => css`
        padding: 0.5rem;
        > div:nth-of-type(even) {
            margin-bottom: 1rem;
        }
        > div:nth-of-type(odd) {
            margin-bottom: 1rem;
        }
    `,
);

export const StyledCheckbox = styled(Checkbox)(
    ({ theme }) => css`
        &.MuiCheckbox-colorSecondary {
            color: ${theme.colors.accentPrimary};
        }
    `,
);
