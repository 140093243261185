import styled, { css } from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import ReplaceIcon from '@material-ui/icons/FindReplaceOutlined';
import PasteIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

export const Container = styled(Paper)(
    ({ theme }) => css`
        && {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-self: center;
            background-color: ${theme.colors.backgroundPrimary};
        }
    `,
);

export const PageHeader = styled.div(
    () => css`
        && {
            display: flex;
            justify-content: space-between;
            margin: 2%;
        }
    `,
);

export const PageHeaderBody = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            justify-content: flex-start;
            width: 50%;
            align-items: center;
            color: ${theme.colors.backgroundDark};
        }
    `,
);

export const PageHeaderBodyRight = styled.div(
    ({ theme }) => css`
        && {
            display: flex;
            justify-content: flex-end;
            width: 20%;
            align-items: center;
            color: ${theme.colors.backgroundDark};
        }
    `,
);

export const CardContainer = styled.div`
    display: grid;
    height: 100%;
    overflow: auto;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px 15px;
    justify-items: stretch;
    align-items: stretch;
    padding: 15px;
`;

interface CellParams {
    gridColumnStart?: number;
    gridColumnEnd?: number;
    gridRowStart?: number;
    gridRowEnd?: number;
    secondaryBackground?: boolean;
}
export const Card = styled(Paper)(
    ({ theme }) => css`
        > div {
            margin-top: 2rem;
        }
        min-height: 0;
        max-height: 750px;
        overflow: auto;
        border-radius: 15px;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        grid-column-start: ${(props: CellParams): number | string => props.gridColumnStart || 'auto'};
        grid-column-end: ${(props: CellParams): number | string => props.gridColumnEnd || 'auto'};
        grid-row-start: ${(props: CellParams): number | string => props.gridRowStart || 'auto'};
        grid-row-end: ${(props: CellParams): number | string => props.gridRowEnd || 'auto'};
        background-color: ${(props: CellParams): string =>
            props.secondaryBackground ? theme.colors.backgroundDark : theme.colors.backgroundLight};
    `,
);

export const ModuleInfoLabel = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            margin-top: 10%;
            font-size: 80%;
            font-weight: lighter;
        }
    `,
);

export const ModuleInfoTitleSmall = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.textLight};
            margin-top: 5%;
            font-size: 80%;
            font-weight: normal;
        }
    `,
);

export const ModuleInfoText = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextDark};
            margin-top: 2%;
            font-size: 80%;
            font-weight: lighter;
        }
    `,
);

export const ModuleInfoTitle = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.textLight};
            margin-top: 10%;
            font-size: 80%;
            font-weight: normal;
        }
    `,
);

export const ModuleInfoLabelSmall = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextLight};
            margin-top: 5%;
            font-size: 80%;
            font-weight: lighter;
        }
    `,
);

export const ModuleInfoLabelTable = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextLight};
            margin-top: 5%;
            font-size: 12px;
            font-weight: lighter;
        }
    `,
);

export const ModuleInfoTextDark = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.subtextLight};
            margin-top: 2%;
            font-size: 80%;
            font-weight: lighter;
        }
    `,
);

export const StyledPartContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
    margin-bottom: 1em;
`;

export const StyledPartHeader = styled.div<{ leftAlign: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: ${(props): string => (props.leftAlign ? '' : 'space-evenly')};
    font-size: 10px;
    margin-bottom: 1em;
`;

export const PartOptionsContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-radius: 10px;
        background-color: ${theme.colors.greyScaleMedium};
        z-index: 100;
    `,
);

export const VerticalSeparator = styled.div(
    ({ theme }) => css`
        width: 1px;
        background-color: ${theme.colors.subtextDark};
        height: 100%;
    `,
);

export const FileCopyOutlinedIcon = styled(CopyIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const FindReplaceOutlinedIcon = styled(ReplaceIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const DescriptionOutlinedIcon = styled(PasteIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const DeleteOutlineOutlinedIcon = styled(DeleteIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const UniquePartsContainer = styled(Typography)(
    ({ theme }) => css`
        && {
            margin-top: 2%;
            font-size: 10px;
            font-weight: lighter;
            color: ${theme.colors.textSalmon};
        }
    `,
);

export const AddPartContainer = styled.div`
    display: flex;
    width: 100%;
    > div {
        flex-basis: 50%;
    }
`;
export const SelectPartActions = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: auto;
        flex: 1;
        padding: 1.5rem 0;
        align-self: flex-end;
    `,
);

export const ViewAnalysisButtonContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-self: flex-end;
    `,
);

export const ExpansionPanelItem = styled.div(
    ({ theme }) => css`
        width: 100%;
        margin-top: 5%;
    `,
);
