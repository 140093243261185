import React, { ReactNode } from 'react';
import { AggregatedAnalysisData } from '../../pages/ProjectExplorer/ProjectExplorer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Metric from '../Metric/Metric';
import GroupedMetric from '../GroupedMetric/GroupedMetric';

import * as S from './ProjectMetrics.style';

interface Props {
    data?: AggregatedAnalysisData;
    loading: boolean;
    error: boolean;
}

export default function ProjectMetrics({ data, loading, error }: Props): JSX.Element {
    const renderMetrics = (): ReactNode => {
        if (loading) {
            return (
                <S.CenteredElement>
                    <CircularProgress />
                </S.CenteredElement>
            );
        }
        if (error) {
            return <S.CenteredElement>ERROR: UNABLE TO LOAD METRICS</S.CenteredElement>;
        }
        if (!data) {
            return <S.CenteredElement>SELECT A PROJECT</S.CenteredElement>;
        }

        return (
            <>
                <S.ContainerDark>
                    <S.ColumnHeader>
                        <div>OVERALL</div> <div>* INR LAKH</div>
                    </S.ColumnHeader>
                    <Metric
                        value={(
                            data?.dmc_result +
                            data?.scale_benefit_result +
                            data?.warranty_cost +
                            data?.rejection_cost +
                            data?.logistics_cost +
                            data?.inventory_cost
                        ).toFixed(2)}
                        label="TOTAL RECURRING IMPACT"
                        showUnitMarker
                        dark
                    />
                    <Metric
                        value={(
                            data?.tooling_investment +
                            data?.design_investment +
                            data?.line_investment +
                            data?.other_investment
                        ).toFixed(2)}
                        label="TOTAL INVESTMENT"
                        showUnitMarker
                        dark
                    />
                    <S.MetricRow>
                        <Metric value={Number(data?.payback_period).toFixed(2)} label="PAYBACK PERIOD (YEARS)" dark />
                        <Metric
                            value={Number(data?.cash_flow_release_result).toFixed(2)}
                            label="CASH RELEASE"
                            showUnitMarker
                            dark
                        />
                    </S.MetricRow>
                    <S.MetricRow>
                        <Metric value={Number(data?.npv).toFixed(2)} label="NPV" showUnitMarker dark />
                        <Metric value={Number(data?.irr).toFixed(2)} label="IRR" dark />
                    </S.MetricRow>
                </S.ContainerDark>
                <S.ContainerLight>
                    <S.ColumnHeader dark>
                        <div>ONE TIME INVESTMENT</div> <div>* INR LAKH</div>
                    </S.ColumnHeader>
                    <GroupedMetric
                        title="Integration Design Investments *"
                        values={[
                            { label: 'total', value: data?.design_investment_total ?? 0, distinctColor: true },
                            { label: 'module', value: data?.design_investment ?? 0 },
                            { label: 'integration', value: data?.design_investment_integration ?? 0 },
                        ]}
                    />
                    <GroupedMetric
                        title="Tooling Investments *"
                        values={[
                            { label: 'total', value: data?.tooling_investment_total ?? 0, distinctColor: true },
                            { label: 'module', value: data?.tooling_investment ?? 0 },
                        ]}
                    />
                    <GroupedMetric
                        title="Line Investments *"
                        values={[
                            { label: 'total', value: data?.line_investment_total ?? 0, distinctColor: true },
                            { label: 'module', value: data?.line_investment ?? 0 },
                            { label: 'integration', value: data?.line_investment_integration ?? 0 },
                        ]}
                    />
                    <GroupedMetric
                        title="Other Investments *"
                        values={[
                            { label: 'total', value: data?.other_investment_total ?? 0, distinctColor: true },
                            { label: 'module', value: data?.other_investment ?? 0 },
                            { label: 'integration', value: data?.other_investment_integration ?? 0 },
                        ]}
                    />
                </S.ContainerLight>
            </>
        );
    };
    return <S.Container>{renderMetrics()}</S.Container>;
}
