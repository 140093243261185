import React, { useState, useEffect } from 'react';
import {
    clientInfoConfig,
    ClientDetails,
    initialClientDetails,
    clientContactConfig,
    firmContactConfig,
    mandatoryFields,
} from '../../ClientOnboarding.config';
import Select from '../../../../components/Select/Select';
import TextInput from '../../../../components/TextInput/TextInput';
import Checkbox from '@material-ui/core/Checkbox';
import Button, { ButtonType } from '../../../../components/Button/Button';
import EditToolContactModal from '../EditToolContact/EditToolContact';

import * as S from './EditClientData.style';

type Props = {
    clientDetails: ClientDetails | undefined;
    putClientLoading: boolean;
    handleSave: (clientDetails: ClientDetails) => void;
    handleSubmit: (clientDetails: ClientDetails) => void;
    handleCancel: () => void;
    isFirstSetup: boolean;
    handleToolContactClose: () => void;
    isToolContactModelOpen: boolean;
};

function EditClientData({
    clientDetails,
    putClientLoading,
    handleSave,
    handleSubmit,
    handleCancel,
    isFirstSetup,
    handleToolContactClose,
    isToolContactModelOpen,
}: Props): JSX.Element {
    const [newClientDetails, setNewClientDetails] = useState<ClientDetails>(initialClientDetails);
    const [showValidations, setShowValidations] = useState(false);

    useEffect(() => {
        if (clientDetails) {
            const getClientData = Object.keys(clientDetails).reduce(
                (acc, key) =>
                    clientDetails[key as keyof ClientDetails] === null
                        ? acc
                        : { ...acc, [key]: clientDetails[key as keyof ClientDetails] ?? '' },
                {} as Partial<ClientDetails>,
            );
            setNewClientDetails({ ...initialClientDetails, ...getClientData });
        }
    }, [clientDetails]);

    const handleChange = (key: keyof ClientDetails) => (
        event: React.ChangeEvent<{ name?: string; value: unknown }>,
    ): void => {
        const value = (event?.target?.value as string) ?? '';
        setNewClientDetails((prevValue) => ({ ...prevValue, [key]: value }));
    };

    const handleCheckBox = (): void => {
        if (isFirstSetup) {
            setNewClientDetails((prevValue) => ({ ...prevValue, enable_industry: !prevValue.enable_industry }));
        }
    };

    const handleUpload = (e: any): void => {
        const file = e.target.files[0] as File;
        const reader = new FileReader();
        reader.onloadend = (): void => {
            setNewClientDetails((prevValue) => ({ ...prevValue, logo: reader.result as string }));
        };
        reader.readAsDataURL(file);
    };

    const validate = (): void => {
        setShowValidations(true);
        if (!mandatoryFields.find((key) => newClientDetails[key] === '')) {
            setShowValidations(false);
            handleSubmit(newClientDetails);
        }
    };

    return (
        <S.Container>
            <EditToolContactModal
                isOpen={isToolContactModelOpen}
                handleClose={handleToolContactClose}
                handleChange={handleChange}
                newClientDetails={newClientDetails}
            />
            <S.HeaderContainer>
                <S.NameAndAddressContainer>
                    <TextInput
                        value={newClientDetails.name}
                        handleChange={handleChange('name')}
                        label="NAME"
                        placeholder="Enter company name"
                        required
                        dark
                        disabled={!isFirstSetup}
                        error={showValidations && !newClientDetails.name ? 'Required!' : ''}
                    />
                    <TextInput
                        value={newClientDetails.address}
                        handleChange={handleChange('address')}
                        label="ADDRESS"
                        placeholder="Enter company address"
                        dark
                        disabled={!isFirstSetup}
                    />
                </S.NameAndAddressContainer>
                <S.UploadContainer onChange={handleUpload}>
                    <input id="fileUpload" type="file" accept=".png,.jpg" hidden />
                    <S.Label htmlFor="fileUpload">
                        {newClientDetails.logo || newClientDetails?.logo?.length !== 0 ? (
                            <S.Img src={newClientDetails.logo} />
                        ) : (
                            'ADD COMPANY LOGO'
                        )}
                    </S.Label>
                </S.UploadContainer>
            </S.HeaderContainer>
            <S.ClientDetailsContainer>
                {clientInfoConfig.map((config) =>
                    config.type === 'SELECT' ? (
                        <Select
                            key={config.key}
                            label={config.label}
                            items={config.options ?? []}
                            value={newClientDetails[config.key].toString()}
                            handleChange={handleChange(config.key)}
                            disabled={!isFirstSetup}
                            variant="standard"
                            placeholder={config.placeholder}
                            required={config.mandatory}
                            error={
                                config.mandatory && showValidations && !newClientDetails[config.key] ? 'Required!' : ''
                            }
                        />
                    ) : (
                        <TextInput
                            key={config.key}
                            value={newClientDetails[config.key].toString()}
                            handleChange={handleChange(config.key)}
                            label={config.label}
                            placeholder={config.placeholder}
                            required={config.mandatory}
                            disabled={!isFirstSetup}
                            error={
                                config.mandatory && showValidations && !newClientDetails[config.key] ? 'Required!' : ''
                            }
                        />
                    ),
                )}
            </S.ClientDetailsContainer>
            <S.CheckListItem onClick={handleCheckBox}>
                <Checkbox
                    checked={newClientDetails.enable_industry}
                    checkedIcon={isFirstSetup ? <S.CheckedIcon /> : <S.DisabledCheckedIcon />}
                    color="primary"
                />
                Enable industry/sub-industry
            </S.CheckListItem>
            <S.ClientDetailsContainer>
                <TextInput
                    value={newClientDetails.charge_code}
                    handleChange={handleChange('charge_code')}
                    label="CHARGE CODE"
                    placeholder="Enter charge code"
                    required
                    error={showValidations && !newClientDetails.charge_code ? 'Required!' : ''}
                />
            </S.ClientDetailsContainer>
            <S.ContactDetailsContainer>
                <S.SectionTitle>CLIENT POINTS OF CONTACT</S.SectionTitle>
                {clientContactConfig.map((contactConfig) => (
                    <>
                        <S.ContactTitle>
                            {contactConfig.label} {contactConfig.mandatory && <S.Asterisk>*</S.Asterisk>}
                        </S.ContactTitle>
                        {contactConfig.inputs.map((config) => (
                            <TextInput
                                key={`${config.key}${contactConfig.label}`}
                                handleChange={handleChange(config.key)}
                                placeholder={config.placeholder}
                                value={newClientDetails[config.key].toString()}
                                required={contactConfig.mandatory}
                                error={
                                    contactConfig.mandatory && showValidations && !newClientDetails[config.key]
                                        ? 'Required!'
                                        : ''
                                }
                            />
                        ))}
                    </>
                ))}
            </S.ContactDetailsContainer>
            <S.ContactDetailsContainer>
                <S.SectionTitle>FIRM POINTS OF CONTACT</S.SectionTitle>
                {firmContactConfig.map((contactConfig) => (
                    <>
                        <S.ContactTitle>
                            {contactConfig.label} {contactConfig.mandatory && <S.Asterisk>*</S.Asterisk>}
                        </S.ContactTitle>
                        {contactConfig.inputs.map((config) => (
                            <TextInput
                                key={`${config.key}${contactConfig.label}`}
                                handleChange={handleChange(config.key)}
                                placeholder={config.placeholder}
                                value={newClientDetails[config.key].toString()}
                                required={contactConfig.mandatory}
                                error={
                                    contactConfig.mandatory && showValidations && !newClientDetails[config.key]
                                        ? 'Required!'
                                        : ''
                                }
                            />
                        ))}
                    </>
                ))}
            </S.ContactDetailsContainer>
            <S.ButtonContainer>
                <div>
                    <S.Asterisk>*</S.Asterisk> Mandatory Fields
                </div>
                {!isFirstSetup && (
                    <Button buttonType={ButtonType.TEXT} onClickHandler={(): void => handleCancel()}>
                        CANCEL
                    </Button>
                )}
                {isFirstSetup && (
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        onClickHandler={(): void => handleSave(newClientDetails)}
                        disabled={putClientLoading}
                    >
                        SAVE
                    </Button>
                )}
                <Button buttonType={ButtonType.PRIMARY} onClickHandler={validate} disabled={putClientLoading}>
                    SUBMIT
                </Button>
            </S.ButtonContainer>
        </S.Container>
    );
}

export default EditClientData;
