import React, { createContext, useReducer } from 'react';
import { GlobalStore, Action, Reducer } from './store.types';
import { reducer } from './store.reducer';

const defaultGlobalStore = new GlobalStore();
const defaultContextValue = {
    state: defaultGlobalStore,
    // Need the next line for type inference. Pls Update if you find a better way
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dispatch: (action: Action): void => {
        // do nothing
    },
};

interface Props {
    children: React.ReactNode;
}

export const GlobalStoreContext = createContext(defaultContextValue);
const { Provider } = GlobalStoreContext;

export const GlobalStoreProvider = ({ children }: Props): JSX.Element => {
    const [state, dispatch] = useReducer<Reducer>(reducer, defaultGlobalStore);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
