import React from 'react';
import styled, { css } from 'styled-components';
import Select from '@material-ui/core/Select';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import { CheckboxProps, Checkbox, Typography } from '@material-ui/core';
import { colors } from '../../../styles/colors';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import ReplaceIcon from '@material-ui/icons/FindReplaceOutlined';
import PasteIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Theme, createStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PasteAcrossIcon from '@material-ui/icons/ArrowForwardIos';

export const Container = styled(Box)(
    () => css`
        && {
            margin: 1em;
            width: 500px;
        }
    `,
);

export const StyledSelect = styled(Select)(
    ({ theme }) => css`
        && {
            min-width: 150px;
            height: 40px;
            .MuiSelect-icon {
                color: ${theme.colors.accentPrimary};
            }
        }
    `,
);

export const StyledDialogTitle = styled(MuiDialogTitle)(
    ({ theme }) => css`
        && {
            color: #070c80;
            font-weight: bold;
            padding: 8px 12px;
            margin: 0;
        }
    `,
);

export const StyledDialog = withStyles({
    root: {
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        margin: '1em',
    },
    paper: {
        padding: '15px',
        borderRadius: '16px',
    },
})(Dialog);

export const StyledTextField = {
    inputStyle: {
        padding: '10px',
        background: colors.subtextDark,
    },
    containerStyle: {
        margin: '0.5em 0',
    },
};

export const StyledIconButton = styled(IconButton)(
    ({ theme }) => css`
        && {
            position: absolute;
            right: 1px;
            top: 1px;
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const StyledDialogContent = styled(MuiDialogContent)(
    ({ theme }) => css`
        && {
            display: flex;
            flex-direction: column;
            padding: 10px;
            min-width: 40vh;
            height: 50vh;
            max-height: 50vh;
        }
    `,
);

export const SelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
`;

export const DialogActions = styled(MuiDialogActions)(
    ({ theme }) => css`
        && {
            margin: 0;
            justify-content: space-between;
        }
    `,
);

export const StyledButton = styled(({ width, ...other }) => <Button {...other} />)`
    && {
        background-color: ${colors.accentPrimary};
        color: #fff;
        width: ${(props) => props.width};
    }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
    && {
        width: 100%;
    }
`;

export const StyledCheckbox = withStyles({
    root: {
        color: '#000',
        '&$checked': {
            color: colors.accentPrimary,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const StyledFormGroup = styled(FormGroup)(
    ({ theme }) => css`
        && {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            max-height: 30vh;
            margin-top: 1em;
            overflow: auto;
            color: ${theme.colors.textLight};
        }
    `,
);

export const StyledTitle = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.textLight};
        }
    `,
);

export const HeaderContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    `,
);

export const ModalTitle = styled(Typography)(
    ({ theme }) => css`
        font-size: 1.5rem;
    `,
);

export const ModalSubTitle = styled(Typography)(
    ({ theme }) => css`
        font-size: 1rem;
        font-weight: lighter;
    `,
);

export const IndustrySelectContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 30%;
    `,
);

export const IndustrySelectBox = styled.div(
    ({ theme }) => css`
        width: 40%;
        ${theme.typography.robotoRegular8};
        > div {
            margin-top: 1%;
        }
    `,
);

export const PartsContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 2%;
        width: 100%;
    `,
);

export const PartsBox = styled.div(
    ({ theme }) => css`
        width: 50%;
    `,
);

export const PartsBoxTitle = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        margin-bottom: 2%;
    `,
);

export const TableHeader = styled.div(
    ({ theme }) => css`
        height: 10%;
        width: 100%;
        background-color: ${theme.colors.backgroundDark};
    `,
);

export const NewPartText = styled(Typography)(
    ({ theme }) => css`
        font-size: 0.9rem;
        margin-top: 5%;
        cursor: pointer;
        color: ${theme.colors.textSalmon};
    `,
);

export const PartOptionsContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-radius: 10px;
        z-index: 100;
    `,
);

export const VerticalSeparator = styled.div(
    ({ theme }) => css`
        width: 1px;
        background-color: ${theme.colors.subtextDark};
        height: 100%;
    `,
);

export const FileCopyOutlinedIcon = styled(CopyIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const FindReplaceOutlinedIcon = styled(ReplaceIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const DescriptionOutlinedIcon = styled(PasteIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const DeleteOutlineOutlinedIcon = styled(DeleteIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const PasteAcrossOutlinedIcon = styled(PasteAcrossIcon)(
    ({ theme }) => css`
        && {
            transform: scale(0.6);
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: colors.backgroundDark,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
            borderBottom: 'none',
        },
    }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.common.white,
            },
        },
    }),
)(TableRow);

export const TableSubHeader = styled(Typography)(
    ({ theme }) => css`
        font-size: 0.5rem;
        font-weight: lighter;
    `,
);

export const ToolIcon = styled(MoreVertIcon)(
    ({ theme }) => css`
        color: ${theme.colors.textSalmon};
    `,
);

export const AddNewPartContainer = styled.div`
    width: 20%;
`;

export const Asterisk = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.accentSecondary};
    `,
);
