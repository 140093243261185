import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const Container = styled(Box)(
    () => css`
        margin: 1rem;
    `,
);

export const StyledDialogTitle = styled(MuiDialogTitle)(
    ({ theme }) => css`
        background-color: ${theme.colors.backgroundDark};
        color: ${theme.colors.textDark};
        font-weight: bold;
        padding: 5% 5%;
        margin: 0;
        ${theme.typography.robotoLight32}
    `,
);

export const StyledDialog = styled(Dialog)(
    () => css`
        .MuiDialog-root {
            margin: 1em;
            box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
        }
        .MuiDialog-paper {
            border-radius: 10px;
        }
    `,
);

export const StyledIconButton = styled(IconButton)(
    ({ theme }) => css`
        position: absolute;
        right: 1px;
        top: 1px;
        color: ${theme.colors.accentPrimary};
    `,
);

export const StyledDialogContent = styled(MuiDialogContent)(
    () => css`
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 60vh;
        min-height: 10vh;
        padding-left: 1rem;
        padding-right: 1rem;
        > div {
            margin-top: 1rem;
        }
    `,
);

export const TitleContainer = styled.div(
    () => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    `,
);

export const Status = styled.div<{ userStatus?: 'ACTIVE' | 'EXPIRED' | 'DISABLED' | 'NEW' }>(
    ({ userStatus, theme }) => css`
        border: 1px solid ${userStatus === 'ACTIVE' ? '#23b619' : userStatus === 'DISABLED' ? '#ff9900' : '#ff5b6b'};
        color: ${userStatus === 'ACTIVE' ? '#23b619' : userStatus === 'DISABLED' ? '#ff9900' : '#ff5b6b'};
        ${theme.typography.robotoMedium12};
        border-radius: 5px;
        height: 1%;
        padding: 1%;
        width: 14%;
        text-align: center;
    `,
);

export const ContactDetails = styled.div(
    ({ theme }) => css`
        display: flex;
        ${theme.typography.robotoRegular12};
        > div {
            margin-right: 5%;
        }
    `,
);

export const AccountInfo = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        ${theme.typography.robotoRegular12};
        > div {
            padding: 0.4rem 0.5rem;
            margin-bottom: 0.2rem;
            flex-basis: 50%;
        }
    `,
);

export const StyledDatePicker = styled(KeyboardDatePicker)(
    ({ theme }) => css`
        color: ${theme.colors.accentPrimary};
        margin-top: 0.5rem;
        > label {
            margin-left: 0.4rem;
        }
    `,
);

export const FlexRow = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: flex-start;
        ${theme.typography.robotoRegular12};
        padding: 0 0.5rem;
        margin-top: 0 !important;
        flex-wrap: wrap;
        > div {
            flex-basis: 50%;
            margin-bottom: 0.5rem;
        }
    `,
);

export const ActionButtons = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        color: ${theme.colors.accentPrimary};
        align-items: center;
        > div:last-child {
            width: 20%;
            margin-left: 5%;
        }
    `,
);
