export enum Roles {
    DATA_ADMIN = 'coc:data_admin',
    MODULE_LEAD = 'coc:module_lead',
    PLATFORM_LEAD = 'coc:platform_lead',
    SUPER_USER = 'coc:super_user',
}

export const LandingPath = [
    {
        role: Roles.DATA_ADMIN,
        path: '/dataManagement',
    },
    {
        role: Roles.MODULE_LEAD,
        path: '/MLOverview',
    },
    {
        role: Roles.PLATFORM_LEAD,
        path: '/PLOverview',
    },
    {
        role: Roles.SUPER_USER,
        path: '/home',
    },
];

export const AccessConfig = [
    {
        path: '/auth/callback',
        accessRoles: [Roles.SUPER_USER, Roles.MODULE_LEAD, Roles.PLATFORM_LEAD, Roles.DATA_ADMIN],
    },
    { path: '/', accessRoles: [Roles.SUPER_USER] },
    { path: '/MLOverview', accessRoles: [Roles.SUPER_USER, Roles.MODULE_LEAD] },
    { path: '/PLOverview', accessRoles: [Roles.SUPER_USER, Roles.PLATFORM_LEAD] },
    { path: '/newAnalysis/:analysisId', accessRoles: [Roles.SUPER_USER, Roles.MODULE_LEAD] },
    { path: '/quickAnalysis/:analysisId', accessRoles: [Roles.SUPER_USER, Roles.MODULE_LEAD, Roles.PLATFORM_LEAD] },
    { path: '/analysisSummary/:analysisId', accessRoles: [Roles.SUPER_USER, Roles.MODULE_LEAD] },
    {
        path: '/quickAnalysisSummary/:analysisId',
        accessRoles: [Roles.SUPER_USER, Roles.MODULE_LEAD, Roles.PLATFORM_LEAD],
    },
    { path: '/analysisExplorer', accessRoles: [Roles.SUPER_USER, Roles.MODULE_LEAD] },
    { path: '/newProject/:projectId', accessRoles: [Roles.SUPER_USER, Roles.PLATFORM_LEAD] },
    { path: '/ProjectExplorer', accessRoles: [Roles.SUPER_USER, Roles.PLATFORM_LEAD] },
    { path: '/moduleExplorer', accessRoles: [Roles.SUPER_USER, Roles.PLATFORM_LEAD] },
    { path: '/dataView', accessRoles: [Roles.SUPER_USER, Roles.DATA_ADMIN] },
    { path: '/userManagement', accessRoles: [Roles.SUPER_USER] },
    { path: '/projectSummary', accessRoles: [Roles.SUPER_USER, Roles.PLATFORM_LEAD] },
    { path: '/dataManagement', accessRoles: [Roles.SUPER_USER, Roles.DATA_ADMIN] },
    { path: '/home', accessRoles: [Roles.SUPER_USER] },
    { path: '/landing', accessRoles: [Roles.SUPER_USER] },
];
