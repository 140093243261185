import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        padding: 1.5rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.backgroundLight};
        ${theme.typography.robotoRegular12};
    `,
);

export const CheckListItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin-top: 1rem;
`;

export const CenteredElement = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledCheckbox = styled(Checkbox)(
    ({ theme }) => css`
        color: ${theme.colors.accentPrimary};
        padding: 0;
        margin-right: 0.5rem;
    `,
);

export const CheckedIcon = styled(CheckBoxIcon)(
    ({ theme }) => css`
        color: ${theme.colors.accentPrimary};
    `,
);

export const RequiredField = styled.span(
    ({ theme }) => css`
        ::after {
            ${theme.typography.robotoRegular16};
            content: '*';
            color: ${theme.colors.textSalmon};
        }
    `,
);
