import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { FirstLevelListItem, SecondLevelListItem, SecondLevelListMap, Item } from '../../DoublyNestedCheckList.types';
import SubItem from '../ListItem/ListItem';
import Expand from '@material-ui/icons/Add';
import Collapse from '@material-ui/icons/Remove';
import * as S from './SecondLevelList.style';

export interface Props {
    firstLevelListItem: FirstLevelListItem;
    selectedValues: Array<Item>;
    selectedFirstLevelListItem: Array<string>;
    setSelectedFirstLevelListItem: Dispatch<SetStateAction<Array<string>>>;
    selectedSecondLevelListItem: SecondLevelListMap;
    setSelectedSecondLevelListItem: Dispatch<SetStateAction<SecondLevelListMap>>;
    setSelectedValues: Dispatch<SetStateAction<Array<Item>>>;
    expandedFirstLevelListItems: Array<string>;
    firstLevelLabels: Array<string>;
}

export default function SecondLevelList({
    firstLevelListItem,
    selectedValues,
    selectedFirstLevelListItem,
    setSelectedFirstLevelListItem,
    selectedSecondLevelListItem,
    setSelectedSecondLevelListItem,
    setSelectedValues,
    expandedFirstLevelListItems,
    firstLevelLabels,
}: Props): JSX.Element {
    const [expandedSecondLevelListItems, setExpandedSecondLevelListItems] = useState<SecondLevelListMap>({});

    useEffect(() => {
        const secondLevelDefaultItem: { [key: string]: Array<string> } = {};
        firstLevelLabels.forEach((label) => {
            secondLevelDefaultItem[label] = [];
        });
        setExpandedSecondLevelListItems(secondLevelDefaultItem);
        // eslint-disable-next-line
  }, []);

    const toggleSecondLevelListItem = (listItemLabel: string, firstLevelListItem: string): void => {
        if (expandedSecondLevelListItems[firstLevelListItem].indexOf(listItemLabel) === -1) {
            setExpandedSecondLevelListItems((prevList) => ({
                ...prevList,
                [firstLevelListItem]: [...prevList[firstLevelListItem], listItemLabel],
            }));
        } else {
            setExpandedSecondLevelListItems((prevList) => ({
                ...prevList,
                [firstLevelListItem]: prevList[firstLevelListItem].filter((item) => item !== listItemLabel),
            }));
        }
    };

    const expandSecondLevelListItem = (listItemLabel: string, firstLevelListItem: string): void => {
        if (expandedFirstLevelListItems.indexOf(listItemLabel) === -1) {
            setExpandedSecondLevelListItems((prevList) => ({
                ...prevList,
                [firstLevelListItem]: [...prevList[firstLevelListItem], listItemLabel],
            }));
        }
    };

    const itemsInFirstAndSecondLevels = (
        firstLevelListItem: FirstLevelListItem,
        secondLevelListItem: SecondLevelListItem,
    ): number => {
        return selectedValues.filter(
            (item) =>
                item.secondLevelListItem === secondLevelListItem.label &&
                item.firstLevelListItem === firstLevelListItem.label,
        ).length;
    };

    const selectAllChildItems = (listItem: SecondLevelListItem, firstLevelListItem: FirstLevelListItem): void => {
        const selectedSubItems: Item[] = [];
        listItem.subItems.forEach((item) => {
            if (
                !selectedValues.find(
                    (selectedItem) =>
                        selectedItem.firstLevelListItem === firstLevelListItem.label &&
                        selectedItem.secondLevelListItem === listItem.label &&
                        selectedItem.label === item.label,
                )
            ) {
                selectedSubItems.push({
                    label: item.label,
                    firstLevelListItem: firstLevelListItem.label,
                    secondLevelListItem: listItem.label,
                });
            }
        });

        setSelectedValues((prevList) => [...prevList, ...selectedSubItems]);
    };

    const handleSecondLevelListItemClick = (
        listItem: SecondLevelListItem,
        firstLevelListItem: FirstLevelListItem,
    ): void => {
        // it second level item is not selected
        if (!selectedSecondLevelListItem[firstLevelListItem.label].includes(listItem.label)) {
            // if all second levels items are checked then add first level item to selected
            if (
                selectedSecondLevelListItem[firstLevelListItem.label].length ===
                firstLevelListItem.subItems.length - 1
            ) {
                if (!selectedFirstLevelListItem.includes(firstLevelListItem.label)) {
                    setSelectedFirstLevelListItem([...selectedFirstLevelListItem, firstLevelListItem.label]);
                }
            }
            // add all second level items to selected
            setSelectedSecondLevelListItem((prevList) => ({
                ...prevList,
                [firstLevelListItem.label]: [...prevList[firstLevelListItem.label], listItem.label],
            }));
            // add all items to selected
            selectAllChildItems(listItem, firstLevelListItem);
        } else {
            // uncheck item and remove first level item from selected
            setSelectedFirstLevelListItem(
                selectedFirstLevelListItem.filter((value) => value !== firstLevelListItem.label),
            );
            // if child items are partially checked then check all of them
            if (itemsInFirstAndSecondLevels(firstLevelListItem, listItem) < listItem.subItems.length) {
                selectAllChildItems(listItem, firstLevelListItem);
            } else {
                // uncheck item and all its child items
                setSelectedSecondLevelListItem((prevList) => ({
                    ...prevList,
                    [firstLevelListItem.label]: selectedSecondLevelListItem[firstLevelListItem.label].filter(
                        (item) => item !== listItem.label,
                    ),
                }));
                setSelectedValues((prevList) =>
                    prevList.filter(
                        (item) =>
                            item.secondLevelListItem !== listItem.label ||
                            item.firstLevelListItem !== firstLevelListItem.label,
                    ),
                );
            }
        }
        expandSecondLevelListItem(listItem.label, firstLevelListItem.label);
    };

    return (
        <S.SecondLevelListContainer>
            {firstLevelListItem.subItems.map((secondLevelListItem) => (
                <div key={secondLevelListItem.label}>
                    <S.ListItem>
                        <S.IconButton
                            onClick={(): void =>
                                toggleSecondLevelListItem(secondLevelListItem.label, firstLevelListItem.label)
                            }
                        >
                            {expandedSecondLevelListItems[firstLevelListItem.label]?.includes(
                                secondLevelListItem.label,
                            ) ? (
                                <Collapse />
                            ) : (
                                <Expand />
                            )}
                        </S.IconButton>
                        <S.StyledCheckbox
                            onClick={(): void =>
                                handleSecondLevelListItemClick(secondLevelListItem, firstLevelListItem)
                            }
                            checkedIcon={<S.CheckedIcon />}
                            checked={selectedSecondLevelListItem[firstLevelListItem.label]?.includes(
                                secondLevelListItem.label,
                            )}
                            indeterminateIcon={<S.PartialIcon />}
                            indeterminate={
                                itemsInFirstAndSecondLevels(firstLevelListItem, secondLevelListItem) > 0 &&
                                itemsInFirstAndSecondLevels(firstLevelListItem, secondLevelListItem) <
                                    secondLevelListItem.subItems.length
                            }
                        />
                        {secondLevelListItem.label.toUpperCase()}
                    </S.ListItem>
                    {expandedSecondLevelListItems[firstLevelListItem.label]?.includes(secondLevelListItem.label) && (
                        <SubItem
                            firstLevelListItem={firstLevelListItem}
                            secondLevelListItem={secondLevelListItem}
                            selectedValues={selectedValues}
                            selectedFirstLevelListItem={selectedFirstLevelListItem}
                            setSelectedFirstLevelListItem={setSelectedFirstLevelListItem}
                            selectedSecondLevelListItem={selectedSecondLevelListItem}
                            setSelectedSecondLevelListItem={setSelectedSecondLevelListItem}
                            setSelectedValues={setSelectedValues}
                        />
                    )}
                </div>
            ))}
        </S.SecondLevelListContainer>
    );
}

export const x = 1;
