import React from 'react';
import styled, { css } from 'styled-components';
import Select from '@material-ui/core/Select';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import { CheckboxProps, Checkbox, Typography, RadioProps, Radio } from '@material-ui/core';
import { colors } from '../../styles/colors';

export const Container = styled(Box)(
    () => css`
        && {
            margin: 1em;
        }
    `,
);

export const StyledSelect = styled(Select)(
    ({ theme }) => css`
        && {
            min-width: 150px;
            height: 40px;
            .MuiSelect-icon {
                color: ${theme.colors.accentPrimary};
            }
        }
    `,
);

export const StyledDialogTitle = styled(MuiDialogTitle)(
    ({ theme }) => css`
        && {
            color: #070c80;
            font-weight: bold;
            padding: 8px 12px;
            margin: 0;
        }
    `,
);

export const StyledDialog = withStyles({
    root: {
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        margin: '1em',
    },
    paper: {
        padding: '15px',
        borderRadius: '16px',
    },
})(Dialog);

export const StyledTextField = {
    inputStyle: {
        padding: '10px',
        background: colors.subtextDark,
    },
    containerStyle: {
        margin: '0.5em 0',
    },
};

export const StyledIconButton = styled(IconButton)(
    ({ theme }) => css`
        && {
            position: absolute;
            right: 1px;
            top: 1px;
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const StyledDialogContent = styled(MuiDialogContent)(
    ({ theme }) => css`
        && {
            display: flex;
            flex-direction: column;
            padding: 10px;
            min-width: 40vh;
            height: 50vh;
            max-height: 50vh;
            ${theme.typography.robotoRegular12};
        }
    `,
);

export const SelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
`;

export const DialogActions = styled(MuiDialogActions)(
    ({ theme }) => css`
        && {
            margin: 0;
            justify-content: space-between;
        }
    `,
);

export const StyledButton = styled(({ width, ...other }) => <Button {...other} />)`
    && {
        background-color: ${colors.accentPrimary};
        color: #fff;
        width: ${(props) => props.width};
    }
`;

export const StyledFormControlLabel = styled(FormControlLabel)<{ isCustom?: boolean }>(
    ({ theme, isCustom }) => css`
        width: 100%;
        color: ${isCustom ? theme.colors.accentPrimary : null};
    `,
);

export const StyledCheckbox = withStyles({
    root: {
        color: '#000',
        '&$checked': {
            color: colors.accentPrimary,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const StyledRadio = withStyles({
    root: {
        color: '#000',
        '&$checked': {
            color: colors.accentPrimary,
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const StyledFormGroup = styled(FormGroup)(
    ({ theme }) => css`
        && {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            max-height: 30vh;
            margin-top: 1em;
            overflow: auto;
            color: ${theme.colors.textLight};
        }
    `,
);

export const ErrorTextContainer = styled.div(
    ({ theme }) => css`
        max-width: 350px;
        word-wrap: break-word;
    `,
);

export const ErrorText = styled(Typography)(
    ({ theme }) => css`
        color: red;
        font-weight: lighter;
        font-size: small;
        margin: 1em;
        word-wrap: break-word;
    `,
);

export const StyledTitle = styled(Typography)(
    ({ theme }) => css`
        && {
            color: ${theme.colors.accentPrimary};
        }
    `,
);

export const SectionTitle = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoMedium14};
        margin: 1rem 0 0.5rem 0;
    `,
);

export const StyledDescription = styled.span(
    ({ theme }) => css`
        ${theme.typography.robotoMedium14};
        color: ${theme.colors.greyScaleDark};
    `,
);
