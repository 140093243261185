import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Platform } from '../../../../types/hierarchies.types';
import Select from '../../../../components/Select/Select';
import NestedCheckList, { ListItem } from '../ProjectCheckList/ProjectCheckList';

import * as S from './ProjectSelector.style';

interface Props {
    data: Array<ListItem> | undefined;
    loading: boolean;
    error: boolean;
    platforms: Array<Platform>;
    selectedPlatform: Platform | undefined;
    setSelectedPlatform: (platform: Platform | undefined) => void;
    selectedProject: Array<string>;
    setSelectedProject: (ids: Array<string>) => void;
}

export default function AnalysisSelector({
    data,
    loading,
    error,
    platforms,
    selectedPlatform,
    setSelectedPlatform,
    selectedProject,
    setSelectedProject,
}: Props): JSX.Element {
    const moduleDropdownOptions = platforms.map((module) => ({ value: module.label, label: module.label }));
    const handleModuleChange = (moduleLabel: string): void =>
        setSelectedPlatform(platforms.find((module) => module.label === moduleLabel));

    const renderAnalysisList = (): ReactNode => {
        if (loading) {
            return <CircularProgress />;
        }
        if (error) {
            return `ERROR: UNABLE TO LOAD PROJECT LIST`;
        }
        if (!data) {
            return `SELECT A PLATFORM`;
        }
        if (data?.length === 0) {
            return `NO PROJECT FOUND`;
        }

        return <NestedCheckList data={data} selectedValues={selectedProject} setSelectedValues={setSelectedProject} />;
    };

    return (
        <S.Container>
            <Select
                handleChange={(event): void => handleModuleChange(event.target.value as string)}
                value={selectedPlatform?.label ?? ''}
                label={'PLATFORM'}
                items={moduleDropdownOptions}
            />
            <S.AnalysisListContainer>{renderAnalysisList()}</S.AnalysisListContainer>
        </S.Container>
    );
}
