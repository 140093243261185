import styled, { css } from 'styled-components';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

export const StyledPopover = styled(Popover)(
    ({ theme }) => css`
        pointer-events: none;
        .MuiPopover-paper {
            pointer-events: none;
            width: 12%;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
            padding: 0.5%;
        }
        .MuiPopover-root {
            pointer-events: none;
            border-radius: 4px;
        }
    `,
);

export const ChildElementText = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoMedium12};
    `,
);

export const PopoverTitle = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12}
    `,
);

export const MetricRow = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        > div {
            display: flex;
            flex-direction: column;
            width: 50%;
        }
    `,
);

export const MetricValue = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoMedium12};
    `,
);

export const MetricLabel = styled.div(
    ({ theme }) => css`
        ${theme.typography.robotoRegular8};
        color: ${theme.colors.subtextLight};
    `,
);
