import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import MaterialSlider from '@material-ui/core/Slider';

export const SliderContainer = styled.div(
    () => css`
        width: 100%;
        display: flex;
        flex-direction: column;
    `,
);

export const SliderLabelContainer = styled.div(
    () => css`
        display: flex;
        justify-content: space-between;
    `,
);

export const SliderLabel = styled(Typography)(
    ({ theme }) => css`
        font-family: Roboto;
        font-size: 0.75rem;
        color: ${theme.colors.subtextDark};
        text-transform: uppercase;
    `,
);

export const Slider = styled(MaterialSlider)(
    ({ theme }) => css`
        & .MuiSlider-track,
        & .MuiSlider-rail {
            opacity: 100%;
            background-color: ${theme.colors.greyScaleDark};
            height: 1px;
        }

        & .MuiSlider-thumb {
            height: 16px;
            width: 16px;
            margin-top: -7px;
            background-color: ${theme.colors.accentSecondary};
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        }

        & .MuiSlider-thumb:hover,
        & .MuiSlider-thumb:active {
            box-shadow: none;
        }

        .MuiSlider-markLabel,
        .MuiSlider-markLabelActive {
            color: ${theme.colors.subtextDark};
        }
    `,
);
