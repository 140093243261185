import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Container,
    PageHeader,
    PageHeaderBody,
    CardContainer,
    Card,
    ModuleInfoLabel,
    PaginationContainer,
    TableCard,
    FileIOContainer,
    ActionText,
    StyledUploadIcon,
    FileNameText,
    FormControlActions,
    FormSaveText,
    FormResetText,
    StyledDownloadIcon,
    StyledErrorText,
    UploadContainer,
    ProgressBarContainer,
    ModalBody,
    ActionButtonContainer,
} from './DataManagement.style';
import Select from '../../components/Select/Select';
import Title from '../../components/Title/Title';
import Pagination from '@material-ui/lab/Pagination';
import Table from '../../components/Table/Table';
import StyledTextInput from '../../components/TextInput/TextInput';
import { uploadFile, getFileTemplate, getUploadHistory } from '../../utils/apiUtil';
import { uploadDataTypes, superuserUploadDataTypes } from '../../constants/apiConfig';
import Button, { ButtonType } from '../../components/Button/Button';
import { useAuthContext } from '../../context/Auth';
import { Roles } from '../../constants/auth';
import { deleteDataConfig } from '../../constants/apiConfig';
import { useFetch } from '../../hooks/useFetch';
import Modal from '../../components/Modal/Modal';
import Snackbar from '../../components/Snackbar/Snackbar';

export interface Props {
    roles: string[];
}

interface FileData {
    fileId: string;
    comments: string;
    file: any;
}

interface UploadDataState {
    totalEntries: number;
    pageTotal: number;
    tableData: any[];
}

interface ErrorStateValidation {
    fileUpload: string;
    fileId: string;
    fileType: string;
}

function DataManagement(props: Props): JSX.Element {
    const { info, logout } = useAuthContext();

    const [deleteDataStatus, deleteData] = useFetch<Blob>(deleteDataConfig);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

    const defaultStateErrors = {
        fileUpload: '',
        fileId: '',
        fileType: '',
    };
    const maxEntriesPerPage = 10;
    const headCells = ['File ID', 'Data Type', 'Uploaded By', 'Uploaded On', 'Comments'];
    const bodyCells = ['file_id', 'data_upload_type', 'uploaded_by', 'created_at', 'comments'];
    const [validationErrors, setValidationErrors] = useState<ErrorStateValidation>(defaultStateErrors);
    const [snackbarState, setSnackbarState] = useState<{ isOpen: boolean; message: string }>({
        isOpen: false,
        message: '',
    });

    const [fileData, setFileData] = useState<FileData>({ fileId: '', comments: '', file: null });
    const dataTypes: any = props.roles?.includes(Roles.SUPER_USER)
        ? [...uploadDataTypes, ...superuserUploadDataTypes]
        : uploadDataTypes;
    const [pageState, setPageState] = useState<number>(1);
    const [uploadHistory, setUploadHistory] = useState<UploadDataState>({
        tableData: [],
        totalEntries: 0,
        pageTotal: 10,
    });
    const [currDataType, setCurrDataType] = useState<number>(0);

    const history = useHistory();

    const handleFileUpload = (e: any): void => {
        setFileData({ ...fileData, file: e.target.files[0] as File });
    };

    const handleTextInputChange = (event: React.ChangeEvent<{ value: unknown }>, key: string): void => {
        const newObj = { ...fileData, [key]: event.target.value };
        setFileData(newObj);
    };

    const resetHandler = (): void => {
        setCurrDataType(0);
        setFileData({ fileId: '', comments: '', file: null });
        setValidationErrors({ fileId: '', fileUpload: '', fileType: '' });
    };

    const handleConfirmDelete = (): void => {
        deleteData();
        setIsConfirmationModalOpen(false);
    };

    const handleSnackbarClose = (): void => {
        setSnackbarState({ isOpen: false, message: '' });
    };

    const fetchUploadHistory = useCallback(async () => {
        const { data } = await getUploadHistory(pageState - 1, 10, info, logout);
        const tableData = data.results
            .sort((firstEle: any, secondEle: any) => Date.parse(secondEle.created_at) - Date.parse(firstEle.created_at))
            .map(({ file_id, data_upload_type, uploaded_by, created_at, comments }: any) => ({
                file_id,
                data_upload_type,
                uploaded_by,
                created_at: created_at.substring(0, 10),
                comments,
            }));
        setUploadHistory({
            tableData,
            totalEntries: data.total,
            pageTotal: data.page_total,
        });
    }, [pageState, info, logout]);

    const handlePageChange = (pageNumber: number): void => {
        setPageState(pageNumber);
    };

    const validate = (): boolean => {
        const { fileId, file } = fileData;
        return fileId.trim().length === 0 || file === null || currDataType === 0;
    };

    const saveHandler = async (): Promise<void> => {
        const isValid = validate();
        if (!isValid) {
            try {
                const data = new FormData();
                data.append('excel', fileData.file);
                data.append('fileId', fileData.fileId);
                data.append('comments', fileData.comments);
                data.append('uploader', 'uploader');
                data.append('fileType', dataTypes[currDataType].value);
                await uploadFile(data, info, logout);
                resetHandler();
                fetchUploadHistory();
            } catch (err) {
                console.error(err);
                setSnackbarState({ isOpen: true, message: 'Something went wrong. Please try again' });
            }
        } else {
            setValidationErrors({
                fileUpload: !fileData.file ? 'Please upload a file' : '',
                fileId: fileData.fileId.trim().length === 0 ? 'Required' : '',
                fileType: currDataType === 0 ? 'Please select a data type' : '',
            });
        }
    };

    useEffect(() => {
        fetchUploadHistory();
    }, [fetchUploadHistory]);

    useEffect(() => {
        if (deleteDataStatus.loading === false && deleteDataStatus.data) {
            fetchUploadHistory();
        }
    }, [deleteDataStatus.data, deleteDataStatus.loading, fetchUploadHistory]);

    const handleSelectInputChange = (
        event: React.ChangeEvent<{ value: unknown }>,
        setStateMethod: React.Dispatch<React.SetStateAction<any>>,
    ): void => {
        setStateMethod(event.target.value);
    };

    const dataTypeSelectItems = dataTypes.map((data: any, index: number) => {
        return { value: index, label: data.label };
    });

    const onTemplateDownloadClick = async () => {
        const isValid = currDataType !== 0;
        if (isValid) {
            const template = await getFileTemplate(dataTypes[currDataType].value, info, logout);
            const url = window.URL.createObjectURL(new Blob([template.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${dataTypes[currDataType].label}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setValidationErrors({ ...validationErrors, fileType: '' });
        } else {
            setValidationErrors({
                fileType: 'Please select a data type',
                fileId: '',
                fileUpload: '',
            });
        }
    };

    return (
        <Container elevation={3}>
            <Snackbar message={snackbarState.message} isOpen={snackbarState.isOpen} onClose={handleSnackbarClose} />
            <PageHeader>
                <PageHeaderBody>
                    <div>
                        <Title title="Data Management" />
                    </div>
                    <ActionButtonContainer>
                        <Button
                            buttonType={ButtonType.SECONDARY}
                            onClickHandler={(): void => setIsConfirmationModalOpen(true)}
                        >
                            DELETE DATA
                        </Button>
                        <Button
                            buttonType={ButtonType.SECONDARY}
                            onClickHandler={(): void => history.push('/dataView')}
                        >
                            VIEW DATA
                        </Button>
                    </ActionButtonContainer>
                </PageHeaderBody>
            </PageHeader>
            <CardContainer>
                <Card gridRowStart={1} gridRowEnd={3} gridColumnStart={1} gridColumnEnd={2} secondaryBackground>
                    <ModuleInfoLabel>SELECT DATA TYPE</ModuleInfoLabel>
                    <Select
                        handleChange={(event): void => handleSelectInputChange(event, setCurrDataType)}
                        value={currDataType}
                        items={dataTypeSelectItems}
                        label="DataType"
                    />
                    <StyledErrorText>{validationErrors.fileType}</StyledErrorText>

                    <FileIOContainer onClick={onTemplateDownloadClick}>
                        <StyledDownloadIcon />
                        <ActionText>DOWNLOAD TEMPLATE</ActionText>
                    </FileIOContainer>

                    <UploadContainer onChange={(e: any): void => handleFileUpload(e)}>
                        <input id="fileUpload" type="file" accept=".xls,.xlsx" hidden />
                        <label htmlFor="fileUpload">
                            <StyledUploadIcon />
                        </label>
                        <ActionText>UPLOAD DATA</ActionText>
                    </UploadContainer>
                    <StyledErrorText>{validationErrors.fileUpload}</StyledErrorText>
                    <ProgressBarContainer>
                        <FileNameText>{fileData.file?.name}</FileNameText>
                    </ProgressBarContainer>

                    <ModuleInfoLabel>FILE ID</ModuleInfoLabel>
                    <StyledTextInput
                        value={fileData.fileId}
                        handleChange={(event): void => handleTextInputChange(event, 'fileId')}
                        error={validationErrors.fileId}
                        label=""
                        dark
                    />
                    <ModuleInfoLabel>COMMENTS</ModuleInfoLabel>
                    <StyledTextInput
                        rows={4}
                        value={fileData.comments}
                        handleChange={(event): void => handleTextInputChange(event, 'comments')}
                        label=""
                        dark
                    />
                    <FormControlActions>
                        <FormResetText onClick={resetHandler}>Reset</FormResetText>
                        <FormSaveText onClick={saveHandler}>Save</FormSaveText>
                    </FormControlActions>
                </Card>
                <TableCard>
                    <Table
                        bodyCells={bodyCells}
                        headCells={headCells}
                        data={uploadHistory.tableData}
                        hideSerialNumberColumn
                        totalTableEntries={uploadHistory.pageTotal}
                    />
                </TableCard>
                <PaginationContainer>
                    <Pagination
                        count={Math.ceil(uploadHistory.totalEntries / maxEntriesPerPage)}
                        onChange={(event: object, page: number): void => handlePageChange(page)}
                        showFirstButton
                        showLastButton
                    />
                </PaginationContainer>
            </CardContainer>

            <Modal
                isOpen={isConfirmationModalOpen}
                title="Confirm Delete"
                handleClose={(): void => setIsConfirmationModalOpen(false)}
            >
                Are you sure you want to delete all data.
                <ModalBody>
                    <Button buttonType={ButtonType.PRIMARY} onClickHandler={handleConfirmDelete}>
                        Confirm
                    </Button>
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={(): void => setIsConfirmationModalOpen(false)}
                    >
                        Cancel
                    </Button>
                </ModalBody>
            </Modal>
        </Container>
    );
}

export default DataManagement;
