import React, { useState } from 'react';
import Expand from '@material-ui/icons/Add';
import Collapse from '@material-ui/icons/Remove';
import TextInput from '../../../../components/TextInput/TextInput';
import ProjectPopover from '../ProjectPopover/ProjectPopover';

import * as S from './ProjectCheckList.style';

export type SubListItem = {
    label: string;
    value: string;
    modelCount: number;
    moduleCount: number;
    status: string;
    title: string;
};

export type ListItem = {
    label: string;
    subItems: Array<SubListItem>;
};

interface Props {
    data: Array<ListItem>;
    selectedValues: Array<string>;
    setSelectedValues: (values: Array<string>) => void;
}

export default function NestedCheckList({ data, selectedValues, setSelectedValues }: Props): JSX.Element {
    const [searchText, setSearchText] = useState('');
    const [expandedListItems, setExpandedListItems] = useState<Array<string>>([]);
    const [selectedListItem, setSelectedListItem] = useState<string>();

    const toggleListItem = (listItemLabel: string): void => {
        if (expandedListItems.indexOf(listItemLabel) === -1) {
            setExpandedListItems([...expandedListItems, listItemLabel]);
        } else {
            setExpandedListItems(expandedListItems.filter((item) => item !== listItemLabel));
        }
    };

    const expandListItem = (listItemLabel: string): void => {
        if (expandedListItems.indexOf(listItemLabel) === -1) {
            setExpandedListItems([...expandedListItems, listItemLabel]);
        }
    };

    const handleListItemClick = (listItem: ListItem): void => {
        if (listItem.label !== selectedListItem) {
            setSelectedListItem(listItem.label);
            const selectedSubItems = listItem.subItems.map((subItem) => subItem.value);
            setSelectedValues(selectedSubItems);
        } else {
            if (selectedValues.length < listItem.subItems.length) {
                const selectedSubItems = listItem.subItems.map((subItem) => subItem.value);
                setSelectedValues(selectedSubItems);
            } else {
                setSelectedListItem(undefined);
                setSelectedValues([]);
            }
        }
        expandListItem(listItem.label);
    };

    const handleSubListItemClick = (subListItem: SubListItem, listItem: ListItem): void => {
        if (listItem.label !== selectedListItem) {
            setSelectedListItem(listItem.label);
            setSelectedValues([subListItem.value]);
        } else {
            if (selectedValues.indexOf(subListItem.value) === -1) {
                setSelectedValues([...selectedValues, subListItem.value]);
            } else {
                if (selectedValues.length - 1 === 0) {
                    setSelectedListItem(undefined);
                }
                setSelectedValues(selectedValues.filter((value) => value !== subListItem.value));
            }
        }
        expandListItem(listItem.label);
    };

    const filteredData = data.reduce((accumulator, currentValue) => {
        const filteredSubItems = currentValue.subItems.filter((subItem) =>
            ('Project ' + subItem.label).toLowerCase().includes(searchText.toLowerCase()),
        );
        if (filteredSubItems.length > 0) {
            return [...accumulator, { ...currentValue, subItems: filteredSubItems }];
        }
        return accumulator;
    }, [] as Array<ListItem>);

    return (
        <S.Container>
            <TextInput
                value={searchText}
                placeholder="Search Project"
                dark
                handleChange={(event): void => setSearchText(event.target.value)}
            />
            {filteredData.map((listItem) => (
                <div key={listItem.label}>
                    <S.CheckListItem>
                        <S.IconButton onClick={(): void => toggleListItem(listItem.label)}>
                            {expandedListItems.includes(listItem.label) ? <Collapse /> : <Expand />}
                        </S.IconButton>
                        <S.StyledCheckbox
                            onClick={(): void => handleListItemClick(listItem)}
                            checked={listItem.label === selectedListItem}
                            checkedIcon={<S.CheckedIcon />}
                            indeterminateIcon={<S.PartialIcon />}
                            indeterminate={
                                listItem.label === selectedListItem &&
                                selectedValues.length !== listItem.subItems.length
                            }
                        />
                        {listItem.label.toUpperCase()}
                    </S.CheckListItem>
                    {expandedListItems.includes(listItem.label) && (
                        <S.SubListContainer>
                            {listItem.subItems.map((subItem) => (
                                <S.CheckListItem
                                    key={subItem.label}
                                    onClick={(): void => handleSubListItemClick(subItem, listItem)}
                                >
                                    <S.StyledCheckbox
                                        checkedIcon={<S.CheckedIcon />}
                                        checked={selectedValues.includes(subItem.value)}
                                    />
                                    <ProjectPopover
                                        title={`${subItem.title || ''}`}
                                        modelsImpacted={subItem.modelCount}
                                        modulesImpacted={subItem.moduleCount}
                                    >
                                        <div>
                                            PROJECT {subItem.label} ({subItem.status})
                                        </div>
                                    </ProjectPopover>
                                </S.CheckListItem>
                            ))}
                        </S.SubListContainer>
                    )}
                </div>
            ))}
        </S.Container>
    );
}
