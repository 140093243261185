export const colors = {
    textDark: '#ffffff',
    textLight: '#222222',
    subtextDark: '#f3f3f3',
    subtextLight: '#333333',
    backgroundPage: '#e6e6e6',
    backgroundPrimary: '#f3f3f3',
    backgroundDark: '#051c2c',
    backgroundLight: '#ffffff',
    greyScaleDark: '#9e9e9e',
    accentPrimary: '#2251ff',
    accentSecondary: '#ff5b6b',
    textSalmon: '#ff5b6b',
    greyScaleMedium: '#d5d5d5',
    greyScaleLight: '#f3f3f3',
    textMelon: '#f6511d',
} as const;

export type Colors = typeof colors;
