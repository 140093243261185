import { GlobalStore, Action } from './store.types';
import { SET_MODULES } from './store.actions';

export const reducer = (prevState: GlobalStore, action: Action): GlobalStore => {
    switch (action.type) {
        case SET_MODULES: {
            const { modules } = action.payload;
            return { ...prevState, modules };
        }
        default:
            return prevState;
    }
};
