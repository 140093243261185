import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    message: string;
    autoHideDuration?: number;
    variant?: 'success' | 'warning' | 'error' | 'info';
}

export default function StyledSnackbar({
    message,
    isOpen,
    onClose,
    autoHideDuration = 5000,
    variant = 'error',
}: Props): JSX.Element {
    function Alert(props: AlertProps): JSX.Element {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
        if (reason === 'clickaway') {
            return;
        }
        onClose();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isOpen}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={variant}>
                {message}
            </Alert>
        </Snackbar>
    );
}
