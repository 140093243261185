import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div(
    () => css`
        flex: 1;
        margin-top: 1rem;
    `,
);

export const AddNewPartButton = styled.button(
    ({ theme }) => css`
        ${theme.typography.robotoMedium14};
        background: none;
        border: none;
        outline: none;
        color: ${theme.colors.textMelon};
        text-transform: uppercase;
        cursor: pointer;
    `,
);
