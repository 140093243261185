import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button, { ButtonType } from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import CircularButton, { CircularButtonType } from '../../components/CircularButton/CircularButton';
import AnalysisChart from '../AnalysisExplorer/components/AnalysisChart/AnalysisChart';
import AnalysisMetrics from '../AnalysisExplorer/components/AnalysisMetrics/AnalysisMetrics';
import {
    patchAnalysisConfig,
    newAnalysisExportConfig,
    validateAnalysisConfig,
    ValidateAnalysisResponse,
} from '../../constants/apiConfig';
import { useFetch } from '../../hooks/useFetch';
import { Locationstate, SensitivityInputs } from './AnalysisSummary.config';
import { formatAnalysisData, formatForExport } from './AnalysisSummary.utils';
import AnalysisDetails from './components/AnalysisDetails/AnalysisDetails';
import { downloadExcel } from '../../utils/fileUtils';
import ConfirmationModal from './components/ConfirmationDialog/ConfirmationDialog';

import * as S from './AnalysisSummary.style';

export default function AnalysisSummary(): JSX.Element {
    const history = useHistory();
    const location = useLocation<Locationstate>();
    const locationState = location?.state;

    const [patchAnalysisStatus, patchAnalysis] = useFetch<string>(patchAnalysisConfig);
    const [exportExcelStatus, exportExcelPost] = useFetch<Blob>(newAnalysisExportConfig);
    const [{ data: validateResults }, validateAnalysis] = useFetch<ValidateAnalysisResponse>(validateAnalysisConfig);

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [sensitivityInputs, setSensitivityInputs] = useState<SensitivityInputs>({
        materialCost: 0,
        annualVolumes: 0,
        designInvestment: 0,
        toolingInvestment: 0,
    });

    if (!locationState) {
        history.push('/MLOverview');
    }

    useEffect(() => {
        if (exportExcelStatus.loading === false && exportExcelStatus.data) {
            downloadExcel(exportExcelStatus.data, `Analysis-${locationState?.analysisId}-Summary.xlsx`);
        }
    }, [exportExcelStatus.data, exportExcelStatus.loading, locationState]);

    const projectData = formatAnalysisData(locationState, sensitivityInputs);

    const handleStatusUpdate = useCallback(
        (status: string) => async (): Promise<void> => {
            const data = { status };
            if (await patchAnalysis({ data, pathParams: { id: locationState?.analysisId } })) {
                history.push('/MLOverview');
            }
        },
        [locationState, history, patchAnalysis],
    );

    const handleExcelExport = (): void => {
        const data = formatForExport(locationState, sensitivityInputs);
        exportExcelPost({ data });
    };

    const validate = (): void => {
        validateAnalysis({
            data: { partsList: locationState?.partsListForValidate },
            pathParams: { id: locationState?.analysisId },
        });
    };

    useEffect(() => {
        if (validateResults?.isValid) {
            handleStatusUpdate('SUBMITTED')();
        } else if (validateResults?.isValid === false) {
            setIsConfirmationModalOpen(true);
        }
    }, [validateResults, handleStatusUpdate]);

    return (
        <S.Container>
            <ConfirmationModal
                analysisId={validateResults?.duplicateAnalysisPartDetails?.[0]?.analysisId?.toString() ?? ''}
                user={validateResults?.duplicateAnalysisPartDetails?.[0]?.originator ?? ''}
                isOpen={isConfirmationModalOpen}
                handleConfirm={handleStatusUpdate('SUBMITTED')}
                handleCancel={(): void => setIsConfirmationModalOpen(false)}
            />
            <S.TitleContainer>
                <Title title="Analysis Summary" showBackButton handleBackButtonClick={(): void => history.goBack()} />
                <S.ActionButtonContainer>
                    <CircularButton
                        variant={CircularButtonType.EXPORT}
                        label="EXPORT"
                        onClickHandler={handleExcelExport}
                    />
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        onClickHandler={handleStatusUpdate('IN_PROGRESS')}
                        disabled={patchAnalysisStatus.loading}
                    >
                        SAVE
                    </Button>
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        onClickHandler={validate}
                        disabled={patchAnalysisStatus.loading}
                    >
                        SUBMIT ANALYSIS
                    </Button>
                </S.ActionButtonContainer>
            </S.TitleContainer>
            <S.BodyContainer>
                <AnalysisDetails
                    locationState={locationState}
                    sensitivityInputs={sensitivityInputs}
                    setSensitivityInputs={setSensitivityInputs}
                />
                <AnalysisChart data={projectData} loading={false} error={false} />
                <AnalysisMetrics data={projectData} loading={false} error={false} />
            </S.BodyContainer>
        </S.Container>
    );
}
