import React from 'react';
import * as S from './ProjectBox.style';
import ProjectBoxTooltip from '../../../../components/ProjectBoxTooltip/ProjectBoxTooltip';

interface Props {
    selectedModels: Array<string>;
    selectedModules: Array<string>;
}

export default function ProjectBox({ selectedModels, selectedModules }: Props): JSX.Element {
    const size = selectedModels.length + selectedModules.length;
    return (
        <S.Container>
            <ProjectBoxTooltip selectedModels={selectedModels} selectedModules={selectedModules} title="PROJECT BOX">
                <S.Box size={size}>
                    <S.FolderIcon />
                    {`PROJECT BOX ${size ? `(${size} ITEMS)` : ''}`}
                </S.Box>
            </ProjectBoxTooltip>
        </S.Container>
    );
}
