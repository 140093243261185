import { Submodules, ModulesResponseObject, AnalysisInputsObject, StateValidations } from './NewAnalysis.types';

export const defaultAnalysisInputs: AnalysisInputsObject = {
    analysisTitle: '',
    originator: '',
    additionalInfo: '',
    detailedAnalysisDesc: '',
    impactOnOthers: '',
    additionalTooling: 0,
    softTooling: 0,
    designHours: 0,
    consultationCost: 0,
    prototypingCost: 0,
    certification: 0,
    supplierRnD: 0,
    existingToolCost: 0,
    depreciationPeriod: 0,
    toolLifecycle: 0,
    penaltyResidualValue: 0,
    supplier: 0,
    internal: 0,
    otherInvestment: 0,
};

export const defaultStateErrors: StateValidations = {
    analysisTitle: '',
    originator: '',
    additionalTooling: '',
    softTooling: '',
    designHours: '',
    consultationCost: '',
    prototypingCost: '',
    certification: '',
    supplierRnD: '',
    detailedAnalysisDesc: '',
    impactOnOthers: '',
};

export const defaultModule: ModulesResponseObject = {
    name: '',
    id: '',
    description: '',
    submodules: [{} as Submodules],
};

export enum OperatingMode {
    TOP_DOWN = 'TOP_DOWN',
    ADVANCED = 'ADVANCED',
}

export const operatingModes = [
    {
        label: 'Top Down',
        value: OperatingMode.TOP_DOWN,
    },
    { label: 'Advanced', value: OperatingMode.ADVANCED },
];
