import styled, { css } from 'styled-components';
import { ExpansionPanelSummary, Typography, ExpansionPanel } from '@material-ui/core';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)(
    ({ theme }) => css`
        background: ${theme.colors.backgroundDark};
        color: ${theme.colors.textDark};
        border-radius: 10px;
        &.MuiExpansionPanelSummary-root.Mui-expanded {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            box-shadow: none;
            min-height: 5px;
        }
        .MuiSvgIcon-root {
            color: ${theme.colors.backgroundLight};
        }
    `,
);

export const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)(
    ({ theme }) => css`
        background-color: ${theme.colors.backgroundLight};
        display: flex;
        flex-direction: column;
        &.MuiExpansionPanelDetails-root {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    `,
);

export const ExpansionPanelContainer = styled(ExpansionPanel)(
    ({ theme }) => css`
        background: ${theme.colors.backgroundPage};
        margin-bottom: 5%;
    `,
);

export const CardTitle = styled(Typography)(
    ({ theme }) => css`
        ${theme.typography.robotoRegular12};
        min-height: 0px;
        height: 15px;
    `,
);
