import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import { GlobalStyle } from './styles/globalStyles';
import { theme } from './styles/theme';
import { GlobalStoreProvider } from './store/store.provider';
import App from './App';
import { midBuilder } from './utils/midBuilder.utils';
import { Mid as MidType } from '@mid/sdk';
import { AuthProvider } from './context/Auth';
import Authorise from './Authorise';

const mid: MidType = midBuilder({
    apiServer: '/api',
    enableCookieAuth: false,
    basePath: '/',
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <AuthProvider mid={mid}>
            <React.StrictMode>
                <StylesProvider injectFirst>
                    <GlobalStoreProvider>
                        <Router>
                            <Authorise>
                                <>
                                    <GlobalStyle />
                                    <App />
                                </>
                            </Authorise>
                        </Router>
                    </GlobalStoreProvider>
                </StylesProvider>
            </React.StrictMode>
        </AuthProvider>
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
